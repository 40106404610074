
a {
  text-decoration: none;
}

html{
  line-height: 1.5;

  @media only screen and (min-width: 0) {
    font-size: 14px;
  }

  @media only screen and (min-width: $medium-screen) {
    font-size: 14.5px;
  }

  @media only screen and (min-width: $large-screen) {
    font-size: 15px;
  }

  font-family: $font-stack;
  font-weight: normal;
  color: $off-black;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	line-height: 1.3;
	&.no-margin{
		line-height: 0;
    margin: 0;
  }
  
  &.green-title {
    color: $primary-color;
  }

  &.thin-font-weight {
    font-weight: 300;
  }

  &.alt-font {
    font-family: $font-alt;
  }
}

p {
	&.inline {
		display: inline-block;
	}
	&.thin-subtitle {
        @include thin-subtitle;
  }
  &.underline {
    text-decoration: underline;
  }
  &.italicize {
    font-style: italic;
  }
  &.thin {
    font-weight: 300;
  }
  &.alt-font {
    font-family: $font-alt;
  }
}

.flow-question-subtitle {
    @include thin-subtitle;
    margin-bottom: 1rem;
}

.regular {
  font-weight: 500!important;
}

// Header Styles
@mixin hstyles-for-mult($cur-mult) {
    h1 { font-size: $h1-fontsize*$cur-mult; line-height: 110%; margin: ($h1-fontsize*$cur-mult / 1.5) 0 ($h1-fontsize*$cur-mult / 2.5) 0;}
    h2 { font-size: $h2-fontsize*$cur-mult; line-height: 110%; margin: ($h2-fontsize*$cur-mult / 1.5) 0 ($h2-fontsize*$cur-mult / 2.5) 0;}
    h3 { font-size: $h3-fontsize*$cur-mult; line-height: 110%; margin: ($h3-fontsize*$cur-mult / 1.5) 0 ($h3-fontsize*$cur-mult / 2.5) 0;}
    h4 { font-size: $h4-fontsize*$cur-mult; line-height: 110%; margin: ($h4-fontsize*$cur-mult / 1.5) 0 ($h4-fontsize*$cur-mult / 2.5) 0;}
    h5 { font-size: $h5-fontsize*$cur-mult; line-height: 110%; margin: ($h5-fontsize*$cur-mult / 1.5) 0 ($h5-fontsize*$cur-mult / 2.5) 0;}
    h6 { font-size: $h6-fontsize*$cur-mult; line-height: 110%; margin: ($h6-fontsize*$cur-mult / 1.5) 0 ($h6-fontsize*$cur-mult / 2.5) 0;}
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { font-weight: inherit; }
$i: 0;
$max-mult: 1 + (($intervals+1) * .0325);
@while $i <= $intervals+1 {
    $cur-mult: (1 + (.0325 * $i)) / $max-mult;
    $cur-width: 330 + ($i * $interval-size);
    @media only screen and (min-width : $cur-width) {
        @include hstyles-for-mult($cur-mult);
        @if $i == $intervals/4 {
            .limit-25-h {
                @include hstyles-for-mult($cur-mult);
            }
        } @else if $i == $intervals/2 {
            .limit-50-h {
                @include hstyles-for-mult($cur-mult);
            }
        } @else if $i == $intervals/4*3 {
            .limit-75-h {
                @include hstyles-for-mult($cur-mult);
            }
        }
    }

    @if $i == $intervals/2 {
        @media #{$large-and-up} {
            .col.limit-h-l {
                @include hstyles-for-mult($cur-mult);
            }
        }
    } @else if $i == 7 {
        @media #{$medium-only} {
            .col.limit-h-m {
                @include hstyles-for-mult($cur-mult);
            }
        }
    }

    $i: $i + 1;
}

// Handle below 360px screen
$cur-mult: 1 / $max-mult;
@media only screen and (max-width: 330px) {
    h1 { font-size: $h1-fontsize*$cur-mult; line-height: 110%; margin: ($h1-fontsize*$cur-mult / 1.5) 0 ($h1-fontsize*$cur-mult / 2.5) 0;}
    h2 { font-size: $h2-fontsize*$cur-mult; line-height: 110%; margin: ($h2-fontsize*$cur-mult / 1.5) 0 ($h2-fontsize*$cur-mult / 2.5) 0;}
    h3 { font-size: $h3-fontsize*$cur-mult; line-height: 110%; margin: ($h3-fontsize*$cur-mult / 1.5) 0 ($h3-fontsize*$cur-mult / 2.5) 0;}
    h4 { font-size: $h4-fontsize*$cur-mult; line-height: 110%; margin: ($h4-fontsize*$cur-mult / 1.5) 0 ($h4-fontsize*$cur-mult / 2.5) 0;}
    h5 { font-size: $h5-fontsize*$cur-mult; line-height: 110%; margin: ($h5-fontsize*$cur-mult / 1.5) 0 ($h5-fontsize*$cur-mult / 2.5) 0;}
    h6 { font-size: $h6-fontsize*$cur-mult; line-height: 110%; margin: ($h6-fontsize*$cur-mult / 1.5) 0 ($h6-fontsize*$cur-mult / 2.5) 0;}
}

// Text Styles
em { font-style: italic; }
.strong { font-weight: 500; }
small { font-size: 75%; }
.light { font-weight: 300; }
.thin { font-weight: 200; }


.flow-text{
  $i: 0;
  @while $i <= $intervals {
    @media only screen and (min-width : 360 + ($i * $interval-size)) {
      font-size: 1.2rem * (1 + (.02 * $i));
    }
    $i: $i + 1;
  }

  // Handle below 360px screen
  @media only screen and (max-width: 360px) {
    font-size: 1.2rem;
  }
}

// Fonts
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQk6YvM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Fasthand';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/fasthand/v30/0yb9GDohyKTYn_ZEERkpaE0Urhg0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.force-load-fasthand {
  font-family: 'Fasthand',normal;
  opacity: 0;
  position: none;
  font-size: 5px;
}

.medium-emphasis {
    color: $medium-emphasis;
}

.low-emphasis {
    color: $low-emphasis;
}

.main-head-sub {
  font-size: 11px;
  margin-top: -8px;
}

.off-black-text {
  color: $off-black;
}

.em-1-5 {
    font-size: 1.5em;
}
