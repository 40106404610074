$tooltip-base-width: 20;
$tooltip-height: 10;

.material-tooltip {
  padding: 10px 8px;
  font-size: 1rem;
  z-index: 2000;
  background-color: transparent;
  border-radius: 2px;
  color: #fff;
  min-height: 36px;
  line-height: 120%;
  opacity: 0;
  position: absolute;
  text-align: center;
  max-width: calc(100% - 4px);
  &.square {
      max-width: 250px;
  }
  overflow: visible;
  left: 0;
  top: 0;
  &:not(.auto-open):not(.has-actions) {
    pointer-events: none;
  }
  visibility: hidden;
  background-color: #323232;

  .arrow {
    position: absolute;
    width: 0;
    height: 0;
    &.point-down {
      border-right: #{$tooltip-base-width/2}px solid transparent;
      border-left: #{$tooltip-base-width/2}px solid transparent;
      border-top: #{$tooltip-height}px solid #323232;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &.point-up {
      border-right: #{$tooltip-base-width/2}px solid transparent;
      border-left: #{$tooltip-base-width/2}px solid transparent;
      border-bottom: #{$tooltip-height}px solid #323232;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &.point-left {
      border-right: #{$tooltip-height}px solid #323232;
      border-top: #{$tooltip-base-width/2}px solid transparent;
      border-bottom: #{$tooltip-base-width/2}px solid transparent;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
    &.point-right{
      border-left: #{$tooltip-height}px solid #323232;
      border-top: #{$tooltip-base-width/2}px solid transparent;
      border-bottom: #{$tooltip-base-width/2}px solid transparent;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .remove-btn {
    margin-right: -5px;
    margin-top: -7px;
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 20px;
    float: right;
    cursor: pointer;
  }
}

.backdrop {
  position: absolute;
  opacity: 0;
  height: 7px;
  width: 14px;
  border-radius: 0 0 50% 50%;
  background-color: #323232;
  z-index: -1;
  transform-origin: 50% 0%;
  visibility: hidden;
}
