.slider {
  position: relative;
  height: 400px;
  width: 100%;

  // Fullscreen slider
  &.fullscreen {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ul.slides {
      height: 100%;
    }

    ul.indicators {
      z-index: 2;
      bottom: 30px;
    }
  }

  .slides {
    background-color: $slider-bg-color;
    margin: 0;
    height: 400px;

    li {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: inherit;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
      }

      .caption {
        color: #fff;
        position: absolute;
        top: 15%;
        left: 15%;
        width: 70%;
        opacity: 0;

        p { color: $slider-bg-color-light; }
      }

      &.active {
        z-index: 2;
      }
    }
  }


  .indicators {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;

    .indicator-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 16px;
      width: 16px;
      margin: 0 12px;
      background-color: $slider-bg-color-light;

      transition: background-color .3s;
      border-radius: 50%;

      &.active {
        background-color: $slider-indicator-color;
      }
    }
  }

}

.slider-container {
    height: 500px;
    max-height: 50vh;
    margin-left: 50px;
    @media(max-width: 350px) {
        margin-left: 20px;
    }
    &.shorter {
        max-height: 40vh;
    }
}

.rc-slider.single-option-slider {
    .rc-slider-track {
        background-color: $primary-color;
        width: 3px;
    }

    .rc-slider-rail {
        background-color: $icon-low-opacity;
        width: 3px;
    }

    .rc-slider-dot {
        background-color: $icon-low-opacity;
        border-color: $icon-low-opacity;
        width: 11px;
        height: 11px;
        left: -4px;

        &.rc-slider-dot-active {
            background-color: $primary-color;
            border-color: $primary-color;
        }
    }

    .rc-slider-handle {
        background-color: $primary-color;
        border-color: $primary-color;
        width: 20px;
        height: 20px;
        margin-top: -11.5px;
        margin-left: -8.5px;
    }

    .rc-slider-mark-text {
        width: 250px;
        text-align: left;
        transform: translateY(-10px);
        user-select: none;

        label {
            font-size: 20px;
            font-weight: 200;
            line-height: 1;
            &.active {
                font-size: 25px;
                font-weight: 500;
                color: $primary-color;
            }
        }

    }

    .rc-slider-mark-text-active {
        color: #999;
    }

    .slider-option-subtitle.active {
        color: #666;
    }
}