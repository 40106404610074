

.card-panel {
  transition: box-shadow .25s;
  padding: $card-padding;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  border-radius: $card-border-radius;
  @extend .z-depth-1;
  background-color: $card-bg-color;
}

a.card {
  display: block;
  color: unset;
}

.card {
  position: relative;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  background-color: $card-bg-color;
  transition: box-shadow .25s transform 0.25s;
  border-radius: $card-border-radius;
  &:not(.no-shadow) {
    @extend .z-depth-1;
  }

  &.notification {
    padding: 20px;
    border-left: 10px solid $primary-color;
    &.checked {
      border-left: 8px solid $icon-low-opacity;
    }
  }

  &.no-shadow {
    border: 1px solid #ddd;
  }

  &.gradient {
    padding-left: 12px;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    height: 110px;
   
    img {
      height: 110px;
      filter: grayscale(90%);
      margin-left: 25px;
    }

    &.g1 {
      background: rgb(67,160,71);
background: linear-gradient(90deg, rgba(67,160,71,1) 60%, rgba(124,179,66,1) 100%);
    }

    &.g2 {
      background: rgb(76,175,80);
background: linear-gradient(90deg, rgba(76,175,80,1) 60%, rgba(139,195,74,1) 100%);
    }

    &.g3 {
      background: rgb(102,187,106);
background: linear-gradient(90deg, rgba(102,187,106,1) 60%, rgba(156,204,101,1) 100%);
    }

    &.g4 {
      background: rgb(129,199,132);
background: linear-gradient(90deg, rgba(129,199,132,1) 60%, rgba(174,213,129,1) 100%);
    }
  }

  .default-card-header {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: $font-grey;
    padding-bottom: 15px;
    .subtitle {
        font-size: 12px;
        color: $icon-low-opacity;
    }
}

  .card-title {
    font-size: 24px;
    font-weight: 300;
    &.activator {
      cursor: pointer;
    }
  }

  &.inline {
    display: inline-block;
  }

  //Recipe Cards During Signup
  &.signup-recipe-card {
    width: 130px;
    height: 200px;
    margin:10px;

    .signup-recipe-card-content {
        width: 100%;
        height: auto;
        border-radius: 5px 5px 0 0;
    }

    .signup-recipe-card-img {
      width: 100%;
      height: 66%;
      object-fit: cover;
      border-radius: $signup-recipe-card-border-radius;
    }
    .signup-recipe-card-title {
      height: 52px;
      font-weight: 300;
      color: $header-color;
      margin-left: 5px;
      margin-top: 5px;
      line-height: 1.2;
      overflow: hidden;
    }
    &.active {
      .icon-overlay-signup {
        opacity: 1;
      }
      .signup-recipe-card-title {
        font-weight: 500;
      }
    }
  }

  //Date Cards During Signup
  &.signup-date-card {
    width: 50px;
    @media(max-width: 360px) {
      width: 45px;
    }
    height: 80px;
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .signup-date-card-title {
      font-weight: 400;
      color: $header-color;
    }
    .signup-date-card-subtitle {
      font-weight: 300;
      color: $font-grey;
    }
    @media(max-width: 500px) {
      .signup-date-card-subtitle {
        font-size: 12px;
      }
    }
    @media(max-width: 360px) {
      .signup-date-card-subtitle {
        font-size: 10px;
      }
    }
  }

    &.active {
        background-color: $primary-color;
        color: color("shades", "white");

        .signup-date-card-title, .signup-recipe-card-title {
            color: color("shades", "white");
            font-weight: 500;
        }
        .signup-date-card-subtitle {
          font-weight: 300;
          color: color("shades", "white");
        }
        &.buttonize:hover {
            background-color:  $primary-slightly-light;
        }
    }
    
  //Meal Cards on Today Page
  &.meal-cards-today {
    background-color: white;
    color: $font-grey;
    width: 100%;
  }

  //Meal Cards on Meal Plan Tab (Day View)
  &.meal-cards-day-view {
    background-color: white;
    margin-left: 20px;
    height: 85px;
    border-radius: 15px;
    .added-person-icon {
      @include meal-plan-icon;
      font-size: 22px;
      position: absolute;
      bottom: 0; 
      left: 0;
      margin: 0px 2px 0px 8px;
      
      i, svg {
        -webkit-text-stroke: 1px color("grey","base");
      }
    }
    .locked-meal-icon {
      @include meal-plan-icon;
      font-size: 18px;
      position: absolute;
      right: 0;
      margin: 1px 6px 0px 2px;
  
      i, svg {
        -webkit-text-stroke: 1px color("grey","base");
      }
    }
  }

  //Weight Log 
  &.weight-log {
    background-color: white;
    min-height: 90px;
    color: $font-grey;
  }

  //Workout Log
  &.workout-log {
    background-color: white;
    color: $font-grey;
    clear: both;
    margin-top: 4px;
    margin-bottom: 2px;

    &.logged {
      background-color: color("grey", "lighten-3" );
    }

    &.rest {
      margin-top: 20px;
      width: 80%;
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
    }

    &.rep-log {
      margin-top: 20px;
      width: 80%;
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;

      background-color: color("grey","lighten-3");
      margin-top: 10px;
      max-width: 300px;

      @media(min-width: $set-background-width) {
            background-color: white;
      }
    }
  }

  // Card Sizes
  &.small, &.medium, &.large, &.small-signup {
    position: relative;

    .card-image + .card-content {
      max-height: 40%;
    }

    .card-action {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .card-image {
      max-height: 60%;
      overflow: hidden;
    }
  }

  &.static-aspect {
    position: relative;
    .card-image {
        max-height: none;
        width: auto;
    }

    &.psuedo-image {
      width: 100%;
      @media #{$small-and-down} {
        .card-image {
          display: none;
        }
      }
      
      @media #{$medium-and-up} {
        .card-image {
          padding-bottom: 66.6%;
        }
      }
    }

    &.max-size-medium {
      max-width: 400px;
      display: inline-block;
    }
  }


  &.small, &.medium, &.large, &.small-signup, &.static-aspect {

    .card-content {
      max-height: 100%;
      overflow: hidden;
    }
    
    //To make routine picker box same hight as others
    .card-content-psudo-routine {
      max-height: 100%;
      overflow: hidden;
      
      .card-title-gray {
        color: $font-grey;
        font-size: 18px;
        line-height: 0.8;
        margin-bottom: 0;
      }
  
      .centering {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      
      @media #{$small-and-down} {
	.card-content-psudo-routine {
	  height: 40px;
	}
  }

    @media #{$medium-and-up} {
    .card-content-psudo-routine {
        height: 136px;
    }
    }
    }

  }

  &.small {
    height: 300px;
  }

  &.medium {
    height: 400px;
  }

  &.large {
    height: 500px;
  }
	
  &.small-signup {
		height: 75px;
		width: 300px;
    border-radius: 8px;
    &.smaller-title {
      .card-title {
        font-size: 20px;
      }
    }
    &.larger {
      height: auto;
      @media #{$medium-and-up} {
        width: 450px;
      }
      .card-content .card-subtitle {
        font-weight: 400;
      }
    }
  }

  &.food-log:not(.has-multiple) {
      min-height: 100px;
  }


  // Horizontal Cards
  &.horizontal {
    &.small, &.medium, &.large {
      .card-image {
        height: 100%;
        max-height: none;
        overflow: visible;

        img {
          height: 100%;
        }
      }
    }

    display: flex;

    .card-image {
      max-width: 50%;
      img {
        border-radius: 2px 0 0 2px;
        max-width: 100%;
        width: auto;
      }
    }

    .card-stacked {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;

      .card-content {
        flex-grow: 1;
      }
    }
  }

  // Sticky Action Section
  &.sticky-action {
    .card-action {
      z-index: 2;
    }

    .card-reveal {
      z-index: 1;
      padding-bottom: 64px;
    }
  }

  .upper-right-icon {
      position: absolute;
      top: 10px;
      right: 10px;
  }
  
  .card-image {
    position: relative;

    .routine-gray-overlay {
        border-radius: $card-border-radius $card-border-radius 0 0;
    }

    // Image background for content

    svg {
        border-radius: $card-border-radius $card-border-radius 0 0;
        &.no-content {
            border-radius: $card-border-radius;
        }
    }

    img {
      display: block;
      border-radius: $card-border-radius $card-border-radius 0 0;
      &.no-content {
          border-radius: $card-border-radius;
      }
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    .card-title {
      color: $card-bg-color;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding: $card-padding;
    }
  }

  &.small-signup.primary {
      .card-content {
        .card-subtitle {
            color: white;
            font-weight: 300;
        }
    
        .card-title {
            color: white;
            font-weight: 700;
            margin-bottom: 0;
            line-height: 1;
        }
      }
    background-color: $primary-color;

}

  .card-content {
    padding: $card-padding;
    border-radius: 0 0 2px 2px;
    display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

    p {
      margin: 0;
    }
    .card-title {
      display: block;
      line-height: 32px;
      margin-bottom: 8px;

      i {
        line-height: 32px;
      }
    }

    .card-subtitle {
      color: $card-background-light;
      font-weight: 300;
    }
    
		.card-title-green {
			color: $card-title-green;
		}

    .card-title-all-colors {
      font-weight: 700;
			margin-bottom: 0;
			line-height: 1;
    }

		.card-title-gray {
			color: $font-grey;
			font-size: 18px;
			line-height: 0.8;
			margin-bottom: 0;
		}

		&.centering {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
  }

  .card-action {
    border-radius: 0 0 $card-border-radius $card-border-radius;
    &:last-child {
      border-radius: 0 0 $card-border-radius $card-border-radius;
    }
    background-color: inherit; // Use inherit to inherit color classes
    border-top: 1px solid rgba(160,160,160,.2);
    position: relative;
    padding: 16px $card-padding;

    a:not(.btn):not(.btn-large):not(.btn-floating) {
      color: $card-link-color;
      margin-right: $card-padding;
      transition: color .3s ease;
      text-transform: uppercase;

      &:hover { color: $card-link-color-light; }
    }
  }

  .card-reveal {
    padding: $card-padding;
    position: absolute;
    background-color: $card-bg-color;
    width: 100%;
    overflow-y: auto;
    left: 0;
    top: 100%;
    height: 100%;
    z-index: 3;
    display: none;

    .card-title {
      cursor: pointer;
      display: block;
    }
  }
  //Subscription cards
  &.subscription-cards {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px;
    color: $header-color;

    .title {
      font-weight: 500;
      font-size: 20px;
      @media screen and (max-width: 350px) {  
        font-size: 18px;
      }
      &.thin {
        font-weight: 300;
      }
    }

    .subtitle {
      font-weight: 300;
      color: color("grey", "base");
      font-size: 12px;
      line-height: 1rem;
      &.right {
          text-align: right;
      }
    }

    &.annual-card {
      background: $primary-color;
      .subtitle, .title {
        color: color("shades", "white");
      }
    }
  }
  &.selected {
    border-color: $primary-color;
    background-color: $primary-super-light;
  }
  .subscription-type-text-container {
    align-items: flex-start;
    max-width: 75%;
    padding: 7px;
  }

  .subscription-price-container {
    align-items: flex-end;
    max-width: 55%;
    padding: 7px;
  }

  //Workout Tab Workouts List Cards
  &.wt-cards {
    // display: flex;
    // flex-direction: column;
    height: 70px;
    width: auto;
    color: $font-grey;
    max-width: $max-width-list;
    margin-right: auto;
    margin-left: auto;
    
    &.inactive {
      background-color: color("grey","lighten-3");
      color: color("grey","base");
      font-weight: 300;
      
      .wt-days {
        background-color: color("grey","lighten-3");
        border: 1px solid color("grey","lighten-1");
        color: color("grey","base");
      }
    
    }

    &.active {
      background-color: $primary-color;
      color: white;

      .wt-days {
        background-color: white;
        color: $primary-color;
      }

      .wt-workout-title {
        font-weight: 500;
      }
    }
  
  }

  //Workout Detail Cards
  &.woo-exercise-card {
    padding-top: 10px;
    padding-left: 8px;	
  }



  //Meal Plan Type Cards
&.type-card {
  border-radius: 16px;
  margin: 0;
  display: block;
  width: 100%;
  height: 100%;


  .type-card-img {
    width: 100%;
    height: 145px;
    object-fit: cover;
    border-radius: 16px 16px 0 0;
  }

  .type-card-img-placeholder {
    position: relative;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
  }

  .side-img-container {
    display: flex;
    position: absolute;
    right: 0;
    top: 110px;
  }

  .type-card-side-img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    object-fit: cover;
    margin:0px 3px 3px 1px;
    border: 2px solid white;
  }

  .date-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 12px;
  }

  .date-contents {
    font-size: 12px;
    font-weight: 400;
    color: $header-color;
    background-color: rgba(255, 255, 255, 0.85);
    height: 20px;
    line-height: 20px;
    padding: 0px 4px;

    &.border-radius-right {
      border-bottom-right-radius: 5px;
    }

    &.border-radius-left {
      border-bottom-left-radius: 5px;
    }
  }

  .added-person-icon {
    @include meal-plan-icon;
    font-size: 20px;
    position: absolute;
    top: 114px;
    left: 0;
    margin: 0px 2px 0px 8px;
    
    i, svg {
      -webkit-text-stroke: 1px color("grey","base");
    }
  }

  .locked-meal-icon {
    @include meal-plan-icon;
    font-size: 18px;
    position: absolute;
    right: 0;
    margin: 1px 6px 0px 2px;

    i, svg {
      -webkit-text-stroke: 1px color("grey","base");
    }
  }

  .type-card-title {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: $header-color;
    margin: 0px 5px 3px 5px;
    @include two-line-ellipsis;
    overflow: hidden;
  }
  .type-card-subtitle {
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    color: $header-light;
    margin: 5px 5px 0px 5px;
    line-height: 1.2;
    overflow: hidden;
    @media #{$large-and-up} {
      margin-top: 1px;
    }
  }

}

}

.type-card-rel-wrap {
  position: relative;
  margin: 7px;
  display: inline-block;
  vertical-align: middle;
  min-width: 145px;
  max-width: 300px;
  width: 145px;
  height: 262px;
  @media #{$medium-only} {
    width: 153px;
  }
  flex: 0 0 auto;

  .type-button-area {
    position: absolute;
    bottom: 0;
    height: 41px;
    width: 100%;
    border-top: 1px solid color("grey", "lighten-2");
    color: $header-light;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 16px 16px;
    background-color: $card-bg-color;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
          background-color: $card-buttonize-background-hover;
          border-radius: 0px 0px 16px 16px;
         
    } 
    &:active {
        transform: scale(0.95);
    }
    &.active {
      background-color: $primary-color;
      color: white;
      font-weight: 500;
      &:active {
        transform: scale(1);
    }
    }
    @media #{$large-and-up} {
      height: 43px;
    }
  } 
}

.meal-card-servings-cont {
  position: absolute;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 20px;
  padding-right: 20px;
  height: 55px;
}

.meal-cards-day-wrap {
  position: relative;
  @media #{$small-and-down} {
    margin-right: 5px;
  }
}

//Subscription Page
.subscription-width {
  width: 90%;
  max-width: 350px;
  margin: 0;
}

//Making the card a button 
.buttonize {
  cursor: pointer;
  &:hover {
        background-color: $card-buttonize-background-hover;
        &:not(.no-shadow) {
          @extend .z-depth-1-half;
        }
  } 
  &:active {
      transform: scale(0.95);
  }
}

.buttonize-color-only {
  cursor: pointer;
  &:hover {
    background-color: $card-buttonize-background-hover;
  }
  &:active {
    background-color: darken($card-buttonize-background-hover,10%);
  }

}

.buttonize-type-card-top {
  cursor: pointer;
  &:hover {
        background-color: $card-buttonize-background-hover;
        border-radius: 16px 16px 0px 0px;
        &:not(.no-shadow) {
          @extend .z-depth-1-half;
        }
  } 
  &:active {
      transform: scale(0.95);
  }
}	

//Icon overlay on sign up recipe cards
.icon-overlay-signup {
  position: absolute;
  color:  #8BC34B;
  transform: scale(2);
  padding-top: 70px;
  padding-left: 70px;
  opacity: 0;
}

//Text overlay on sign up routine cards
.text-overlay-signup {
  position: absolute;
  color: white;
  font-size: 2em;
  text-align: center;
  top:90%;
  left: 10%;
  transform: translate( -30%, -90% );
  z-index: 101;
  white-space: nowrap;
  line-height: 1;
  
  .subtitle {
    font-size: 0.5em;
    font-weight: 500;
  }

  &.right {
    left: 74%;
  }
}

//Signup routine cards 
.routine-cards-padding {
  padding: 5px !important;
  min-height: 82px;
}

.icon-size-forty {
  font-size: 30px;
  padding: 0px 10px 10px 10px;
  opacity: 0.6;
}

.routine-text {
  font-weight: 300;
  text-align: justify;
  margin-top: 0;
}

.routine-gray-overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #888;
  background-size: 50px 50px;
  opacity: 0.5;
  filter: alpha(opacity=0);
  transition: all 0.6s;
}

//Workout Walkthrough Initialization Button Cards 
.card.init {
  margin: 10px;
  padding-right: 5px;
  max-width: 380px;
  margin: 18px auto;

  @media #{$short-screen-only} {
    margin: 5px auto;
    max-width: 310px;
	}
}

//Meal Plan Page
.card.nutrition-card {
  height: fit-content;
  padding: 10px 20px 10px 20px;
  justify-content: center;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;
  border-radius: 25px;
  margin: 22px auto 22px auto;
  max-width: 350px;
}

.mec-wrapper {
  position: relative;
  &.me-side {
    width: calc(50% - 5px);
    .meal-editor-card {
      height: 225px;
      img {
        height: 100px;
      }
    }
    margin: 0px;
  }
  .rm-actions-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9; 
  }
}

//Meal Editor Page
.card.meal-editor-card {
  height: 317px;
  width: 100%;
  border-radius: 16px;
  img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 16px 16px 0 0;
  }
  .me-title {
    font-size: 17px;
    font-weight: 400;
    color: $header-color;
    margin: 5px 5px 3px 8px;
    @include two-line-ellipsis;
    overflow: hidden;
  }
  .me-subtitle {
    height: 15px;
    font-size: 12px;
    font-weight: 300;
    color: $header-light;
    margin: 0px 1px 0px 11px;
    line-height: 1.2;
    overflow: hidden;
    @media #{$large-and-up} {
      margin-top: 1px;
    }
  }
  
  &.me-nutrition {
    height: 150px;
    width: 100%;
  }
  &.me-nutrition-2 {
    height: 100px;
  }
}

.stretch-cards {
  display: flex;
  .card {
    flex-grow: 1;
  }
}




    
    