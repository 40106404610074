// ==========================================================================
// Materialize variables
// ==========================================================================
//
// Table of Contents:
//
//  1. Colors
//  2. Badges
//  3. Buttons
//  4. Cards
//  5. Carousel
//  6. Collapsible
//  7. Chips
//  8. Date + Time Picker
//  9. Dropdown
//  10. Forms
//  11. Global
//  12. Grid
//  13. Navigation Bar
//  14. Side Navigation
//  15. Photo Slider
//  16. Spinners | Loaders
//  17. Tabs
//  18. Tables
//  19. Toasts
//  20. Typography
//  21. Footer
//  22. Flow Text
//  23. Collections
//  24. Progress Bar
//  25. Etc.



// 1. Colors
// ==========================================================================

$primary-slightly-light: hsl($primary-h,$primary-s,calc(#{$primary-l} + 6%));
$primary-slightly-dark: hsl($primary-h,$primary-s,calc(#{$primary-l} - 6%));
$primary-very-light: hsl($primary-h,$primary-s,calc(#{$primary-l} + 35%));
$primary-super-light: hsl($primary-h,$primary-s,calc(#{$primary-l} + 45%));
$primary-color-light: hsl($primary-h,$primary-s,calc(#{$primary-l} + 15%)) !default;
$primary-med-light: hsl($primary-h,$primary-s,calc(#{$primary-l} + 20%)) !default;
$primary-color-dark: hsl($primary-h,$primary-s,calc(#{$primary-l} - 15%)) !default;
$primary-color-transparent: hsla($primary-h,$primary-s,$primary-l,0.35);

$secondary-color: color("secondary", "base") !default;
$success-color: color("green", "base") !default;
$error-color: color("red", "base") !default;
$link-color: color("light-blue", "darken-1") !default;
$info-color: color("light-blue","base") !default;
$warning-color: color("orange","base") !default;
$ok-color: color("yellow", "base") !default;

$background-off-white: #F8F8F8 !default;
$background-off-white-2: #F2F2F2 !default;


$font-grey: #616161 !default;
$font-light-grey: #787878;
$header-light: rgb(113, 113, 113) !default;
$header-color: rgb(34, 34, 34) !default;

$icon-low-opacity: #C6C5C5 !default;


// 2. Badges
// ==========================================================================

$badge-bg-color: $primary-color !default;
$badge-height: 22px !default;


// 3. Buttons
// ==========================================================================

// Shared styles
$button-border: none !default;
$button-background-focus: $primary-slightly-light !default;
$button-font-size: 14px !default;
$button-icon-font-size: 1.3rem !default;
$button-height: 36px !default;
$button-padding: 0 16px !default;
$button-radius: 5px !default;

// Disabled styles
$button-disabled-background: #DFDFDF !default;
$button-disabled-color: #9F9F9F !default;

// Raised buttons
$button-raised-background: $primary-color !default;
$button-raised-background-hover: $primary-slightly-light !default;
$button-raised-color: #fff !default;
$button-raised-focus-color: hsl($primary-h,$primary-s,calc(#{$primary-l} - 10%));

// Large buttons
$button-large-font-size: 15px !default;
$button-large-icon-font-size: 1.6rem !default;
$button-large-height: $button-height * 1.5 !default;
$button-floating-large-size: 56px !default;

// Small buttons
$button-small-font-size: 13px !default;
$button-small-icon-font-size: 1.2rem !default;
$button-small-height: $button-height * .9 !default;
$button-floating-small-size: $button-height * .9 !default;

// Flat buttons
$button-flat-color: #343434 !default;
$button-flat-disabled-color: lighten(#999, 10%) !default;

// Floating buttons
$button-floating-background: $primary-color !default;
$button-floating-background-hover: $button-floating-background !default;
$button-floating-color: #fff !default;
$button-floating-size: 40px !default;
$button-floating-radius: 50% !default;

// Wide buttons
$button-width-wide: 150px;
$button-width-full: 400px;

// Toggle buttons 
$button-toggle-font-size: 15px !default;
$button-toggle-background: #fff !default;
$button-toggle-border: 1px solid color("grey", "mid-point") !default;;
$button-toggle-radius: 30px !default;
$button-toggle-width: 50px !default;
$button-toggle-height: $button-height * .8 !default;
$button-toggle-outline-width: 2px !default;
$button-toggle-line-border: 1px solid;

//Sign up buttons
$button-sign-up-margin: 20px;
$button-google-color: white !default;
$button-google-color-hover:darken($button-google-color, 3%) !default;
$button-facebook-color:color("blue", "facebook") ;
$button-facebook-color-hover: lighten($button-facebook-color, 5%) !default;
$button-email-color: $primary-color;
$button-email-color-hover: $primary-slightly-light !default;



// 4. Cards
// ==========================================================================

$card-padding: 24px !default;
$card-bg-color: #fff !default;
$card-link-color: $primary-color !default;
$card-link-color-light: $primary-med-light !default;
$card-border-radius: 5px !default;
$signup-recipe-card-border-radius: 5px 5px 0 0 !default;
$btn-border-radius: 5px !default;
$card-background-light: #8E8E8E !default;
$card-title-green: #14CB49 !default;
$card-buttonize-background-hover: darken($card-bg-color, 5%);


// 5. Carousel
// ==========================================================================

$carousel-height: 400px !default;
$carousel-item-height: $carousel-height / 2 !default;
$carousel-item-width: $carousel-item-height !default;


// 6. Collapsible
// ==========================================================================

$collapsible-height: 3rem !default;
$collapsible-line-height: $collapsible-height !default;
$collapsible-header-color: #fff !default;
$collapsible-border-color: #ddd !default;


// 7. Chips
// ==========================================================================

$chip-bg-color: #e4e4e4 !default;
$chip-border-color: #9e9e9e !default;
$chip-selected-color: #26a69a !default;
$chip-margin: 5px !default;


// 8. Date + Time Picker
// ==========================================================================

$datepicker-display-font-size: 2.8rem;
$datepicker-calendar-header-color: #999;
$datepicker-weekday-color: rgba(0, 0, 0, .87) !default;
$datepicker-weekday-bg: $primary-slightly-dark !default;
$datepicker-date-bg: $primary-color !default;
$datepicker-year: rgba(255, 255, 255, .7) !default;
$datepicker-focus: rgba(0,0,0, .05) !default;
$datepicker-selected: $primary-color !default;
$datepicker-selected-outfocus: hsl($primary-h,calc(#{$primary-s} - 15%),calc(#{$primary-l} + 35%)) !default;
$datepicker-day-focus: hsla($primary-h,calc(#{$primary-s} - 5%),$primary-l,0.25) !default;
$datepicker-disabled-day-color: rgba(0, 0, 0, .3) !default;

$timepicker-clock-color: rgba(0, 0, 0, .87) !default;
$timepicker-clock-plate-bg: #eee !default;


// 9. Dropdown
// ==========================================================================

$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: $primary-color !default;
$dropdown-item-height: 50px !default;


// 10. Forms
// ==========================================================================

// Text Inputs + Textarea
$input-height: 3rem !default;
$input-border-color: color("grey", "base") !default;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $success-color !default;
$input-focus-color: $primary-color !default;
$input-font-size: 16px !default;
$input-margin-bottom: 8px;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 0 !default;
$label-font-size: .8rem !default;
$input-disabled-color: rgba(0,0,0, .42) !default;
$input-disabled-solid-color: #949494 !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$input-icon-size: 2rem;
$placeholder-text-color: lighten($input-border-color, 20%) !default;
$wo-wt-number: 30px !default;

// Radio Buttons
$radio-fill-color:  $primary-color !default;
$range-slider-color: hsla($primary-h,$primary-s,$primary-l,0.26);
$radio-empty-color: #5a5a5a !default;
$radio-border: 2px solid $radio-fill-color !default;

// Range
$range-height: 14px !default;
$range-width: 14px !default;
$track-height: 3px !default;

// Select
$select-border: 1px solid #f2f2f2 !default;
$select-background: rgba(255, 255, 255, 0.90) !default;
$select-focus: 1px solid  $primary-super-light !default;
$select-option-hover: rgba(0,0,0,.08) !default;
$select-option-focus: rgba(0,0,0,.08) !default;
$select-option-selected: rgba(0,0,0,.03) !default;
$select-padding: 5px !default;
$select-radius: 2px !default;
$select-disabled-color: rgba(0,0,0,.3) !default;

// Switches
$switch-bg-color: $primary-color !default;
$switch-bg-trans: hsla($primary-h,$primary-s,$primary-l,0.85);
$switch-checked-lever-bg: hsl($primary-h,calc(#{$primary-s} - 25%),calc(#{$primary-l} + 25%)) !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: rgba(0,0,0,.38) !default;
$switch-radius: 15px !default;


// 11. Global
// ==========================================================================

$bottom-scroll-padding: 50px;

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$extra-small-screen: 350px !default;
$med-small-down-screen: 450px !default;
$med-small-up-screen: 451px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$extra-short-screen: 570px !default;
$short-screen: 675px !default;
$long-screen: 800px !default;
$set-background-width: 500px;
//for recipe image fits 
$rc-small-screen: 522px !default;
$rc-small-screen-up: 523px !default;
$arrows-hide-width: 700px !default;
$simple-nav-btn-drop: "only screen and (max-width: 550px)" !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$extra-small-and-down: "only screen and (max-width: #{$extra-small-screen})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
$long-screen-only: "only screen and (min-height : #{$long-screen})" !default;
$short-screen-only: "only screen and (max-height : #{$short-screen})" !default;
$extra-short-screen-only: "only screen and (max-height : #{$extra-short-screen})" !default;
$med-small-and-down: "only screen and (max-width: #{$med-small-down-screen})" !default;
$med-small-up: "only screen and (min-width: #{$med-small-up-screen})" !default;
//For recipe image fits 
$rc-small-and-down: "only screen and (max-width : #{$rc-small-screen})" !default;
$rc-medium-and-up: "only screen and (min-width : #{$rc-small-screen-up})" !default;
$arrows-hide: "only screen and (max-width : #{$arrows-hide-width})" !default;
$arrows-wide: "only screen and (min-width : #{$arrows-hide-width})" !default;

// 12. Grid
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;


// 13. Navigation Bar
// ==========================================================================

$bottom-nav-height: 42px !default;
$bottom-nav-offset-top: calc(var(--sat) + #{$bottom-nav-height});
$bottom-nav-height-safe: calc(var(--sat) + #{$bottom-nav-height});
$bottom-nav-cont-padding: calc(var(--sab) + #{2*$bottom-nav-height});
$bottom-nav-min-padding: calc(var(--sab) + #{$bottom-nav-height});
$messaging-height-sm: calc(var(--wih) - #{$bottom-nav-height});
$messaging-height-lg: calc(var(--wih) - #{$bottom-nav-height});
$navbar-height: 64px !default;
$navbar-line-height: $navbar-height !default;
$navbar-height-mobile: 56px !default;
$navbar-line-height-mobile: $navbar-height-mobile !default;
$navbar-font-size: 1rem !default;
$navbar-font-color: #fff !default;
$navbar-brand-font-size: 2.1rem !default;
$simple-nav-height-num: 50;
$simple-nav-height: #{$simple-nav-height-num}px;
$simple-nav-height-safe: calc(var(--sat) + #{$simple-nav-height});
$simple-nav-cont-padding: #{$simple-nav-height-num + 15}px;
$messaging-height-standalone: calc(var(--wih) - #{$simple-nav-height});
$signed-in-as-height: 15px;
$chat-subhead-height: 42px !default;


// 14. Side Navigation
// ==========================================================================

$sidenav-width: 250px !default;
$sidenav-font-size: 14px !default;
$sidenav-font-color: rgba(0,0,0,.87) !default;
$sidenav-bg-color: #fff !default;
$sidenav-padding: 12px !default;
$sidenav-item-height: 40px !default;
$sidenav-line-height: $sidenav-item-height !default;


// 15. Photo Slider
// ==========================================================================

$slider-bg-color: color('grey', 'base') !default;
$slider-bg-color-light: color('grey', 'lighten-2') !default;
$slider-indicator-color: color('green', 'base') !default;


// 16. Spinners | Loaders
// ==========================================================================

$spinner-default-color: $primary-color !default;


// 17. Tabs
// ==========================================================================

$tabs-underline-color: $primary-color-light !default;
$tabs-underline-transparent: hsla($primary-h,$primary-s,calc(#{$primary-l} + 5%),0.2);
$tabs-text-color: $primary-color !default;
$tabs-text-color-inactive: hsla($primary-h,$primary-s,$primary-l,0.7);
$tabs-text-color-trans: hsla($primary-h,$primary-s,$primary-l,0.4);
$tabs-bg-color: #fff !default;


// 18. Tables
// ==========================================================================

$table-border-color: rgba(0,0,0,.12) !default;
$table-striped-color: rgba(242, 242, 242, 0.75) !default;
$table-striped-even-color: rgba(255, 255, 255, 0.75) !default;



// 19. Toasts
// ==========================================================================

$toast-height: 48px !default;
$toast-color: #323232 !default;
$toast-text-color: #fff !default;
$toast-action-color: #eeff41;


// 20. Typography
// ==========================================================================

$font-stack: 'Roboto', sans-serif !default;//"Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-alt: 'Roboto Condensed', sans-serif !default;
$off-black: rgba(0, 0, 0, 0.87) !default;
$medium-emphasis: rgba(0, 0, 0, 0.6) !default;
$low-emphasis: rgba(0, 0, 0, 0.38) !default;
$low-em-dark-bcg: rgba(255, 255, 255, 0.65) !default;
// Header Styles
$h1-fontsize: 4.2rem !default;
$h2-fontsize: 3.56rem !default;
$h3-fontsize: 2.92rem !default;
$h4-fontsize: 2.28rem !default;
$h5-fontsize: 1.64rem !default;
$h6-fontsize: 1.15rem !default;


// 21. Footer
// ==========================================================================

$footer-font-color: #fff !default;
$footer-bg-color: $primary-color !default;
$footer-copyright-font-color: rgba(255,255,255,.8) !default;
$footer-copyright-bg-color: rgba(51,51,51,.08) !default;


// 22. Flow Text
// ==========================================================================

$range : $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;


// 23. Collections
// ==========================================================================

$collection-border-color: #e0e0e0 !default;
$collection-bg-color: #fff !default;
$collection-active-bg-color: $primary-color !default;
$collection-active-color: $primary-super-light !default;
$collection-hover-bg-color: #ddd !default;
$collection-link-color: $primary-color !default;
$collection-line-height: 1.5rem !default;


// 24. Progress Bar
// ==========================================================================

$progress-bar-color: $primary-color !default;
$progress-bar-super-light: hsl($primary-h,$primary-s - 15%,calc(#{$primary-l} + 40%));


// 25. Etc.
// ==========================================================================

$max-width-list: 523px;
$wooel-height: 55px;

//Macro Variables
$protein-color: #3fa054;
$carb-color: #3366CC;
$fat-color: #FF9900;
$fiber-color: #979dac;
$alcohol-color: #990099;

$chat-sidebar-width: 385px;

$circle-img-dim-num: 40;
$circle-img-dim: #{$circle-img-dim-num}px;
$two-circle-dim:  #{$circle-img-dim-num/2}px;
$three-circle-dim: #{$circle-img-dim-num/3}px;

