.client-dash-card {

    &.extra-top-padding {
        padding-top: 15px;
    }

    &.average {
        &:not(.check-only) {
            background-color: color("grey","lighten-3");
        }
        border-radius: 6px;
        .metric-container .profile {
            align-self: flex-start;
            color: $font-grey;
            line-height: 1.1;
            @media(max-width: 360px) {
                font-size: 12px;
            }
        }
    }

    &.title-row {
        .metric-container {
            height: auto;
        }
    }

    .metric-container {
        display: flex;
        height: 100px;
        align-items: center;
        justify-content: flex-end;
        position: relative;
    
        .profile {
            flex-grow: 1;
            padding: 5px;
            overflow: hidden;
            flex-shrink: 100;
            min-width: 25%;
            @media(max-width: 360px) {
                min-width: unset;
            }

            .check-container {
                position: absolute;
                left: 0;
                bottom: 0;
                height: auto;
            }

            .profile-wrap {
                display: flex;
                align-items: center;
                flex-direction: column;

                .profile-name {
                    @include listing-title-large;
                    @include abbr-text;
                    max-width: 100%;
                    margin-left: 5px;
                    margin-right: 5px;
                }
                @media #{$small-and-down} {
                    .profile-name {
                        @include listing-title;
                        line-height: 1.2;
                    }
                }
                @media(max-width: 360px) {
                    .profile-name {
                        font-size: 12px;
                    }
                }
            }
        }
    
        .metric {
            &.three {
                width: 20%;
            }
            &.two {
                width: 30%;
            }
            &.one {
                width: 60%;
            }
            min-width: 100px;
            text-align: center;
            .metric-title {
                display: inline-block;
                color: #aaa;
                text-align: center;
                line-height: 1;
                position: relative;
                .q-icon {
                    position: absolute;
                    font-size: 11px;
                    top: -5.5px;
                    right: -5.5px;
                }
            }
        }

        .filler {
            flex-grow: 100;
            flex-shrink: 999;
        }

        .dropdown-container, .check-container {
            width: 45px;
            height: 100%;
            display: flex;
            flex-shrink: 0;
            align-self: center;
            justify-content: center;
            align-items: center;
        }

        @media #{$small-and-down} {
            .check-container.all-check {
                left: -5px;
                bottom: 0;
            }
            .check-container:not(.all-check) {
                width: 0;
                label {
                    position:  absolute;
                    top: 5px;
                    left: 5px;
                }
            }
        }
    }

    &.extra-top-padding {
        .metric-container {
            @media #{$small-and-down} {
                .check-container:not(.all-check) {
                    label {
                        top: -10px;
                    }
                }
            }
        }
    }
}

.client-title {
    @include listing-title;
    line-height: 1.2;
}

.client-setup-card {
    padding: 15px;
    border-left: 10px solid $primary-color;

    .prompt {
        font-size: 18px;
        font-weight: 400;
        color: $header-color;
    }
}

.client-goal-card {
    padding: 5px 15px;
    border-left: 10px solid $primary-color;
    display: flex;
    align-items: center;

    .goal-cont {
        flex-shrink: 1;
        padding-right: 5px;
        overflow-x: hidden;
    }

    .action-cont {
        flex-grow: 1;
        text-align: right;
        white-space: nowrap;
    }

    .agoal-label {
        font-size: 12px;
    }

    .agoal-text {
        font-size: 16px;
        font-weight: bold;
        @include abbr-text;
    }

    .agoal-sub {
        font-size: 10px;
        color: $font-grey;
        margin-top: -5px;
    }
}

.t-conv-title {
    font-size: 24px;
}


.t-conv-logo {
    position: absolute;
    top: calc(var(--sat) + 10px);
    right: 10px;
    height: 60px;
    @media #{$small-and-down} {
        height: 40px;
    }
}

.conv-bill-cycle-tip {
    font-size: 12px;
}

.t-conv-prod-box {
    position: relative;
    display: flex;
    text-align: center;
    .product {
        position: relative;
        flex: 1;
        height: 60vh;
        @each $plan-type in $trainer-plans {
            &.#{$plan-type} {
                background-color: color($plan-type,"bcg");
                &.show-border {
                    border-top: 8px solid color($plan-type,"border");
                }
            }
        }
        .plan-name {
            font-size: 15px;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .price {
            font-size: 24px;
            margin-top: 15px;
            margin-bottom: 40px;
            .subtitle {
                color: $font-grey;
                font-size: 12px;
            }
        }

        .trial-info {
            font-size: 14px;
            margin-bottom: 20px;
        }

        .feature-summary {
            font-size: 13px;
        }

        .cta-cont {
            position: absolute;
            bottom: 20px;
            width: 100%;
        }
    }
}

.t-conv-faq-q {
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 15px;
    padding-bottom: 5px;
    .tilde {
        float: right;
        color: $font-grey;
    }
}

.contact-text {
    font-size: 14px;
}

table.t-conv-feat-table {
    position: relative;
    border-collapse: collapse;
    thead.main-head {
        tr {
            height: 1px;
            th {
                height: inherit;
            }
        }
        th {
            padding: 0;
            position: sticky;
            top: var(--sat);
            .prod {
                position: relative;
                color: white;
                padding: 20px 10px;
                border-radius: 0;
                font-weight: normal;
                text-align: center;
                font-size: 15px;
                height: 100%;
                min-height: 150px;
                .prod-text {
                    writing-mode: vertical-lr;
                    text-orientation: mixed;
                    display: inline-block;
                    margin-bottom: 20px;
                }
                @each $plan-type in $trainer-plans {
                    &.#{$plan-type} {
                        border-bottom: 2px solid color($plan-type,"border");
                        color: $off-black;
                        background-color: color($plan-type,"bcg");
                    }
                }
                .details {
                    color: $font-grey;
                    font-size: 10px;
                    display: none;
                }
                .cta-text {
                    display: none;
                }
                .cta-icon {
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                @media(min-width: 750px) {
                    .prod-text {
                        writing-mode: horizontal-tb;
                        text-orientation: mixed;
                        margin-bottom: 0;
                    }
                    .details {
                        display: block;
                    }
                    .cta-text {
                        display: inline-block;
                    }
                    .cta-icon {
                        display: none;
                    }
                }
            }
        }
        tr {
            border-bottom: none;
        }
    }
    thead {
        .section-head {
            font-size: 20px;
            text-align: left;
        }
        tr {
            border-bottom: 1px solid $off-black;
        }
        td {
            border: none;
        }
    }
    td {
        text-align: center;
        padding: 9px 5px;
        &.check-cell {
            border-left: 1px solid rgba(0, 0, 0, 0.12)
        }
    }

    tr:not(:last-of-type) {
        td:not(.empty):not(.offset) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    td.empty {
        width: 35px;
        @media #{$small-and-down} {
            width: 10px;
        }
    }

    td.offset {
        width: 15px;
    }

    td.feature-text {
        text-align: left;
        width: 200px;
        font-size: 12px;
        @media #{$small-and-down} {
            width: 150px;
        }
    }

    td.sub-header {
        font-weight: bold;
        text-align: left;
        padding-bottom: 0;
    }

    tr {
        border: none;
    }
}

@mixin chat-center {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
}

.card.trainer-note-list {
    padding: 10px 0 10px 10px;
    .note-cont {
        max-height: 100%;
        padding-right: 10px;
        overflow-y: auto;
    }
    height: 300px;
    max-height: 300px;
}

.tnote-title {
    font-size: 18px;
    font-weight: 400;
    color: $header-color;
    @include abbr-text;
}

.mp-list-title {
    font-size: 15px;
    color: $off-black;
    font-weight: bold;
}


.chat-sidebar {
    position: fixed;
    width: $chat-sidebar-width;
    height: calc(var(--wih) - #{$bottom-nav-offset-top});
    top: $bottom-nav-offset-top;
    &.standalone {
        top: $simple-nav-height-safe;
        .message-head {
            top: $simple-nav-height-safe;
        }
        z-index: 100;
    }
    background-color: white;
    z-index: 11;
    border-top: 1px solid #ddd;
    @extend .elevation-4-dp;
    clip-path: inset(0 -10px -10px -10px);
    overflow-y: auto;
    padding-top: $chat-subhead-height;
}

.message-head {
    height: $chat-subhead-height;
    background-color: white;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    position: fixed;
    top: $bottom-nav-offset-top;
    width: $chat-sidebar-width;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    z-index: 99;
    &.standalone {
        top: $simple-nav-height-safe;
    }
}

@media #{$medium-and-down} {
    .message-head {
        top: 0;
        width: 100%;
        left: 0;
        border: none;
        @include elevation-dp(4);
    }

    .chat-list {
        padding-top: $chat-subhead-height;
    }
}

.chat-header {
    position: fixed;
    width: calc(100% - #{$chat-sidebar-width});
    left: $chat-sidebar-width;
    top: $bottom-nav-offset-top;
    &.standalone {
        top: $simple-nav-height-safe;
        z-index: 101;
    }
    height: $chat-subhead-height;
    clip-path: inset(0 -10px -70vh 0);
    @extend .elevation-4-dp;
    z-index: 12;
    background-color: white;
    border-top: 1px solid #ddd;
    padding: 0 10px 0 30px;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.chat-header, .message-head {
    .chat-menu {
        position: absolute;
        width: $chat-subhead-height;
        height: $chat-subhead-height;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.main-chat-area {
    margin-left: $chat-sidebar-width;
}

.chat-activity {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: $messaging-height-standalone;
    background-color: white;
    &.desktop {
        padding: $bottom-nav-offset-top 10px 0 10px; //for the chat header
        height: $messaging-height-sm;
        @media #{$large-and-up} {
            height: $messaging-height-lg;
        }
        &.standalone {
            height: $messaging-height-standalone;
        }
    }
}

.zoom-bottom-cont {
    @include chat-center;
    position: relative;
}

.chat-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    position: relative;
    &.main {
        flex-grow: 1;
    }

    .zoom-to-bottom {
        position: absolute;
        @extend .elevation-4-dp;
        background-color: white;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        bottom: 10px;
        right: 0;
        cursor: pointer;
        transform: scale(1);
        transition: transform 0.35s ease-out;
        &.inactive {
            transform: scale(0);
        }
        svg {
            position: absolute;
            font-size: 24px;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .chat-scroll {
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        left: 0;
        top: 0;
    }

    .chat-rows-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include chat-center;
    }
}

.new-msg-wrap {
    width: 100%;
    @extend .elevation-4-dp;
    border-radius: 15px;
    padding: 5px 10px;
    margin-bottom: 10px;
    background-color: white;
    .new-msg-inputs-wrap {
        display: flex;
        align-items: flex-end;
    }

    .new-msg {
        min-height: 38px;
        &:not(.archived) {
            height: 38px;
        }
        font-size: 15px;
        line-height: 15px;
        max-height: 40vh;
        &.with-img {
            max-height: 20vh;
        }
        padding: 10px 5px;
        width: 100%;
        overflow-y: auto;
        flex-grow: 1;
        resize: none;
        border: none;
        &.archived {
            text-align: center;
            color: $font-grey;
        }
    }

    .msg-action-btn {
        padding: 0 7.5px;
        cursor: pointer;
        font-size: 28px;
        color: $font-grey;
        &.send {
            color: $primary-color;
        }
    }
}

.draft-img {
    text-align: center;
    line-height: 0;
    border-bottom: 1px solid #ddd;
    position: relative;
    img {
        max-width: 100%;
        max-height: 35vh;
        display: inline-block;
    }
}

.msg-img {
    width: 100%;
    display: block;
    img {
        max-width: 100%;
    }
}

.img-view-cont {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    z-index: 999;

    &.entering, &.entered {
        transform: scale(1);
        opacity: 1;
    }

    &.exiting, &.exited {
       transform: scale(0.1);
       opacity: 0;
    }

    .img-view-bcg {
        background-color: #000000;
        width: 100%;
        height: 100%;
        z-index: 999;
        position: absolute;
        top: 0;
        left: 0;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        z-index: 1000;
        @include abs-centered;
    }
}


.chat-date-break {
    text-align: center;
    margin: 10px;
    color: $font-grey;
    font-size: 12px;
}

.chat-bubble-group {
    flex-grow: 1;
    overflow: hidden;

    .sender-name {
        font-weight: 500;
        font-size: 13px;
        margin-left: 9px;
    }

    .response-to-block {
        border-radius: 4px;
        border-left: 4px solid #00000088;
        background-color: #00000033;
        padding: 3px;
        display: flex;
        align-items: center;
        margin-left: -8px;
        margin-right: -8px;
        cursor: pointer;
        &.self {
            border-left: 4px solid #ffffff88;
            background-color: #ffffff33;
        }

        .media-cont {
            position: relative;
            height: 50px;
            width: 50px;
            font-size: 40px;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
            margin-right: 5px;
        }

        .text-cont {
            overflow: hidden;
            flex-grow: 1;
            .reply-title {
                @include abbr-text;
                font-weight: 500;
            }
            .reply-sub {
                @include abbr-text;
                font-size: 0.9em;
            }
        }
    }

    .msg-cont {
        display: flex;
        position: relative;
        &.self {
            flex-direction: row-reverse;
            margin: 2.5px 9px 2.5px 5px;
            .chat-bubble {
                background-color: #175AE2;
                color: white;
                a {
                    color: white;
                }
            }
        }

        &:not(.self) {
            margin: 2.5px 5px 2.5px 9px;
        }

        .bubble-wrap {
            max-width: 500px;
            overflow: hidden;
        }

        .chat-bubble {
            background-color: #E1E3E4;
            padding: 5px 15px;
            border-radius: 5px;
            cursor: pointer;
            overflow-wrap: break-word;
            a {
                text-decoration: underline;
            }
            &.group-first {
                margin-top: 0;
            }
        }
    }
}

.chat-bgroup-wrap {
    width: 100%;
    display: flex;
    align-items: flex-end;
    &.first-group {
        margin-top: 15px;
    }
    margin-bottom: 15px;
    .avi-cont {
        flex-shrink: 0;
        flex-grow: 0;
        padding-bottom: 3px;
        &.with-tstamp {
            padding-bottom: 14px;
        }
    }
}

.chat-list-entry {
    cursor: pointer;
    display: flex;
    padding: 7.5px 10px;
    max-width: 100%;
    border-bottom: 1px solid #ddd;
    &.active, &:hover {
        background-color: #F1F3F4;
    }

    .cinfo-wrap {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        .title-time {
            display: flex;
            .ctitle {
                flex-grow: 1;
                font-size: 15px;
            }

            .ctime {
                font-size: 12px;
                color: $font-grey;
            }
        }

        .cdetail {
            color: $font-grey;
            font-size: 14px;
            flex-grow: 1;
            overflow: hidden;
        }
    }

    .cicon-wrap {
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
}

.unread-marker {
    height: 25px;
    background-color: #E1E3E4;
    text-align: center;
    width: 100%;
    margin: 10px 0;
}

.fixed-action-btn.sidebar {
    left: 300px;
}

@media #{$large-and-up} {
    *:hover::-webkit-scrollbar {
        opacity: 1;
    }

    html:not(.is-safari):not(.is-ios) ::-webkit-scrollbar {
        height: 0.5rem;
        opacity: 1;
        width: 0.375rem;
    }
    
    ::-webkit-scrollbar-button {
        display: none;
        height: 0;
        width: 0;
    }
    
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
    
    *:hover::-webkit-scrollbar-thumb {
        -webkit-backdrop-filter: blur(100);
        backdrop-filter: blur(100);
        background-color: rgba(0,0,0,.2);
        border-radius: 10px;
        max-height: 12.5rem;
        min-height: 5rem;
        opacity: 1;
    }

    ::-webkit-scrollbar-thumb {
        height: 0;
        width: 0;
    }

    ::-webkit-scrollbar-thumb {
        opacity: 0;
        -webkit-transition: .2s ease-in-out;
        transition: .2s ease-in-out;
    }
}

@media #{$medium-and-down} {

    .photo-view::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
}

.input-field.cli-search-field {
    margin: 0 5px;
    display: block;
    flex-grow: 1;
}

.inv-table {
    padding: 10px;
    .ithead {
        padding: 10px 10px 0px 10px;
        display: flex;
        .ls {
            font-size: 15px;
            font-weight: bold;
        }
    }
    .itrow {
        padding: 10px 10px 10px 25px;
        border-top: 1px solid #ddd;
        display: flex;
    }

    .ithead, .itrow {
        &.gap-down {
            border-top: none;
            padding-top: 25px;
        }

        .rs {
            flex-grow: 1;
            text-align: right;
        }

        .ls {
            flex-shrink: 1;
            text-align: left;
        }
    }

}

.em-food-res {
    font-size: 12px;
    font-weight: bold;
}

.possible-recs-cont {
    max-width: 800px;
    margin: auto;
}

@media #{$extra-large-and-up} {
    .possible-recs-cont {
        max-width: 1100px;
    }
}

.label-w-menu {
    display: flex;
    .label-cont {
        flex-grow: 1;
    }

    .menu-cont {
        width: 40px;
        flex-shrink: 0;
        cursor: pointer;
        text-align: center;
        font-size: 25px;
    }
}

.field-label {
    font-size: 18px;
    line-height: 1.2;
}

.tform {
    .tform-body {
        h1 {
            font-size: 25px;
            text-align: center;
        }

        h2 {
            font-size: 22.5px;
            svg {
                font-size: 14.5px;
            }
        }

        p {
            text-align: left;
            margin-top: 0;
            margin-bottom: 15px;
        }

        .field-edit-cont {
            padding: 20px;
            background-color: white;
            position: relative;
            border: 1px solid #ddd;
            border-radius: 5px;
            margin: 15px 0;

            .drag-handle {
                position: absolute;
                top: 50%;
                left: -5px;
                font-size: 20px;
                padding: 10px;
                cursor: pointer;
                transform: translateY(-50%);
            }
        }

        .upper-right-btn {
            position: absolute;
            top: 5px;
            right: 10px;
            font-size: 18px;
            padding-left: 20px;
            cursor: pointer;
            &.for-field {
                top: 0;
                right: 5px;
                font-size: 16px;
            }
        }
    }
}

.inline-icon {
    min-width: 30px;
    text-align: center;
}

.mood-field-cont {
    max-width: 375px;
    .mood-labels {
        display: flex;
        font-size: 11px;
        color: $font-grey;
        .left-label {
            text-align: left;
            margin-left: 20px;
            flex-grow: 1;
        }
        .right-label {
            text-align: right;
            margin-right: 20px;
            flex-grow: 1;
        }
    }
}

.mood-field {
    display: flex;
    flex-wrap: nowrap;
    &:not(.read-only) {
        svg {
            cursor: pointer;
        }
    }
    svg {
        flex-shrink: 100;
        stroke-width: 0px;
        border-radius: 10px;
        width: 100%;
        height: 100%;

        &.sel {
            fill: white;
            &.mood-1 {
                background-color: #ff3932;
            }

            &.mood-2 {
                background-color: #fe6b28;
            }

            &.mood-3 {
                background-color: #fe9b26;
            }

            &.mood-4 {
                background-color: #b3c943;
            }

            &.mood-5 {
                background-color: #44cb52;
            }
        }

        &:not(.sel) {
            &.mood-1 {
                fill: #ff3932;
            }

            &.mood-2 {
                fill: #fe6b28;
            }

            &.mood-3 {
                fill: #fe9b26;
            }

            &.mood-4 {
                fill: #b3c943;
            }

            &.mood-5 {
                fill: #44cb52;
            }
        }

    }
}
