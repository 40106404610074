/* Select Field
   ========================================================================== */

select { display: none; }
select.browser-default { display: block; }

select {
  background-color: $select-background;
  width: 100%;
  padding: $select-padding;
  border: $select-border;
  border-radius: $select-radius;
  height: $input-height;
  &.browser-default {
      &.small-inline {
          display: inline-block;
          max-width: 120px;
      }
      &.auto-height {
        height: auto;
        max-height: 150px;
      }
  }

  &.default-font {
    color: $font-grey;
    font-weight: 300;
  }

  &.select-signup {
    width:30%;
    min-width: 150px;
    display: block;
    margin: 0 auto;
    color: $font-grey;
    font-weight: 300;
  }
  &.select-signup-medium {
    width: 83px;
    margin: 0 auto;
    color: $font-grey;
    font-weight: 300;
  }
  &.select-signup-small{
    width: 55px;
    margin: 0 auto;
    color: $font-grey;
    font-weight: 300;
  }
  &.select-signup-tiny{
    width: 43px;
    margin: 0 auto;
    color: $font-grey;
    font-weight: 300;
  }
  &.inline {
    display: inline;
  }
}

.select-label {
  position: absolute;
}

.select-wrapper {
  &.valid .helper-text[data-success],
  &.invalid ~ .helper-text[data-error] {
    @extend %hidden-text;
  }

  &.valid {
    & > input.select-dropdown {
      @extend %valid-input-style;
    }

    & ~ .helper-text:after {
      @extend %custom-success-message;
    }
  }

  &.invalid {
    & > input.select-dropdown,
    & > input.select-dropdown:focus {
      @extend %invalid-input-style;
    }

    & ~ .helper-text:after {
      @extend %custom-error-message;
    }
  }

  &.valid + label,
  &.invalid + label {
    width: 100%;
    pointer-events: none;
  }

  & + label:after {
    @extend %input-after-style;
  }

  position: relative;

  input.select-dropdown {
    &:focus {
      border-bottom: 1px solid $input-focus-color;
    }
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: $input-border;
    outline: none;
    height: $input-height;
    line-height: $input-height;
    width: 100%;
    font-size: $input-font-size;
    margin: $input-margin;
    padding: 0;
    display: block;
    user-select:none;
    z-index: 1;
  }

  .caret {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 0;
    fill: rgba(0,0,0,.87);
  }

  & + label {
    position: absolute;
    top: -26px;
    font-size: $label-font-size;
  }
}

// Disabled styles
select:disabled {
  color: $input-disabled-color;
}

.select-wrapper.disabled {
  + label {
    color: $input-disabled-color;
  }
  .caret {
    fill: $input-disabled-color;
  }
}

.select-wrapper input.select-dropdown:disabled {
  color: $input-disabled-color;
  cursor: default;
  user-select: none;
}

.select-wrapper i {
  color: $select-disabled-color;
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
  color: $select-disabled-color;
  background-color: transparent;
}

body.keyboard-focused {
  .select-dropdown.dropdown-content li:focus {
    background-color: $select-option-focus;
  }
}

.select-dropdown.dropdown-content {
  li {
    &:hover {
      background-color: $select-option-hover;
    }

    &.selected {
      background-color: $select-option-selected;
    }
  }
}

// Prefix Icons
.prefix ~ .select-wrapper {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}

.prefix ~ label { margin-left: 3rem; }

// Icons
.select-dropdown li {
  img {
    height: $dropdown-item-height - 10;
    width: $dropdown-item-height - 10;
    margin: 5px 15px;
    float: right;
  }
}

// Optgroup styles
.select-dropdown li.optgroup {
  border-top: 1px solid $dropdown-hover-bg-color;

  &.selected > span {
    color: rgba(0, 0, 0, .7);
  }

  & > span {
    color: rgba(0, 0, 0, .4);
  }

  & ~ li.optgroup-option {
    padding-left: 1rem;
  }
}

//SemiTransparent Select
.semitransparent-select {
  background-color: rgba(255, 255, 255, .2);
  border: 1px solid white;
  color: white;
  border-radius: 6px;
  text-transform: none;
  padding: 0 10px;
  line-height: 30px;
  margin-bottom: 10px;
  margin-left:7px;
  margin-right: 9px;
  height: 36px;
  width: fit-content;
  font-size: 12px;
  padding: 0px 2px;
  @extend .z-depth-1;
  transition: background-color .2s ease-out transform .2s ease-out;
  cursor: pointer;
  &:hover {
    @extend .z-depth-1-half;
  }
  &:active {
    transform: scale(0.95);
  }
  &.dark {
    border: 1px solid $font-grey;
    color: $font-grey;
    padding: 0px 20px 0px 20px;
  }
}

.select-btns {
  display: inline-block;

  .btn {
    text-align: center;
    .btn-content {
      display: unset;
    }
  }

  &.compact {
    .btn {
      width: 60px;
      padding: 0;
    }
  }

  &.min-width {
    .btn {
      min-width: 80px
    }
    @media(max-width: 400px) {
      .btn {
        min-width: 60px;
      }
    }
  }

  &.min-width-large {
    .btn {
      min-width: 140px
    }
  }

  &.no-upcase {
    .btn {
      text-transform: none;
    }
  }

  .first-label {
    float: left;
    color: $font-grey;
  }
  .last-label {
    float: right;
    color: $font-grey;
  }
}
