.collapse {
    display: block;
    overflow: hidden;
    will-change: height;
}

@keyframes expandAnimation {
    
    0% {
        transform: scale(0, 0);
    }
    10% {
        transform: scale(0.3438999999999999, 0.3438999999999999);
    }
    20% {
        transform: scale(0.5903999999999999, 0.5903999999999999);
    }
    30% {
        transform: scale(0.7599, 0.7599);
    }
    40% {
        transform: scale(0.8704000000000001, 0.8704000000000001);
    }
    50% {
        transform: scale(0.9375, 0.9375);
    }
    60% {
        transform: scale(0.9744, 0.9744);
    }
    70% {
        transform: scale(0.9919, 0.9919);
    }
    80% {
        transform: scale(0.9984, 0.9984);
    }
    90% {
        transform: scale(0.9999, 0.9999);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes expandContentsAnimation {

    0% {
        transform: scale(999, 999);
    }
    10% {
        transform: scale(2.907822041291074, 2.907822041291074);
    }
    20% {
        transform: scale(1.693766937669377, 1.693766937669377);
    }
    30% {
        transform: scale(1.3159626266614028, 1.3159626266614028);
    }
    40% {
        transform: scale(1.1488970588235294, 1.1488970588235294);
    }
    50% {
        transform: scale(1.0666666666666667, 1.0666666666666667);
    }
    60% {
        transform: scale(1.026272577996716, 1.026272577996716);
    }
    70% {
        transform: scale(1.0081661457808246, 1.0081661457808246);
    }
    80% {
        transform: scale(1.001602564102564, 1.001602564102564);
    }
    90% {
        transform: scale(1.000100010001, 1.000100010001);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes collapseAnimation {

    0% {
        transform: scale(1, 1);
    }
    10% {
        transform: scale(0.6561000000000001, 0.6561000000000001);
    }
    20% {
        transform: scale(0.4096000000000001, 0.4096000000000001);
    }
    30% {
        transform: scale(0.24009999999999998, 0.24009999999999998);
    }
    40% {
        transform: scale(0.12959999999999994, 0.12959999999999994);
    }
    50% {
        transform: scale(0.0625, 0.0625);
    }
    60% {
        transform: scale(0.025599999999999956, 0.025599999999999956);
    }
    70% {
        transform: scale(0.008099999999999996, 0.008099999999999996);
    }
    80% {
        transform: scale(0.0016000000000000458, 0.0016000000000000458);
    }
    90% {
        transform: scale(0.00009999999999998899, 0.00009999999999998899);
    }
    100% {
        transform: scale(0, 0);
    }
}

@keyframes collapseContentsAnimation {

    0% {
        transform: scale(1, 1);
    }
    10% {
        transform: scale(1.5241579027587255, 1.5241579027587255);
    }
    20% {
        transform: scale(2.4414062499999996, 2.4414062499999996);
    }
    30% {
        transform: scale(4.164931278633903, 4.164931278633903);
    }
    40% {
        transform: scale(7.7160493827160535, 7.7160493827160535);
    }
    50% {
        transform: scale(16, 16);
    }
    60% {
        transform: scale(39.062500000000064, 39.062500000000064);
    }
    70% {
        transform: scale(123.45679012345686, 123.45679012345686);
    }
    80% {
        transform: scale(624.9999999999822, 624.9999999999822);
    }
    90% {
        transform: scale(10000.0000000011, 10000.0000000011);
    }
    100% {
        transform: scale(999, 999);
    }
}

@keyframes expandXAnimation {

    0% {
        transform: scaleX(0);
    }
    10% {
        transform: scaleX(0.3438999999999999);
    }
    20% {
        transform: scaleX(0.5903999999999999);
    }
    30% {
        transform: scaleX(0.7599);
    }
    40% {
        transform: scaleX(0.8704000000000001);
    }
    50% {
        transform: scaleX(0.9375);
    }
    60% {
        transform: scaleX(0.9744);
    }
    70% {
        transform: scaleX(0.9919);
    }
    80% {
        transform: scaleX(0.9984);
    }
    90% {
        transform: scaleX(0.9999);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes expandContentsXAnimation {

    0% {
        transform: scaleX(999);
    }
    10% {
        transform: scaleX(2.907822041291074);
    }
    20% {
        transform: scaleX(1.693766937669377);
    }
    30% {
        transform: scaleX(1.3159626266614028);
    }
    40% {
        transform: scaleX(1.1488970588235294);
    }
    50% {
        transform: scaleX(1.0666666666666667);
    }
    60% {
        transform: scaleX(1.026272577996716);
    }
    70% {
        transform: scaleX(1.0081661457808246);
    }
    80% {
        transform: scaleX(1.001602564102564);
    }
    90% {
        transform: scaleX(1.000100010001);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes collapseXAnimation {

    0% {
        transform: scaleX(1);
    }
    10% {
        transform: scaleX(0.6561000000000001);
    }
    20% {
        transform: scaleX(0.4096000000000001);
    }
    30% {
        transform: scaleX(0.24009999999999998);
    }
    40% {
        transform: scaleX(0.12959999999999994);
    }
    50% {
        transform: scaleX(0.0625);
    }
    60% {
        transform: scaleX(0.025599999999999956);
    }
    70% {
        transform: scaleX(0.008099999999999996);
    }
    80% {
        transform: scaleX(0.0016000000000000458);
    }
    90% {
        transform: scaleX(0.00009999999999998899);
    }
    100% {
        transform: scaleX(0);
    }
}

@keyframes collapseContentsXAnimation {

    0% {
        transform: scaleX(1);
    }
    10% {
        transform: scaleX(1.5241579027587255);
    }
    20% {
        transform: scaleX(2.4414062499999996);
    }
    30% {
        transform: scaleX(4.164931278633903);
    }
    40% {
        transform: scaleX(7.7160493827160535);
    }
    50% {
        transform: scaleX(16);
    }
    60% {
        transform: scaleX(39.062500000000064);
    }
    70% {
        transform: scaleX(123.45679012345686);
    }
    80% {
        transform: scaleX(624.9999999999822);
    }
    90% {
        transform: scaleX(10000.0000000011);
    }
    100% {
        transform: scaleX(999);
    }
}

@keyframes expandYAnimation {

    0% {
        transform: scaleY(0);
    }
    10% {
        transform: scaleY(0.3438999999999999);
    }
    20% {
        transform: scaleY(0.5903999999999999);
    }
    30% {
        transform: scaleY(0.7599);
    }
    40% {
        transform: scaleY(0.8704000000000001);
    }
    50% {
        transform: scaleY(0.9375);
    }
    60% {
        transform: scaleY(0.9744);
    }
    70% {
        transform: scaleY(0.9919);
    }
    80% {
        transform: scaleY(0.9984);
    }
    90% {
        transform: scaleY(0.9999);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes expandContentsYAnimation {

    0% {
        transform: scaleY(999);
    }
    10% {
        transform: scaleY(2.907822041291074);
    }
    20% {
        transform: scaleY(1.693766937669377);
    }
    30% {
        transform: scaleY(1.3159626266614028);
    }
    40% {
        transform: scaleY(1.1488970588235294);
    }
    50% {
        transform: scaleY(1.0666666666666667);
    }
    60% {
        transform: scaleY(1.026272577996716);
    }
    70% {
        transform: scaleY(1.0081661457808246);
    }
    80% {
        transform: scaleY(1.001602564102564);
    }
    90% {
        transform: scaleY(1.000100010001);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes collapseYAnimation {

    0% {
        transform: scaleY(1);
    }
    10% {
        transform: scaleY(0.6561000000000001);
    }
    20% {
        transform: scaleY(0.4096000000000001);
    }
    30% {
        transform: scaleY(0.24009999999999998);
    }
    40% {
        transform: scaleY(0.12959999999999994);
    }
    50% {
        transform: scaleY(0.0625);
    }
    60% {
        transform: scaleY(0.025599999999999956);
    }
    70% {
        transform: scaleY(0.008099999999999996);
    }
    80% {
        transform: scaleY(0.0016000000000000458);
    }
    90% {
        transform: scaleY(0.00009999999999998899);
    }
    100% {
        transform: scaleY(0);
    }
}

@keyframes collapseContentsYAnimation {

    0% {
        transform: scaleY(1);
    }
    10% {
        transform: scaleY(1.5241579027587255);
    }
    20% {
        transform: scaleY(2.4414062499999996);
    }
    30% {
        transform: scaleY(4.164931278633903);
    }
    40% {
        transform: scaleY(7.7160493827160535);
    }
    50% {
        transform: scaleY(16);
    }
    60% {
        transform: scaleY(39.062500000000064);
    }
    70% {
        transform: scaleY(123.45679012345686);
    }
    80% {
        transform: scaleY(624.9999999999822);
    }
    90% {
        transform: scaleY(10000.0000000011);
    }
    100% {
        transform: scaleY(999);
    }
}