
.bf-note {
	background-color: color("grey","lighten-3");
	border-radius: 6px;
	padding: 20px;
	text-align: left;

	label {
		font-weight: 500;
		font-size: 14px;
		color: $off-black;
	}

	.bf-text {
		&.gd {
			color: $success-color;
		}

		&.ok {
			color: $ok-color;
		}

		&.wn {
			color: $warning-color;
		}

		&.bd {
			color: $error-color;
		}

		font-weight: 500;
		font-size: 18px;
	}
}

.flow-subtext {
	font-size: 18px;
    color: $font-light-grey;
}

.flow-sum-card {
	text-align: left;
	overflow: hidden;
    width: 400px;
    max-width: calc(100vw - 20px);
	.sum-card-head {
		padding: 15px;
		font-size: 15px;
		font-weight: 500;
		text-transform: uppercase;
		color: white;
		background-color: $primary-color;
	}

	.sum-card-bod {
		padding-top: 15px;
		.plan-step {
			&.deemphasize {
				opacity: 0.35;
			}
			display: flex;
			align-items: center;
			justify-content: left;
			padding: 0 15px 15px 15px;
			font-size: 18px;
			.icon {
				min-width: 40px;
				text-align: center;
				color: $primary-color;
				font-size: 24px;
			}
			.title-cont {
				padding-left: 15px;
				.title {
				}

				.subtitle {
                    font-size: 14px;
                    color: $font-light-grey;
				}
			}
		}
	}
}

.onb-ol {
    text-align: left;
    font-size: 16px;
    color: $font-light-grey;

    .onb-ol-li {
        display: flex;
        align-items: flex-start;
        &.cntr {
            align-items: center;
        }
        justify-content: left;
        padding: 0 15px 15px 15px;
        font-size: 18px;

        label {
            font-weight: 500;
            font-size: 1em;
            color: $off-black;
        }

        .icon {
            min-width: 30px;
            text-align: center;
            color: $primary-color;
            font-size: 24px;
            &.bad {
                color: $error-color;
            }
        }
        .title-cont {
            padding-left: 15px;
            &.btxt {
                color: $off-black;
            }
            .title {

            }

            .subtitle {

            }
        }
    }
}

.wheel-scroll-cont {
    position: relative;
    text-align: center;

    &.smaller {
        .wheel-scroller {
            width: 75px;
        }
    }
}

.wheel-scroller {
    overflow-y: auto;
    display: inline-block;
    &::-webkit-scrollbar {
        display: none;
    }
    perspective: 100px;
    perspective-origin: center center;
    width: 200px;
}

.select-overlay {
    position: absolute;
    width: 220px;
    height: 40px;
    top: 49%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 20px;
    border: 2px solid $primary-color;
    background-color: $primary-color-transparent;

    .unit-label {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: $font-grey;
        font-size: 15px;
        font-weight: 500;
    }
}

.multi-wheel-cont {
    position: relative;
    text-align: center;

    .wheel-scroll-cont {
        display: inline-block;
    }
    .select-overlay {
        width: 300px;
    }

}

.illustration-cont {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.illustration {
    position: relative;
    .title {
        position: absolute;
        top: 10px;
        left: 0;
        font-size: 18px;
        font-weight: 500;
    }


    .sf-label {
        position: absolute;

        img {
            width: 100px;
            height: auto;
        }

        &.fat-loss {
            bottom: 10px;
            left: 10px;
        }

        &.muscle-gain {
            bottom: 85px;
            right: 15px;
        }

        &.bar-l {
            bottom: 15px;
            left: 25%;
            transform: translateX(-50%);
            font-size: 15px;
            color: #eee;
            text-transform: uppercase;
            max-width: 100px;
            line-height: 1;
        }

        &.bar-r {
            bottom: 100px;
            right: 25%;
            transform: translateX(50%);
            color: white;
            text-transform: uppercase;
            max-width: 100px;
            line-height: 1;
        }

        &.bar-r-top {
            top: 60px;
            right: 25%;
            transform: translateX(50%);
            font-size: 35px;
            font-weight: 700;
            color: $primary-color;
        }
    }

    .rdiets-label {
        position: absolute;
        bottom: 47px;
        right: 10px;
        white-space: nowrap;

        &.bs {
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
        }

        &.wrk-prf {
            transform: translateX(-50%) rotate(-18deg);
            bottom: 48%;
            left: 50%;
        }

        &.wrk-dif {
            transform: translateX(-50%) rotate(-18deg);
            bottom: 28%;
            left: 50%;
        }

        &.wrk-prf2 {
            bottom: 25%;
            left: 58%;
            transform: translateX(-50%);
        }

        &.wrk-dif2 {
            bottom: 51%;
            left: 58%;
            transform: translateX(-50%);
        }

        font-size: 14px;
        color: $font-grey;
    }

    .bot-axis-lbl {
        position: absolute;
        &.ll {
            left: -7px;
        }
        &.rl {
            right: -7px;
        }
        bottom: -8px;
        font-size: 12px;
        color: #aaa;
    }

}

.est-prog-chart-tip {
    position: absolute;
    padding: 5px;
    background: $primary-color;
    text-align: center;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 10px;
    white-space: nowrap;
    text-transform: uppercase;
    transform: translate(-50%,-100%);
    &.outlined {
        border: 2px solid $primary-color;
        background-color: white;
        color: $primary-color;
        &.blue-tip {
            border: 2px solid color("blue","base");
            background-color: white;
            color: color("blue","base");
        }

        &.orange-tip {
            border: 2px solid #ff9800;
            background-color: white;
            color: #ff9800;
        }
    }

    &.blue-tip {
        &:not(.bottom) {
            &::after {
                border-top: 10px solid color("blue","base");
            }
        }
    }

    &.orange-tip {
        &:not(.bottom) {
            &::after {
                border-top: 10px solid #ff9800;
            }
        }
    }
      
    &:not(.bottom) {
        &::after {
            bottom: -10px; /* Adjust this value to move the caret below the box */
            border-left: 10px solid transparent; /* Left side of the arrow */
            border-right: 10px solid transparent; /* Right side of the arrow */
            border-top: 10px solid $primary-color; /* Color and height of the arrow */
            &.blue-tip {
                border-top: 10px solid color("blue","base");
            }
        }
    }

    &.bottom {
        transform: translate(-50%,0);
        &::after {
            top: -10px; /* Adjust this value to move the caret below the box */
            border-left: 10px solid transparent; /* Left side of the arrow */
            border-right: 10px solid transparent; /* Right side of the arrow */
            border-bottom: 10px solid $primary-color; /* Color and height of the arrow */
        }
    }

    &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
    }
}

.meal-plan-table {
    width: 100%;
    border-collapse: collapse;
    .mtitle {
        overflow: hidden;
    }
}
  
.meal-plan-table th, .meal-plan-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}
  
.meal-plan-table th {
    background-color: #f4f4f4;
}
  
.meal-plan-table td.selected {
    background-color: #cce5ff;
    color: #004085;
    font-weight: bold;
}

@media(max-width: 360px) {
    .meal-plan-table .mtitle {
        max-width: 55px;
    }
}

@media(max-width: 350px) {
    .meal-plan-table .mtitle {
        max-width: 45px;
    }
}

@media(max-width: 340px) {
    .meal-plan-table .mtitle {
        max-width: 40px;
    }
}

@media(max-width: 340px) {
    .meal-plan-table .mtitle {
        max-width: 35px;
    }
}

.review-slider {
    .carousel {
        .carousel-item {
            width: 275px;
            height: 350px;
        }
    }
}

.rating-wl-tag {
    position: absolute;
    left: 0;
    top: 5px;
    color: #fff;
    background-color: #4fb067;
    padding: 5px;
    border-radius: 0 6px 6px 0;
    font-size: 10px;
}
  