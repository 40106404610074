/* Text Inputs + Textarea
   ========================================================================== */

/* Style Placeholders */

::placeholder {
  color: $placeholder-text-color;
}

/* Text inputs */

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {

  // General Styles
  background-color: transparent;
  border: none;
  border-bottom: $input-border;
  border-radius: 0;
  outline: none;
  height: $input-height;
  width: 100%;
  min-width: 100%;
  font-size: $input-font-size;
  margin: $input-margin;
  padding: $input-padding;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow .3s, border .3s;

  // Disabled input style
  &:disabled,
  &[readonly="readonly"] {
    color: $input-disabled-color;
    border-bottom: $input-disabled-border;
  }

  // Disabled label style
  &:disabled+label,
  &[readonly="readonly"]+label {
    color: $input-disabled-color;
  }

  // Focused input style
  &:focus:not([readonly]) {
    border-bottom: 1px solid $input-focus-color;
    box-shadow: 0 1px 0 0 $input-focus-color;
  }

  // Focused label style
  &:focus:not([readonly])+label {
    color: $input-focus-color;
  }

  // Hide helper text on data message
  &.valid ~ .helper-text[data-success],
  &:focus.valid ~ .helper-text[data-success],
  &.invalid ~ .helper-text[data-error],
  &:focus.invalid ~ .helper-text[data-error] {
    @extend %hidden-text;
  }

  // Valid Input Style
  &.valid,
  &:focus.valid {
    @extend %valid-input-style;
  }

  // Custom Success Message
  &.valid ~ .helper-text:after,
  &:focus.valid ~ .helper-text:after {
    @extend %custom-success-message;
  }
  &:focus.valid ~ label {
    color: $input-success-color;
  }

  // Invalid Input Style
  &.invalid,
  &:focus.invalid {
    @extend %invalid-input-style;
  }

  // Custom Error message
  &.invalid ~ .helper-text:after,
  &:focus.invalid ~ .helper-text:after {
    @extend %custom-error-message;
  }
  &:focus.invalid ~ label {
    color: $input-error-color;
  }

  // Full width label when using validate for error messages
  &.validate + label {
    width: 100%;
  }

  // Form Message Shared Styles
  & + label:after {
    @extend %input-after-style;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: $input-font-size;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    font-size: $input-font-size;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    font-size: $input-font-size;
  }
  &:-moz-placeholder { /* Firefox 18- */
    font-size: $input-font-size;
  }

  // Large Text Input
  &.medium-text-input {
    font-size: $wo-wt-number;
    font-weight: 400;
    color: $font-grey;
    text-align: center;
  }

  &.large-text-input {
    font-size: 35px;
    font-weight: 300;
    color: $font-grey;
    text-align: center;
  }

  &.small-text-input {
    font-size: size 20px;
    font-weight: 500;
    color: $font-grey;
  }

  &.short-text-input {
    width: 50px;
    text-align: center;

    &.f1 {
      font-size: 25px;
      color: $font-grey;
      font-weight: 300;
    }
  }

  &.in-text-input {
    display: inline;
    min-width: unset;
    width: 50px;
    height: unset;
    text-align: center;
  }

  //Prefix helper 
  &.no-min-width {
    min-width: auto;
  }
}


.small-number-input {
    position: relative;
    display: inline-block;
    width: 50px;
    input[type=number]:not(.browser-default),
    input[type=text]:not(.browser-default) { 
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        height: 2rem;
        &.invalid ~ label {
            color: $input-error-color;
          }
    }

    label {
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
    }
}

/* Validation Sass Placeholders */
%valid-input-style {
  border-bottom: 1px solid $input-success-color;
  box-shadow: 0 1px 0 0 $input-success-color;
}
%invalid-input-style {
  border-bottom: $input-invalid-border;
  box-shadow: 0 1px 0 0 $input-error-color;
}
%hidden-text {
  color: transparent;
  user-select: none;
  pointer-events: none;
}
%custom-success-message {
  content: attr(data-success);
  color: $input-success-color;
}
%custom-error-message {
  content: attr(data-error);
  color: $input-error-color;
}
%input-after-style {
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  transition: .2s opacity ease-out, .2s color ease-out;
}

textarea.invalid {
  border-color: $input-error-color;
}

// Styling for input field wrapper
.input-field {
  // Inline styles
  &.inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;

    input,
    .select-dropdown {
      margin-bottom: 1rem;
    }

    .helper-text {
        margin-top: -1rem;
    }
    &.small-size {
      width: 45px;
      input {
        text-align: center;
      }
    }
 
  }

  // Gutter spacing
  &.col {
    label {
      left: $gutter-width / 2;
    }

    .prefix ~ label,
    .prefix ~ .validate ~ label {
      width: calc(100% - 3rem - #{$gutter-width});
    }
  }

  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > label {
    color: $input-border-color;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    cursor: text;
    transition: transform .2s ease-out, color .2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(12px);
    pointer-events: none;

    &:not(.label-icon).active {
      transform: translateY(-14px) scale(.8);
      transform-origin: 0 0;
    }
	    
  }

  &.with-placeholder {
    & > label {
      transform: translateY(-14px) scale(.8);
      transform-origin: 0 0;
    }
  }

  &.right-label {
    & > label {
      right: 0;
      left: unset;
    }
  }

  // Autofill + date + time inputs
  & > input[type]:-webkit-autofill:not(.browser-default):not([type="search"]) + label,
  & > input[type=date]:not(.browser-default) + label,
  & > input[type=time]:not(.browser-default) + label {
    transform: translateY(-14px) scale(.8);
    transform-origin: 0 0;
  }

  .helper-text {
    &::after {
      opacity: 1;
      position: absolute;
      left: 0;
    }

    position: relative;
    min-height: 18px;
    display: block;
    font-size: 12px;
    color: rgba(0,0,0,.54);
  }

  // Prefix Icons
  .prefix {
    position: absolute;
    width: $input-height;
    font-size: $input-icon-size;
    transition: color .2s;
    top: ($input-height - $input-icon-size) / 2;

    &.active { color: $input-focus-color; }
  }

  &.medium-number, &.small-text {
    width: 100px;
    input {
        text-align: center;
    }
  }

  &.wid-300 {
    width: 300px !important;
    input {
        text-align: center;
    }
  }

  &.smaller-number, &.smaller-text {
    width: 80px;
    input {
        text-align: center;
    }
  }

  &.smaller-number-2, &.smaller-text-2 {
    width: 70px;
    input {
      text-align: center;
    }
  }

  .prefix ~ input,
  .prefix ~ textarea,
  .prefix ~ label,
  .prefix ~ .validate ~ label,
  .prefix ~ .helper-text,
  .prefix ~ .autocomplete-content {
    margin-left: 3rem;
    width: 92%;
    width: calc(100% - 3rem);
  }

  .prefix ~ label { margin-left: 3rem; }

  @media #{$medium-and-down} {
    .prefix ~ input {
      width: 86%;
      width: calc(100% - 3rem);
    }
  }

  @media #{$small-and-down} {
    .prefix ~ input {
      width: 80%;
      width: calc(100% - 3rem);
    }
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  &.compact {
    margin-bottom: 2px;
    margin-top: 2px;
    height: 40px;

    .helper-text {
      display: none;
    }

    input {
      height: unset;
      padding-top: 20px;
      margin-bottom: 2px;
    }
    & > label.active {
      transform: translateY(0) scale(.8);
    }
  }
}


/* Search Field */

.input-field input[type=search] {
  display: block;
  line-height: inherit;
  transition: .3s background-color;

  .nav-wrapper & {
    height: inherit;
    padding-left: 4rem;
    width: calc(100% - 4rem);
    border: 0;
    box-shadow: none;
  }

  &:focus:not(.browser-default) {
    background-color: $input-background;
    border: 0;
    box-shadow: none;
    color: #444;

    & + label i,
    & ~ .mdi-navigation-close,
    & ~ .material-icons {
      color: #444;
    }
  }

  & + .label-icon {
    transform: none;
    left: 1rem;
  }

  & ~ .mdi-navigation-close,
  & ~ .material-icons {
    position: absolute;
    top: 0;
    right: 1rem;
    color: transparent;
    cursor: pointer;
    font-size: $input-icon-size;
    transition: .3s color;
  }
}


/* Textarea */

// Default textarea
textarea {
  width: 100%;
  height: $input-height;
  background-color: transparent;

  &.materialize-textarea {
    line-height: normal;
    overflow-y: hidden; /* prevents scroll bar flash */
    padding: .8rem 0 .8rem 0; /* prevents text jump on Enter keypress */
    resize: none;
    min-height: $input-height;
    box-sizing: border-box;
  }
}

// For textarea autoresize
.hiddendiv {
  visibility: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word; /* future version of deprecated 'word-wrap' */
  padding-top: 1.2rem; /* prevents text jump on Enter keypress */

  // Reduces repaints
  position: absolute;
  top: 0;
  z-index: -1;
}


/* Autocomplete */
.autocomplete-content {
  li {
    .highlight { color: #444; }

    img {
      height: $dropdown-item-height - 10;
      width: $dropdown-item-height - 10;
      margin: 5px 15px;
    }
  }
}

/* Character Counter */
.character-counter {
  min-height: 18px;
}

.modal-search-bar {
    display: flex;

    .input-field, select {
        &.search-field {
            flex-grow: 1;
        }
        &:not(:nth-child(1)) {
            margin-left: 5px;
        }
        margin-top: 1.5em;
        margin-bottom: 0;
    }
}

.clear-text-btn {
  top: 40%;
  right: 10px;
  position: absolute;
  font-size: 1.5em;
}

.modal-form {
    &.centered-form {
        text-align: center;
        .inner-form-container {
            display: inline-block;
            text-align: left;
        }
    }
    .inner-form-container {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.form-section-header {
    margin-top: 35px;
    color: $font-grey;
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    line-height: 1;
    .expandable {
        cursor: pointer;
    }

    .expand-icon {
        font-size: 1.35em;
    }
}

.small-form-header {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 15px;
  text-align: left;
  color: $font-grey;
  .parenthetical {
    font-weight: 300;
  }
}

.react-tagsinput {
    padding-right: 5px; 
}