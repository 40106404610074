@import "./scss/loginButtons.scss";
@import "./scss/materialize.scss";
@import "./scss/additions.scss";
@import "./scss/trainers.scss";
@import "./scss/onboarding.scss";
@import "./scss/animations.scss";
@import "./scss/workout-walkthrough.scss";

@supports (top: env(safe-area-inset-top)) {
    :root {
		--sat: env(safe-area-inset-top);
		--sar: env(safe-area-inset-right);
		--sab: env(safe-area-inset-bottom);
		--sal: env(safe-area-inset-left);
	}
}

@supports not (top: env(safe-area-inset-top)) {
    :root {
		--sat: 0;
		--sar: 0;
		--sab: 0;
		--sal: 0;
	}
}

:root {
	--wih: 100vh;
}

body {
	margin: 0;
	&:not(.allow-overflow) {
		overflow: hidden;
	}
    -webkit-font-smoothing: antialiased; 
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }

.app {
	color: $off-black;
	.logo-img {
		width: auto;
		height: auto;
		max-height: 300px;
		max-width: 375px;
		max-width: calc(min(400px, 95vw));
		margin-bottom: 60px;
	}
	position:absolute;             /* position in the center of the screen */
	left:50%;
	top:50%;
	text-align:center;
	transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	width: 100%;
	overflow: hidden;
	max-width: 400px;
}

.app.loading::after {
	content:'';
	top:0;
	transform:translateX(100%);
	width:100%;
	height:100%;
	position: absolute;
	z-index:1;
	animation: slide 1s infinite 1s;
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(255,255,255,0) 100%);
  }
  @keyframes slide {
	0% {transform:translateX(-100%);}
	100% {transform:translateX(100%);}
  }

.page-container, .conversion-page-container {
    position: absolute;
    width: 100%;
    min-height: 100vh;

	&.white-color {
		background-color: white;
	}
}

.conversion-page-container {
    z-index: -2;
}

a {
	text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-enter {
	opacity: 0;
	z-index: 1;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
}

.fade-exit {
	opacity: 1;
	z-index: 1;
}

.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 300ms ease-in;
}

.fade-exit-done {
	opacity: 0;
}

.list-slide-exit {
	will-change: transform;
	transform: translateX(0);
}

.list-slide-exit.list-slide-exit-active {
	transform: translateX(100%);
	transition: transform 180ms ease-out;
}

.slide-left {
	.list-slide-exit.list-slide-exit-active {
		transform: translateX(-100%);
		transition: transform 180ms ease-out;
	}
}

.slide-up-enter {
	transform: translateY(100vh);
	box-shadow: -10px -10px 20px rgba(0,0,0,0.5);
	z-index: 999;
}

.slide-up-enter.slide-up-enter-active {
	transform: translateY(0);
	transition: transform 300ms ease-in;
}

.slide-up-exit {
	transform: translateY(0);
	box-shadow: -10px -10px 20px rgba(0,0,0,0.5);
	z-index: 999;
}

.slide-up-exit.slide-up-exit-active {
	transform: translateY(100vh);
	transition: transform 300ms ease-in;
}

.fade-back-exit {
	transform: scaleX(1) scaleY(1) translateX(0);
	opacity: 1;
	z-index: 1;
	overflow: hidden;
	max-height: 100vh;
}

.fade-back-exit.fade-back-exit-active {
	transform: scaleX(0.8) scaleY(0.8) translateX(-35vh) translateZ(0);
	opacity: 0.5;
	transition: opacity 300ms ease-out, transform 300ms ease-out;
}

.fade-back-enter {
	transform: scaleX(0.8) scaleY(0.8) translateX(-35vh);
	opacity: 0.5;
	z-index: 1;
	overflow-x: hidden;
	max-height: 100vh;
}

.fade-back-enter.fade-back-enter-active {
	transform: scaleX(1) scaleY(1) translateX(0) translateZ(0);
	opacity: 1;
	transition: opacity 300ms ease-out, transform 300ms ease-out;
}


.slide-over-enter {
	transform: translateX(100vw);
	position: fixed;
	z-index: 999;
	overflow: hidden;
	max-height: 100vh;
}

.slide-over-enter.slide-over-enter-active {
	transform: translateX(0) translateZ(0);
	transition: transform 300ms ease-out;
}

.slide-over-exit {
	transform: translateX(0);
	position: fixed;
	z-index: 999;
	overflow: hidden;
	max-height: 100vh;
}

.slide-over-exit.slide-over-exit-active {
	transform: translateX(100vw) translateZ(0);
	transition: transform 300ms ease-out;
}

.flow-form-in-enter {
	transform: translateX(100vw);
	position: absolute;
	width: 100vw;
}

.flow-form-in-enter.flow-form-in-enter-active {
	transform: translateX(0) translateZ(0);
	transition: transform 300ms ease-out;
}

.flow-form-in-exit {
	transform: translateX(0);
	position: absolute;
	width: 100vw;
}

.flow-form-in-exit.flow-form-in-exit-active {
	transform: translateX(-100vw) translateZ(0);
	transition: transform 300ms ease-out;
}

.flow-form-out-enter {
	transform: translateX(-100vw);
	position: absolute;
	width: 100vw;
}

.flow-form-out-enter.flow-form-out-enter-active {
	transform: translateX(0) translateZ(0);
	transition: transform 300ms ease-out;
}

.flow-form-out-exit {
	transform: translateX(0);
	position: absolute;
	width: 100vw;
}

.flow-form-out-exit.flow-form-out-exit-active {
	transform: translateX(100vw) translateZ(0);
	transition: transform 300ms ease-out;
}

.flow-form-in-enter, .flow-form-in-exit, .flow-form-out-enter, .flow-form-out-exit {
	.ff-next-btn {
		display: none;
	}
}


.mb20 {
	margin-bottom: 20px;
}

.mt20 {
	margin-top: 20px;
}

.hidden {
	display: none !important;
}
  
#connecting {
  color: #709621;
}

.current-state {
	padding-right: 15px;
	padding-left: 15px;
	margin-bottom: 8px;
}

// For signup1.html

.green-gradient-bcg {
	background: linear-gradient(#AEEA00,#00C853);
	background-attachment: fixed;
}

.green-gradient-bcg-2 {
	background: rgb(134,209,128);
	background: linear-gradient(145deg, rgba(134,209,128,1) 0%, rgba(79,181,103,1) 100%);
}

.page-height-cont {
	min-height: 90vh;
}

.choose-goal {
	display: flex;
	min-height: 80vh;
	align-items: center;
	justify-content: center;
	margin-top: calc(50px + var(--sat));
	margin-bottom: -#{$bottom-scroll-padding};
	margin-left: 0;
	margin-right: 0;
    overflow-x: hidden;
	
	&.display-block {
		display: block;
	}

	&.full-screen-support {
		min-height: 100vh;
		margin-top: 0;
	}
}

.fixed-next-flow {
	.choose-goal {
		margin-bottom: 0;
	}
}

.centered-container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
	margin-bottom: -50px;

	.center-child {
		margin-right: 15px;
		margin-left: 15px;
	}
}

.choose-goal-heading {
	color: $header-color;
	font-weight: 300;
	padding-top: 15px;

	&.fixed-size {
		font-size: 1.79554rem;
        line-height: 110%;
        margin: 1.19703rem 0 0.71822rem 0;
	}
}

.conversion-heading {
	color: $header-color;
	font-weight: 500;
	margin-left: 20px;
	margin-top: 5px;
	&.spacing-2 {
		margin-top: 10px;
		margin-bottom: 5px;
		margin-left: 23px;
		@media #{$medium-and-up} {
			margin-left: 15px;
		}
	}

	&.lighter {
		font-weight: 400;
	}
}

.header-note {
	flex-grow: 1;
	font-size: 13px;
	text-align: right;
	color: $font-grey;
	line-height: 13px;
	text-decoration: underline;
}

.basic-heading {
	color: $header-color;
	font-weight: 500;
	font-size: 16px;
}

.basic-heading-sub {
	color: $header-light;
    font-weight: 300;
    font-size: 12px;
}

@media screen and (max-width: 630px) {  
	.conversion-heading-split {
  max-width: 165px;
  } 
}

.signup-question-container {
	text-align: center;
	max-width: 400px;
	margin: 20px 8px 90px 8px;
    &.fixed-background {
        position: absolute;
        z-index: 1;
    }
	&.larger {
		max-width: 600px;
	}
}

.horizontal-scrolling-wrapper {
	display: flex;
	margin-right: 5px;
	flex-wrap: nowrap;
	overflow-x: auto;
	z-index: -1;
	-webkit-overflow-scrolling: touch; //smooth, no-momentum scrolling for iOS

	.card {
		flex: 0 0 auto;
	}
}

@media #{$small-and-down} {
	.responsive-scrolling-wrapper {
		display: flex;
		margin-right: 5px;
		flex-wrap: nowrap;
		overflow-x: auto;
		z-index: -1;
		-webkit-overflow-scrolling: touch; //smooth, no-momentum scrolling for iOS
	}

	.responsive-scrolling-wrapper::-webkit-scrollbar {
		display: none;
	}
}

.white-font {
	color: white;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

// Sign Up Page Image Placements
.overflow-hidden {
	overflow: hidden;
}

.garnish-container {
	overflow: hidden;
	position: absolute;
	width: 100vw;
	height: 250px;
	top: 0;
}

.spinach-1 {
	height: 70px;
	position: fixed;
	top: -5px;
	right: 90px;
	transform: rotate(90deg);
	opacity: .8;
}

.spinach-2 {
	height: 120px;
	position: fixed;
	right: -20px;
	top: -25px;
	transform: rotate(65deg);
	opacity: .8;
}

.spinach-2a {
	height: 120px;
	transform: rotate(65deg);
	opacity: .8;
	position: absolute;
}

.spinach-3 {
	height: 100px;
	position: fixed;
	top: 100px;
	right: 0px;
	opacity: .8;
}

.spinach-1, .spinach-2, .spinach-3 {
	&.conversion {
		position: absolute;
	}
}

.barbell {
	position: fixed;
	bottom: -190px;
	left: -590px;
	transform: rotate(-30deg);
	opacity: .8;
    height: 270px;
	height: auto\9; /* ie8 */
	
	&.conversion {
		top: 40px;
        position: absolute;
        z-index: -1;
	}
}

.branding-phone {
	position: absolute;
	bottom: -100px;
	left: 100px;
	transform: rotate(-30deg);
    height: 400px;
}

@media screen and (max-width: 350px) {  
  	.small-screen-disappear {
    display: none !important;
    } 
}

@media screen and (max-width: 325px) {  
	.extra-small-screen-disappear {
  display: none;
  } 
}

@media screen and (min-width: 601px) {  
	.wide-width-disappear {
  display: none;
  } 
}

@media #{$large-and-up} {
	.large-screen-disappear {
		display: none;
	}
}

.clickable {
    cursor: pointer;
}

.low-opacity-icon {
    color: $icon-low-opacity;
}

.inline-block {
    display: inline-block;
}

.primary-bar {
	position: relative;
	background-color: $primary-color;
	height: 150px;
	width: 100%;
}

.centering {
	position: absolute;
	top: 50%;
	width: 100%; 
	transform: translateY(-50%);
}

.buttonize-no-color {
		cursor: pointer;
}

@media #{$small-and-down} {
	.card-content-psudo-routine {
	  height: 40px;
	}

	&.double {
		height: 55px;
	}
  }

@media #{$medium-and-up} {
.card-content-psudo-routine {
	height: 136px;
}
}

//Conversion Page Elements 
.secondary-background {
	background: color("grey","lighten-4");
	height: auto;
	padding: 7px;
}

.typography-conversion {
	margin: 0px 5px 0px 5px;
	font-size: 14px;

	&.title-text {
		font-size: 17px;
		margin-top: 2px;
		color: $header-color;
		font-weight: 700;
	}
	
	&.subtitle-paragraph {
		margin-top: 0px;
		font-size: 12px;
	}

	&.ratings-title {
		font-size: 15px;
		font-weight: 500;
		color: $header-color;
	}

	&.ratings-text {
		font-size: 12px;
		font-weight: 300;
		color: $font-grey;
	}
}

.stars {
	color: color("yellow","golden");
	font-size: 20px;
}

//Today Page Elements

.today-page-no-split {
	display: none;
}

@media screen and (min-width: 630px) {  
	.today-page-split{
	  display: none;
  } 

  .today-page-no-split {
	  display: inline-block;
  }
}

.overview-background {
	width: 100%;
	overflow: visible;
	height: 135px;
	background: $primary-color;
	margin-top: $bottom-nav-offset-top;

	&.active-workout {
		min-height: 70px;
		height: auto;
		margin-top: 0;
		padding-bottom: 5px;
	}
}

.calorie-number-overview {
	color: white;
	font-size: 30px;
	//text-shadow: 0px 1px 5px rgba(0,0,0,0.5);
	font-weight: 400;
}

.macro-number-overview {
	color: white;
	font-size: 25px;
	//text-shadow: 0px 1px 5px rgba(0,0,0,0.5);
	font-weight: 400;
	@media #{$medium-and-down} {
		font-size: 20px;
	}
}

.calorie-spacing {
	&.macros {
		margin-left: 9px;
		margin-right: 9px;
		display: inline-block;
		@media #{$extra-small-and-down} {
			margin-left: 4px;
			margin-right: 4px;
		}
	}
}

.macros-bg {
	display: inline-block;
	min-width: 60px;
	border-radius: 9px;
	padding-left: 7px;
	padding-right: 7px;
	color: white;
	font-size: 12px;
	font-weight: 200;
	line-height: 17px;
}

.recipe-ingredients-container {
	margin: 10px 10px 0 20px
}

.recipe-ingredient.header {
	background-color: $primary-super-light;
	padding: 3px;
	border-radius: 3px;
	color: $font-grey;
	.basic-heading {
		font-weight: 300;
	}
}

.grocery-group:not(:last-of-type) {
	margin-bottom: 20px;
}

.grocery-item {
	position: relative;
	.grocery-check {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-20%);
	}
}

.meal-plan-column-cont {
	margin-left: auto;
	margin-right: auto;
	max-width: 600px;
}

.divider-line {
	width: 80%;
	height: 1px;
	background-color: white;
	opacity: .5;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 4px;
	&.mp {
		background-color: color("grey","lighten-2");
		margin-left: 10px;
		height: 2px;
		width: 90%;
		@media #{$small-and-down} {
			width: 95%;
			margin-left: auto;
		}
		&.dark {
			background-color: color("grey","darken-1");
		}
	}
	&.me {
		background-color: color("grey","lighten-1");
		max-width: 420px;
	}
	&.vertical {
		background-color: color("grey","lighten-1");
		height: 70%;
		width: 1px;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 25px;
		margin-right: 25px;
	}
	&.rp {
		background-color: color("grey","lighten-2");
		width: 92%;
	}
	&.full {
		width: 100%;
	}
	
}

.today-page-medal {
	margin: 3px;
	font-size: 30px;
	opacity: 0.5;

	&.active {
		opacity: 1;
		color: color("yellow", "darken-1");
	}

	&.rest {
		font-size: 25px;
	}
}

.today-workout-title {
	font-family: $font-alt;
	color: white;
	margin-top: 3px;
	line-height: 1;
	font-size: 20px;
	max-width: 225px;
	min-width: 225px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.food-log-container{
	min-height: 80px;
	height: auto;
	background: color("light-green","lighten-4");
	border-radius: 14px;
	margin-left: 3%;
	margin-right: 3%;
	padding-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 50px;
	z-index: -1;
}

.food-log-totals {
	height: 30px;
	&.with-goals {
		height: 90px;
	}
	width: 100%;
	border-radius: 5px 5px 0 0;
	background-color: rgba(220,237,197,0.33);
	position: absolute;
	bottom: 0;
}

.even-spaced-table {
	display: table;
	table-layout: fixed;
	width: 100%;
	text-align: left;
	box-sizing: border-box;
}

.even-spaced-cell {
	display: table-cell;
	text-align: center;
	width: 1%;
	vertical-align: middle;
	padding: 0px;

	&.left {
		text-align: left;
	}

	&.right {
		text-align: right;
	}
}

.today-meal-container {
	color: $header-color;
	font-weight: 500;
	font-size: 15px;
	text-align: left;
}

.overview-card {
	background-color: $primary-color;
	padding: 10px;
	overflow: hidden;
	position: relative;
	border-radius: 14px;
	// margin: 7px 10px 10px 5px;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

	&.has-overlay {
		@include blur-overlay-cont;
	}

	//, svg:not(.svg-inline--fa)
	img {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 100%;
		-webkit-transform: translate(-50%,-50%);
			-ms-transform: translate(-50%,-50%);
				transform: translate(-50%,-50%);
	}

	.content-wrapper {
		text-align: center;
	}

	.today-workout-container {
		display: inline-block;
		max-width: 100%;
		text-align: left;
		padding-left: 8px;
		padding-right: 8px;
	}

	.today-circle-container {
		position: relative;
		margin: auto;
		height: 125px;
	}

	.circular-chart {
		display: block;
		margin: 10px auto 10px auto;
		width: 115px;
		height: 115px;
	}

	&:not(.square) {
		@media #{$large-and-up} {
			.content-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.today-workout-container {
				margin-left: 30px;
			}

			.today-circle-container {
				top: 14px;
				margin: 0px 30px 0px 0px;
				height: 140px;
			}

			.circular-chart {
				margin: auto;
				min-width: 115px;
			}

			.calorie-goal {
				top: -40px;
			}
		}
	}
}

.today-circle, .error-circle {
  fill: none;
  stroke-width: 1.5;
  stroke-linecap: round;
}

.error-circle {
	stroke-width: 1.6;
}

.macro-circle-chart {
	width: 68px;
	height: 68px;
	min-width: 68px;
	position: absolute;
	transform: translate(-2px,-20%);
	left: 0;
	path {
		fill: none;
		stroke-width: 2;
	}
}

.circle-bg {
fill: none;
stroke: rgba(255,255,255,.44);
stroke-width: 1.5;
}

.circle-text {
	fill: white;
	font-family: sans-serif;
	font-size: 0.6em;
	text-anchor: middle;
	
	&.ct-subtitle {
		font-size: 0.25em;
		font-weight: 300;
	}

	&.red-fill {
		fill: color("red","base");
	}
}

.pct-circle-text {
	fill: $font-grey;
	font-family: sans-serif;
	font-size: 0.75em;
	text-anchor: middle;
}

.pct-circle-title {
	text-transform: uppercase;
	font-size: 20px;
	color: $off-black;
}

.circular-chart.cc-white .today-circle {
	stroke: white;
}

.circular-chart.cc-white .error-circle {
	stroke: color("red","base");
}

.calorie-goal {
	font-size: 12px;
	color: white;
	text-align: center;
	top: -48px;
	position: relative;

	i, svg {
		color: rgba(255,255,255,.4);
		&.active {
			color: color("amber","base");
		}
	}
}

.today-macro-container {
	background-color: rgba(255,255,255,.44);
	margin: 0px auto 0px auto;
	width: fit-content;
	border-radius: 9px;
	padding: 5px 0 7px 0;
	@media #{$large-and-up} {

	}
}

//Today Page- Meal Cards

.card-tab {
	border-color: color("grey","lighten-3");
	background-color: white;
	border-style: solid;
	border-width: 1px 1px 0 1px;
	border-radius: 9px 9px 0 0;
	font-weight: 300;
	color: $font-grey;
	font-size: 15px;
	padding: 6px 8px;
	white-space: nowrap;
	display: inline;
	
	&.recipe-card {
		position: absolute;
		top: -35px;
		right: 10px;
	}
}

.image-center-crop {
	float: left;
	min-width: 120px;
	width: 120px;
	height: 120px;
	overflow: hidden;
	position: relative;
	border-radius: 14px;
	margin: 7px 10px 10px 5px;

	&.large {
		width: 100%;
		height: 350px;
		border-radius: 0%;
		margin: 0px;
	}

	&.workout-head {
		top: 0;
		&.with-simple-nav {
			top: $simple-nav-height-safe;
		}
	}

	&.rounded-corners {
		border-radius: 5px;
	}

	img, svg:not(.svg-inline--fa) {
		position: absolute;
		left: 50%;
		top: 50%;
		height: 100%;
		-webkit-transform: translate(-50%,-50%);
			-ms-transform: translate(-50%,-50%);
				transform: translate(-50%,-50%);
	}
}

.recipe-like-icons-placeholder {
    width: 72px;
}

.ingredient-info-placeholder {
    width: 38px;
}

.recipe-like-btns-container {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    .icon-btn-container {
        display: inline-block;
    }
}

@media(max-width: 400px) {
    .recipe-like-icons-placeholder, .ingredient-info-placeholder {
        width: 0;
    }

    .recipe-like-btns-container {
        top: unset;
        bottom: 0;
    }
}

.icon-btn-container {
    padding: 9px;
    cursor: pointer;
    color: $font-grey;
    font-size: 18px;
}

.today-recipe-details {
	padding-top: 5px;
	margin-right: 55px;
	clear: both;
}

.today-recipe-title {
	color: $header-color;
	font-weight: 300;
	font-size: 15px;
	border-bottom: 1px solid color("grey","lighten-2");
}

.today-recipe-macros {
	color: color("grey","mid-point");
	font-size: 12px;
	font-weight: 300;
}

.today-total-macros {
	color: color("grey","mid-point");
	font-size: 10px;
	font-weight: 300;
	overflow: hidden;
}

.slight-emphasis-bcg {
	background-color: color("grey","lighten-3");
	border-radius: 6px;
}

.meal-card-bottom {
	display: flex;
	padding-top: 4px;
	clear: both;
	border-top: 1px solid color("grey","lighten-2");
	min-height: 48px;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
}

.slight-top-border {
	border-top: 1px solid #ddd;
}

.prep-buttons {
	width: auto;
	white-space: nowrap;
}

.total-info {
	width: auto;
	flex-grow: 1;
}

.recipe-divider {
	width: 95%;
	border-bottom: 1px solid color("grey","lighten-2");
	margin-top: 2px;
	margin-bottom: 2px;
	margin-left: auto;
	margin-right: auto;
}

//Weight Log
.weight-log-input {
	width: 70px;
	margin-top: 24px;
	margin-right: 15px;
}

// Today Page- Workout Section
.today-time {
	position: absolute;
	color: white;
	right: 9px;
	top: 5px;

	&.bottom-left {
		left: 10px;
		bottom: 5px;
		top: auto;
		right: auto;
	}
}

.today-workout {
	position: absolute;
	color: white;
	max-width: 70%;
	left: 4%;
	bottom: 10%;

	.today-workout-title-card {
		font-family: $font-alt;
		font-weight: 700;
	}

	.today-workout-subtitle {
		font-family: $font-alt;
		font-weight: 300;
		font-size: 18px;
	}
}

@media #{$medium-and-up} {
	.today-workout-title-card {
	  font-size: 36px;
	  line-height: 1;
	}
  }
@media #{$small-and-down} {
    .today-workout-title-card {
        font-size: calc(28px + 2vw);
        line-height: 1;
    }
}

//Workout Tab Overview

.workout-page-image-container {
	max-width: 523px;
	display: flex;
	flex-direction: column;
	margin-right: auto;
	margin-left: auto;
}

.wt-play-btn {
    color: white;
    opacity: 0.88;
    font-size: 60px;
    position: absolute;
    right: 18px;
    bottom: 10px;
}

.deload-btns {
	white-space: nowrap;
    margin-right: auto;
    margin-top: 6px;
    margin-left: 5px;

    &.db-left {
        text-align: right;
        margin-right: 8px;
        padding-bottom: 8px;
    }
}

.wt-routine-title {
	font-family: $font-alt;
	font-weight: 500;
	font-size: 23px;
	color: $header-color;
	margin: 6px auto 3px 10px;
}

.wt-days {
    float: left;
    position: relative;
    font-size: 16px;
    margin-top: 10px;
    margin-left: 8px;
    background-color: $primary-color;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: white;
    text-align: center;
    padding-top: 12px;

	&.rest {
		background-color: white;
		border: 1px solid color("grey","lighten-1");
		color: color("grey","base");
		font-weight: 300;
	}
}

.wt-workout-title {
position: absolute;
vertical-align: middle;
margin-left: 70px;
line-height: 70px;
height: 100%;
font-weight: 300;
font-size: 18px;

	&.workout {
		font-weight: 500;
}
}

.medal-color {
    color: color("amber","lighten-2");
}

.wt-medal {
    float: right;
    position: relative;
    font-size: 28px;
    text-align: center;
    margin-right: 15px;
    margin-top: 22px;

    &.active {
        color: color("amber","lighten-2");
    }

    &.inactive {
        color: color("grey","base");
        opacity: 0.7;
    }
}

.max-list {
	max-width: $max-width-list;
	
	&.ml-center {
	margin-left: auto;
	margin-right: auto;
	}

	@media #{$large-and-up} {
		margin-top: 30px;
		}
	
	&.ml-wide {
	@media #{$medium-and-up} {
		max-width: 90%;
		}
	}
	&.ml-wide-2 {
		max-width: 590px;
	}
}

@media #{$medium-only} {
	.m-center-column {
		max-width: $max-width-list;
		margin-left: auto;
		margin-right: auto;
	}
}

@media #{$large-and-up} {
	.l-center-column {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media #{$medium-only} {
	.m-center-column-2 {
		display: inline-block;
		text-align: left;

	}
}



.wt-routine {
	text-align: center;
	color: $header-color;
	font-weight: 300;
	font-size: 23px;
	margin: 0px auto 10px 10px;
	line-height: 23px;
	padding-bottom: 5px;
	padding-top: 8px;
}

.wt-dividers {
	width: 95%;
	margin-left: auto;
	margin-right: auto;
}

.wt-total-days {
	float: left;
	color: $font-grey;
	font-size: 30px;
	font-weight: 300;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 50%;
	text-align: center;

	.days-subtitle {
		font-size: 14px;
	}
}

.wt-min {
	float: right;
	color: $font-grey;
	font-size: 30px;
	font-weight: 300;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 50%;
	text-align: center;

	.min-subtitle {
		font-size: 14px;
	}
}

.wt-details {
	clear: both;
	color: $font-grey;
	font-weight: 300;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}

//Workout Section

.topper-title {
	font-weight: 400;
	color: $header-color;
	font-size: 18px;
	text-align: center;
	position: relative;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 50px;
    margin-right: 50px;
}

.woo-title-container {
	//color: white;
	font-weight: 500;
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	width: fit-content;
	line-height: 1;
	max-width: 90%;
	text-align: center;

	&.wod {
		padding-bottom: 30px;
		margin-top: 25px;
		text-transform: uppercase;
	}
}

.woo-title {
	font-weight: 500;
	font-size: 27px;
}

.woo-subtitle {
	font-family: $font-alt;
	font-size: 17px;
}
.superset {
	width: fit-content;
	margin-left: auto;
	border-radius: 10px;
	background-color: color("grey","lighten-4");
	padding: 0px 7px 2px 7px;
	color: $font-grey;
	font-weight: 300;
	margin-right: 10px;
	height: fit-content;
	margin-top: -1px;
  }

.woo-exercise-list {
	display: flex;
	position: relative;
	background-color: white;
	padding-bottom: 10px;
	text-align: left;

	&.swap {
		padding-bottom: 5px;
        border-radius: 5px;


		.img-container {
			width: auto;
			height: $wooel-height;
			position: relative;
			float: left;
            border-radius: 5px;	
            img {
                height: 100%;
                width: auto;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;	
            }
            svg {
                border-radius: 5px;	
            }
        }
        
        &:last-of-type {
            padding-bottom: 0;
        }
    }
    
    .title-container {
        @include title-container
    }

	.wooel-exercise, .exercise-subtitle {
		color: $font-grey;
		font-weight: 300;
		font-size: 12px;
        line-height: 13px;
		
	}

	.swap-exercise, .exercise-title {
		@include listing-title
	}

	img {
		width: auto;
		height: $wooel-height;
		position: relative;
		border-radius: 5px;	
	}

	.wooel-elipsis {
		color: $font-grey;
		width: 48px;
		float: right;
		font-size: 24px;
		height:48px;
		padding: 10px;
		margin-right: 3px;
		text-align: center;
		margin-top: auto;
		margin-bottom: auto;
	}

}

.wod-title {
	font-size: 20px;
	color: $header-color;
	font-weight: 300;
	max-width: $max-width-list;
	margin-left: auto;
	margin-right: auto;
    padding: 8px 8px 5px 13px;
    text-align: center;

	@media #{$large-and-up} {
		padding: 8px 0px 15px 0px;
	}

	&.for-log-view {
		padding: 0;
		text-align: left;
		margin-bottom: -15px;
	}

	.wod-subtitle {
		font-size: 13px;
	}
}

.wod-bkgd {
	color: white;
	background: $primary-color;
	background: linear-gradient(135deg, rgba(70,162,123,1) 0%, $primary-color 100%);
}

 .wooel-divider {
	width: 100%;
	border-bottom: 1px solid color("grey","lighten-2");
	clear: both;
 }

.transparent-bottom {
	position: fixed;
    background-color: rgba(255,255,255,0.75);
    @extend .elevation-4-dp;
	height: 60px;
	width: 100%;
	bottom: 0;
	z-index: 100;

	&.wod-btns {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
	}
}

body.keyboard-showing {
	.bottom-center-action-container {
		display: none;
	}

	.transparent-bottom {
		display: none;
	}
}

.wod-play-btn {
	padding-left: 4px;
}

// Exercise Log

.volume-btn {
	color: $icon-low-opacity;
    cursor: pointer;
    padding: 8px 10px 10px 16px;
    font-size: 2em;
    position: fixed;
    top: 0;
    right: 0;
	z-index: 100;

	&.vb-white {
		color: white;
	}
}

.wol-title {
	color: white;
	background-color: color("green","darken-1");
	width: 100%;
	height: auto;
	font-weight: 500;
	font-size: 28px;
	line-height: 1;
	padding: 10px 15px 10px 15px;
	margin-bottom: 10px;
	text-transform: uppercase;

	@media #{$medium-and-up} {
		text-align: center;
		}
	
	.wol-subtitle {
		font-family: $font-alt;
		font-weight: 300;
		font-size: 18px;
		text-transform: capitalize;
	}
}

.wol-exercise-container {
	line-height: 1;
	
	&.inactive {
		background-color: color("grey", "lighten-3" );
	  }
}

.wol-exercise-title {
	color: $header-color;
	font-size: 18px;
	margin: 10px;
	&.grow {
		flex-grow: 1;
	}
}

.wol-right-icon {
	font-size: 20px;
	padding: 10px;
	width: 40px;
	height: 40px;
	margin-right: 10px;
	margin-left: auto;

	&.big {
		font-size: 30px;
		padding: 0;
	}
}

.wol-checkbox {
	text-align: center;
	width: 40px;
	height: 40px;
	line-height: 40px;
}

.wol-divider-line {
	border-right: 1px solid color("grey","lighten-2");
}

.input-bold {
	font-size: 18px;
	font-weight: 900;
	color: black;
}

.respo {
	
	&.mart40 {
		margin-top: 40px;

		@media #{$short-screen-only} {
			margin-top: 20px;
		}
	}
}

.spreadsheet-form {
	display: table;
	border-collapse: collapse;
	width: 100%;
	font-weight: 300;

	.spreadsheet-head {
		display: table-row;

		.spreadsheet-head-cell {
			display: table-cell;
			text-align: center;
            padding: 0px 5px;
            &:not(:last-of-type) {
                border-right: 1px solid #DDDDDD;
            }
        }
        border-bottom: 1px solid #DDDDDD;
	}

	.spreadsheet-row {
		display: table-row;
		
		&.logged {
			background-color: color("grey", "lighten-3");
		}

		.spreadsheet-cell {
			height: 40px;
			display: table-cell;
			text-align: center;
			vertical-align: middle;
            white-space: nowrap;

			&.icon-cell {
				font-size: 16px;
				cursor: pointer;
				width: 40px;
			}

			&.no-padding {
				padding: 0px;
            }
            
            &:not(:last-of-type) {
                border-right: 1px solid #DDDDDD;
            }

            .plus-minus-toggle {
                min-width: 100px;
            }
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid #DDDDDD;
        }
	}
}

.input-cell {
	width: 100%;
	height: 100%;
	border-radius: 0;
	text-align: center;
    box-shadow: none;
	background: none;
	border: 0px;
    &.error {
        border: 1px solid red;
        color: red;
    }

    &:focus {
        border: 1px solid $primary-color;
        background-color: #ddd;
	}
	
}

select.input-cell {
	width: auto;
	max-width: 100%;
}

.input-text {
	font-size: 1.25em;
	font-weight: 900;
	color: black;
	border: 0px;
}

// Workout Walkthrough 

.wo-wt-video-wrapper {
	height: 100%;
	width: 100%;
	display: inline-block;
	position: relative;

	&.rest {
		margin-top: 0px;
	}

	@media #{$long-screen-only} {
		margin-top: 30px;
		}

}

.superset-label {	
	background-color: color("green","darken-1");	
	color: white;	
	padding: 2px 4px;	
	width: fit-content;	
	border-radius: 2px;	
}


.opt-rest {
	margin-top: 5x !important;
	margin-bottom: 5px !important;
}

.wo-wt-video {
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	display: inline-block;
	position: relative;
	cursor: pointer;

	video {
		max-width: 100%;
		max-height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

}

.wo-wt-details-wrapper {
	margin-left: auto;
	margin-right: 4px;
	width: 100%;


	@media #{$medium-and-up} {
		max-width: 480px;
		margin-right: auto;
	}
}

.inline-icon-btn {
	font-size: 20px;
	padding: 10px 10px 10px 10px;
	margin-right: 4px;
    color: $font-grey;
    display: inline-block;
    cursor: pointer;
    line-height: 0;
    z-index: 9;
}

.placeholder-svg {
	max-width: 100%;
	max-height: 100%;
	background-color: black;
}

.width-placeholder-svg {
    height: 100%;
    width: auto;
}

.wo-wt-ex-title {
	color: $header-color;
	font-size: 23px;
	margin-left: 7px;
	margin-right: 7px;
	
	&.rest-title {
		margin-top: 20px;
		margin-bottom: 0px;
		
		@media #{$long-screen-only} {
			margin-top: 80px;
		  }
	}

	&.rest-title-2 {
		margin-top: 0px;
		margin-bottom: 0px;
		
		@media #{$long-screen-only} {
			margin-top: 30px;
		  }
	}

	&.rest {
		margin-bottom: 0px;
		font-size: 20px;
	}

	@media #{$extra-small-and-down} {
		margin-bottom: 0px;}

	&.color-white {
		color: white;
	}
}

.wo-wt-subtitle {
	color: $header-light;
	font-size: 18px;
	font-family: $font-alt;
	font-weight: 300;

	@media #{$extra-small-and-down} {
		margin-bottom: 10px;}
}

.wo-wt-input-container {
	padding: 0px 20px;
	flex-grow: 1;
	max-width: 200px;
}


.wo-wt-rest-opt-text {
	padding-top: 5px;
	font-weight: 300;
	margin-left: auto;
	margin-right: auto;
}

.wo-wt-ex-num {
	color: $header-color;
	font-size: $wo-wt-number;
	font-weight: 400;
	margin-left: 3px;
	margin-right: 3px;
	white-space: nowrap;
	
	&.inactive {
		font-weight: 300;
	}

	&.input-btn {
		width: 40px;
		height: 40px;
		line-height: 40px;
	}
}

.inline-tooltip-container {
    position: relative;
    display: inline-block;
}

.inline-tooltip {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    transform: translateX(40px) translateY(-20px);
}

.wo-wt-ex-subtitle {
	color: $font-grey;
	font-size: 13px;
    font-weight: 300;

	&.rest {
		padding-bottom: 8px;
	}

	&.icon-centering {
		padding-left: 20px;
	}

}


.compact-rating-btns {
	.wo-wt-test-btns {
		height: 40px;
		width: 40px;
		margin: 3px;
	}

	max-width: 250px;

	.prompt {
		color: $font-grey;
		max-width: 250px;
		line-height: 1.1;
		margin-top: 10px;
	}
}

.wo-wt-test-btns {
	height: 50px;
	width: 50px;
	border-radius: 5px;	
	margin: 7px;
	position: relative;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	
	&.color-1 {
		background-color: #9ccc65;
	}
	&.color-2 {
		background-color: #b8cb54;
	}
	&.color-3 {
		background-color: #ffca28;
	}
	&.color-4 {
		background-color: #ff9d36;
	}
	&.color-5 {
		background-color: #ff7043;
	}
	&.active {
		background-color: white;
		svg {
			@include abs-centered;
		}
	}

	&.non-interactive {
		width: 20px;
		height: 20px;
		margin: 2px;
		display: inline-block;
		vertical-align: middle;
		box-shadow: none;
		margin-left: 5px;
	}
}

.wo-wt-test-subtitle {
	max-width: 300px;
	font-size: 13px;
	color: $font-grey;
	font-weight: 300;
	margin-left: auto;
	margin-right: auto;
}

.wo-wt-test-subtitle-2 {
	right: 0;
	margin-left: auto;
}

.wo-wt-notes {
	max-width: 480px;
	width: 90%;
	border-radius: 6px;
	margin-left: auto;
	margin-right: auto;
	background-color: color("grey","lighten-3");
	padding: 8px;
    color: $font-grey;
	text-align: center;
	margin-top: 15px;
	line-height: 1.3;
	.regular {
		color: #333;
	}
}

@media(min-width: $set-background-width) {
    .wo-wt-notes {
        background-color: white;
    }
}

.wo-wt-divider {
	width: 100%;
	height: 25px;

	@media #{$extra-small-and-down} {
		height: 10px;
	}

	&.calculate {
		height: 25px !important;
	}
}

.wo-wt-playbtn {
	margin-left: 3px;

	&.small-btn {
		font-size: 18px !important;
	}

	&.pause {
		font-size: 16px !important;
		margin-left: 0px;
	}
}

.wo-wt-continue-btn {
	margin-top: 4px;
	margin-bottom: 13px;

	@media #{$long-screen-only} {
		margin-top: 50px;
	}
}

.wo-wt-warmup-container {
	position: relative;
	display: flex;
	top: -30px;
}

.wo-wt-warmup {
	font-size: 18px;
	height: 36px;
	line-height: 33px;
	background-color: color("green","base");
	color: white;
	font-weight: 500;
	width: fit-content;
	padding: 0px 15px;
	text-align: center;
	border-right: 1px solid white;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

	&.sets {
		border-right: none;
	}
}

// Workout Walkthrough Complete

.complete-top {
	font-family: $font-alt;
	color: $font-grey;
	font-size: 20px;
	font-weight: 300;
	margin-top: 30px;

	@media #{$long-screen-only} {
		margin-top: 100px;
	}
}

.complete-middle {
	font-family: $font-alt;
	color: $header-color;
	font-size: 30px;
	font-weight: 500;
	margin-bottom: 30px;
}

.medal-gradient {
  font-size: 72px;
}

svg.fa-medal.medal-gradient * {
	fill: url(#medal-gradient);
}

.calibrate-circle {
	margin-top: 10px;
	height: 175px;
	width: 175px;
	border-radius: 50%;
	border: 3px solid $primary-color;
	color: $header-color;
	font-size: 45px;
	margin-left: auto;
	margin-right: auto;
	line-height: 175px;
	white-space: nowrap;
}

.bottom-btn {
	position: fixed;
	bottom: 40px;
	left: 50%;
    transform: translate(-50%, 0);
}

.complete-container {
	margin-top: 30px;
	display: inline-flex;
	margin-bottom: 70px;
	
	@media #{$long-screen-only} {
		margin-bottom: 120px;
	}
}

.complete-stats {
	font-size: 25px;
	font-weight: 300;
	color: $header-color;
}

.complete-stats-subtitle {
	font-size: 16px;
	font-family: $font-alt;
	font-weight: 300;
	color: $font-grey;
	margin-top: -5px;
}

.complete-spacer {
	margin-left: 10px;
	margin-right: 10px;
	width: fit-content;
}

.wo-wt-ex-tab {
	border-color: color("grey","lighten-3");
	border-style: solid;
	border-width: 0 1px 1px 1px;
	border-radius: 0 0 9px 9px;
	font-weight: 300;
	color: $header-color;
	font-size: 15px;
	padding: 6px 8px;
	white-space: nowrap;
	background-color: white;
	margin-left: 10px;
	margin-top: -5px;
	float: left;
}

.wo-wt-ex-tab-wrapper, .wo-wt-form-wrapper {
	max-width: 480px;
	margin-left: auto;
	margin-right: auto;
}

.wo-wt-intro-new {
	font-weight: 300;
	color: color("green", "darken-2");
	clear: both;
	margin-top: 50px;
	margin-left: 20px;
	font-size: 16px;
	border-bottom: 1px solid color("green", "darken-2");
	width: fit-content;
	line-height: 18px;
	
	@media #{$short-screen-only} {
		margin-top: 10px;
	}
}

.wo-wt-intro-text {
	font-weight: 300;
	color: $header-color;
	margin-left: 20px;
	margin-top: 10px;
	margin-right: 8px;
	font-size: 25px;
	line-height: 28px;
	margin-bottom: 25px;

	@media #{$short-screen-only} {
		margin-bottom: 5px;
	}
}

.init-text-1 {
	color: $header-color;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 2px;
	padding-top: 10px;

	@media #{$short-screen-only} {
		font-size: 13px;
	}
}

.init-text-2 {
	color: $font-grey;
	font-weight: 300;
	font-size: 14px;
	line-height: 14px;
	padding-bottom: 10px;
	overflow: hidden;

	@media #{$short-screen-only} {
		font-size: 10px;
	}
}

.init-wrapper {
	margin-left: 8px;
	margin-right: 8px;
}

.wt-detail-spacing {
	margin: 8px 30px;
}

//Exercise swap
.ex-swap-header {
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	height: fit-content;
	padding-bottom: 10px;
	color: white;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.ex-swap-btn, .action-container {
	font-size: 20px;
	padding: 12px 10px 12px 10px;
	float: right;
	color: $font-grey;
    border-left: 1px solid color("grey", "lighten-2");
	&:not(.inactive) {
		cursor: pointer;
	}
	text-align: center;

    &.extra-padding {
        padding-left: 15px;
        padding-right: 15px;
    }

	&.no-border {
		border-left: none;
	}

	&.left {
		float: left;
	}

	.action-label {
		height: 0;
		margin-top: -5px;
		line-height: 11px;
		font-size: 10px;
	}

	[type="checkbox"].filled-in {
		+ span {
			top: 6px;
		}
	}
}

.ex-swap-btn:not(.inactive) {

    &:hover {
        background-color: #eee; 
    }

    &:active {
        background-color: $font-grey;
        color: white;
    }
}

.label-1 {
	color: $header-color;
	font-weight: 300;
	font-size: 15px;
}

.label-2 {
	color: $header-color;
	font-weight: 500;
	font-size: 17px;
}

.label-3 {
	color: $header-color;
	font-family: $font-alt;
	font-size: 23px;
    font-weight: 300;
    line-height: 1.2;
}

@media(max-width: 425px) {
    .label-3 {
        font-size: 18px;
    }
}

.text-input-container {
	width: fit-content;
	display: flex;
	align-items: center;
	margin-right: auto;
	margin-left: auto;
}

//Routine Editor 
.title-medium {
	font-size: 18px;
	font-weight: 500;
	&.thin {
		font-weight: 300;
	}
}

.subtitle-small {
	font-size: 12px;
	font-weight: 300;
}

.subtitle-reg {
	font-size: 12px;
	font-weight: 400;
}

//Settings 
.settings-title {
	color: $font-grey;
	font-size: 16px;
	width: fit-content;
	font-weight: 400;
	line-height: 42px;
	white-space: nowrap;

	&.subtitle {
		font-size: 15px;
	}
}

.settings-input {
	border: 1px solid color("grey", "lighten-3");
	border-radius: 4px;
	height: 40px;
	width: 40px;
	color: $font-grey;
	font-weight: 300;

	&.si-med {
		width: 55px;
	}
}

:focus {
    outline: none;
}

.upper-right-card-cont {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}

.plate-calc-row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    .plate-calc-cell {
        flex-grow: 1;
        padding: 20px;
    }
}

#notifier {
	position: fixed;
	bottom: 70px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 9999999;
	.notifier-msg {
	  width: 150px;
	  background-color: $primary-color;
	  color: white;
	  border-radius: 3px;
	  text-align: center;
	  padding: 15px;
	}

	&.point-down::after {
		display: block;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		content: '';
		width: 0;
		border-right: 25px solid transparent;
		border-left: 25px solid transparent;
		border-top: 15px solid $primary-color;
	}
}
  
.app-scroll-container {
	overflow: auto;
	max-height: 100vh;
	&:not(.no-scroll-padding) {
		padding-bottom: $bottom-scroll-padding;
	}
	max-width: 100vw;
}

//Meal Plan- Day View
.sideways-text {
	transform: (rotate(270deg));
	transform-origin: (0 0);
	font-size: 12px;
	color: $header-color;
	position: absolute;
	bottom: 0;
	left:0;
}

.meal-plan-heading-2 {
	font-size: 19px;
	margin-left: 10px;
	color: $header-color;
	font-weight: 500;
}

/* Not sure why it's a partial pixel off but this fixed it (?) */
.image-meal-card-day {
    float: left;
	width: 100px;
	min-width: 100px;
    height: 85.5px;
    overflow: hidden;
    position: relative;
    border-radius: 15px 0 0 15px;
	margin-right: 5px;
	//margin-top: -.5px;
	@media #{$extra-small-and-down} {
		width: 85px;
		min-width: 85px;
		}
	@media #{$medium-and-up} {
		width: 127px;
		min-width: 127px;
		}	
  
    img, svg:not(.svg-inline--fa) {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 100%;
      -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
		  transform: translate(-50%,-50%);
    }
}

  .meal-plan-recipe-title {
	  font-size: 14px;
	  color: $header-color;
	  font-weight: 400;
  }
  
  .button-placeholder {
	  position: absolute;
	  top: 0;
	  height: 100%;
	  right: 0;
	  width: 55px;
	  min-width: 55px;
	  border-left: 1px solid color("grey","lighten-2");
	  border-radius: 0px 15px 15px 0px;
	  text-align: center;	
	  color: $font-grey;
	  font-size: 12px;
	  text-transform: uppercase;
	  svg {
		  font-size: 16px;
	  }
	  margin-left: 5px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  &.active {
		  background-color: $primary-color;
		  color: white;
	  }
	  &.wide {
		  width: 100px;
	  }
  }

  .fab-label-container {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%) translateX(-100%);
	padding-bottom: 25px;
  }

  .fab-label {
	  border-radius: 15px;
	  width: fit-content;
	  color: white;
	  font-weight: 600;
	  font-size: 14px;
	  white-space: nowrap;
	  padding: 5px 10px 5px 10px;
	}

  .align-bottom {
	  position: absolute;
	  bottom: 0;
	  align-items: flex-end;
	  &.ab-left {
		  right: auto;
		  left: 0;
	  }
	  &.ab-right {
		left: auto;
		right: 0; 
	  }
  }

  .align-middle {
	position: absolute;
	bottom: 0;
	align-items: center;
	&.ab-left {
		right: auto;
		left: 0;
	}
	&.ab-right {
	  left: auto;
	  right: 0; 
	}
}

  .png-sizing {
	  margin:auto;
	  img {
		height: 14px;
	  }
  }

  .small-thin-header  {
	  font-weight: 300;
	  font-size: 18px;
	  color: $header-color;
  }

.macros-text {
	font-weight: 400;
	font-size: 25px;
	line-height: 15px;		
	&.v2 {
		font-size: 20px;
	}
}

.macro-subtitle {
	margin-top: 5px;
	margin-bottom: 8px;
	line-height: 14px;
	font-weight: 300;
}	
  .goal-text {
	  font-size: 12px;
	  font-weight: 300;
	  border-radius: 15px;
	  border: 1px solid color("grey","lighten-2");
	  width: fit-content;
	  padding: 0px 10px;
	  color: color("grey","darken-1");
	  margin: 5px auto 5px auto;
	  &.error {
		  background-color: $error-color;
		  color: white;
		  border-color: $error-color;
		  font-weight: 400;
	  }
  }

  .macro-container {
	  width:130px;
  }

  .error-bubble {
	  width: 12px;
	  height: 12px;
	  color: color("red","base");
  }

  .error-bubble-2 {
	width: 13px;
	height: 13px;
	color: white;
	background-color: color("red","base");
	border-radius: 50%;
	font-weight: 600;
	line-height: 13px;
	@extend .z-depth-1-half;
	display: inline-block;
}

.nutrition-background {
	background-color: $background-off-white-2;
	min-height: 100vh !important;
	
  }

.nutrition-header-wrapper {
    margin-left: auto;
	margin-right: auto;
	width: fit-content;
}

.type-cards-wrapper { 
	@media #{$small-and-down} {
	display: flex;
	// margin-right: 5px;
	flex-wrap: nowrap;
	overflow-x: auto;
	z-index: -1;
	-webkit-overflow-scrolling: touch; //smooth, no-momentum scrolling for iOS
	// margin-left: -10px;
    // margin-right: -10px;
	padding-left: 10px;
	max-width: 100vw;
	.card {
		flex: 0 0 auto;
		}
	
	}
	
}

.type-cards-container {
	padding-right: 0px ;

	@media #{$large-and-up} {
		padding-left: 80px ;
		padding-right: 30px ;
	}
}

.meals-section-container {
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	max-width: 100%;
}

.meal-type-container {
	text-align: left;
}

.topper-container{
	max-width: 1280px;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	@media #{$small-and-down} {
		max-width: 600px;
		width: 99%;
	}
	@media #{$extra-small-and-down} {
		max-width: 500;
		width: 100%;
	}

} 

#get-app-wrapper {
	position: fixed;
	bottom: 0;
	width: 100vw;
	z-index: 999;
}

#get-app-panel {
	text-align: center;
	background-color: #ffffff;
	box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
	padding-bottom: $bottom-nav-height;
}

.get-app-btns {
	white-space: nowrap;
	display: inline-block;
	a {
	  display: inline;
	  &:hover, &:active, &:focus {
		text-decoration: none;
		background-color: unset;
	  }
	  img{
		margin-left: -10px;
		height: 75px;
	  }
  
	  &.app-store {
		img {
			margin-bottom: 13px;
		  margin-left: 0;
		  height: 51px;
		  cursor: pointer;
		}
	  }
  
	  @media(max-width: 365px) {
		img{
		  margin-left: -10px;
		  height: 62px;
		}
  
		&.app-store {
		  img {
			margin-left: -10;
			height: 42px;
		  }
		}
	  }
  
	}
  }

// Meal Editor

.meal-editor-container {
	max-width: 410px;
	margin-left: auto;
	margin-right: auto;
}

.me-date-container {
	display: flex;
	align-items: center;
	width: 100%;
	text-align: center;

	@media #{$large-and-up} {
		margin-top: 20px;
	}
	
}

.me-date {
	font-weight: 400;
	height: 40px;
	color: $font-grey;
	line-height: 40px;
	vertical-align: middle;
	
	display: inline-block;
	&.active {
		color: white;
	}
} 

// .stretch {
//   width: 100%;
//   display: inline-block;
// }

.side-dish-container {
	display: flex;
	margin: auto auto 5px auto;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	&.me-margin {
		@media #{$large-and-up} {
			margin-top: 70px;
		}
	}	
}

.me-nut-container {
	width: 110px;
}

.me-cals {
	width: 100px;
	font-size: 18px;
	font-weight: 600;
	border-bottom: 1px solid color("grey","lighten-2");
	.cals {
		font-weight: 500;
	}
}

.me-macro {
	width: 70px;
	font-weight: 500;
	.net {
		font-size: 12px;
		margin-left: -3px;
	}
}

.me-macro-num {
	width: 37px;
	font-weight: 600;
}

.me-details {
	font-size: 25px;
	font-family: $font-alt;
	color: $header-light;
	i {
		font-size: 22px;
	}
}

// Recipe Card
.icon-placement {
	position: absolute;
	color: white;
	
	&.bottom-left {
		bottom: 17px;
		left: 10px;
		font-size: 35px;
		i, svg {
			-webkit-text-stroke: 1px color("grey","base");
		  }
		  @media #{$rc-medium-and-up} {
			bottom: 0px;
		}
	}

	&.bottom-right {
		bottom: 12px;
		right: 20px;
		font-size: 40px;
		color: color("red","base");
		@media #{$rc-medium-and-up}{
			bottom: 0px;
		}
	}

	&.top-left {
		top: 10px;
		left: 10px;
	}

	&.top-right {
		top: 10px;
		right: 10px;
	}
	
}

.sliding-background {
	width: 100%;
	z-index: 9;
	position: relative;
	border-radius: 20px 20px 0px 0px;
	margin-top: 330px;
	background-color: white;
	&.wp-page {
		background-color: $background-off-white;
		margin-bottom: -80px;
	}
	height: 100%;
	@extend .elevation-4-dp;
	min-height: 100vh;
	padding-bottom: $bottom-scroll-padding;
}

.rp-header-wrapper {
	position: relative;
	height: 60px;
	width: 100%;
}

.rp-date {
	margin-top: 15px;
	position: relative;
	float: left;
	margin-left: 10px;
	color: $font-grey;
	font-size: 12px;
	padding: 2px 7px;
	border-radius: 15px;
	border: 1px solid $font-grey;
	
	&.v2 {
		float: none;
		 width: fit-content;
		 margin-left: 0px;
	}
}

.rp-ol {
	color: $header-color;
	font-size: 16px;
	padding: 0px;
	margin: 5px 16px 10px 40px;
}

.added-people-wrapper { 
	display: flex;
	flex-wrap: nowrap;
	&.wrap {
		flex-wrap: wrap;
	}

	&.fake-pad {
		padding-top: 10px;
		margin-top: -10px;
	}

	overflow-x: auto;
	-webkit-overflow-scrolling: touch; //smooth, no-momentum scrolling for iOS
	padding-left: 10px;
	.btn {
		flex: 0 0 auto;
		}
	
}

.rc-hide-on-med-and-up {
	@media #{$rc-medium-and-up} {
		display: none !important;
	  }
}

.rc-hide-on-small-only {
	@media #{rc-small-and-down} {
		display: none !important;
	  }
}

//Search
.search-container {
	max-width: 500px;
	width: 80%;
	&.sc-center {
		margin-left: auto;
		margin-right: auto;
	}
	@media #{$large-and-up} {
		max-width: 400px;
	}
	&.width-100 {
		width: 100%;
	}
}

.toggle-3 {
	width: 258px;
	height: 40px;
	padding: 3px;
	background-color: color("grey","lighten-3");
	border-radius: 50px;
	font-size: 12px;
	line-height: 34px;
	color: $font-grey;
	text-align: center;
	display: flex;
	cursor: pointer;
	.active {
		height: 34px;
		padding-left: 13px;
		padding-right: 13px;
		width: fit-content;
		background-color: $primary-color;
		border-radius: 50px;
		color: white;
		font-weight: 500;
		font-size: 14px;
		line-height: 34px;
		flex-grow: 1;
	}
	.inactive {
		padding-left: 15px;
		padding-right: 15px;
		width: fit-content;
		flex-grow: 1;
	}
}

// Meal Swapping 
.type-cards-wrapper-2 {
	@media #{$small-and-down} {
		z-index: -1;
		.card {
			flex: 0 0 auto;
			}
		
		}

	@media #{$extra-small-and-down} {
		.card {
			margin-left: 2px;
			margin-right: 2px;
		}
	}

}

.search-box {
	margin-top: 50px;
	margin-left: 20px;
	background-color: white;
	overflow: auto;
	padding: 15px;
}

.extra-margin-on-large {
	margin-top: 20px;

	@media #{$large-and-up} {
		margin-top: 50px;
	}
}

//Recipe Editor
.re-topper {
	width: 310px;
	margin: 0px;
	display: flex;
	.re-name {
		width: 250px;
		margin-right: 30px;
	}
	.re-servings {
		width: 70px;
	}
}

.macros-box {
	height: 80px;
	max-width: 380px;
	background-color: $background-off-white;
	border-radius: 9px;
	margin: auto;
}

.macros-circle-chart {
	height: 68px;
	width: 68px;
	border: 3px solid $primary-color;
	border-radius: 50%;
	margin: auto 10px auto 15px;
}

.num-sub-container {
	width: 56px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.macro-circle-text {
	fill: $font-grey;
	font-family: sans-serif;
	font-size: 12px;
	text-anchor: middle;
	font-weight: 300;
	
	&.subtitle {
		font-size: 7.5px;
		font-weight: 500;
	}
}

.number-circle {
	font-size: 20px;
	font-weight: 300;
	color: $font-grey;
	line-height: 25px;
}

.subtitle-circle {
	font-size: 12px;
	font-weight: 500;
	color: $font-grey;
	line-height: 12px;
}

.re-ingredient-container {
	height: 40px;
	line-height: 40px;
	display: flex;
	flex-wrap: nowrap;
	border-bottom: 1px solid color("grey","lighten-2");
	margin-left: 20px;
	margin-right: 20px;
	@media #{$extra-small-and-down} {
		margin-left: 5px;
		margin-right: 5px;
	}
	color: $font-grey;

	.re-ingredient-desc {
		flex-grow: 1;
		min-width: 0;
		white-space: nowrap;
		.re-ingr-piece {
			max-width: 50%;
			flex-shrink: 0;
			margin-right: 5px;
		}
	}

	.re-ingredient-btns {
		white-space: nowrap;
	}
}

.swap-date {
	width: 45px;
	line-height: 1;
	font-weight: 400;
	font-size: 10px;
	&.main {
		font-weight: 500;
		margin-top: 11px;
		font-size: 14px;
	}
}

.swap-day-margins {
	margin-left: 15px;
	margin-right: 15px;
	@media #{$extra-small-and-down} {
		margin-left: 5px;
		margin-right: 5px;
	  }
}

.swap-container, .modal-list-header {
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
}

.swap-date-title {
	line-height: 1;
	font-size: 14px;
	font-weight: 500;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: left;
	padding-left: 5px;
	margin-right: 25px;
	margin-top: 18px;
}

.swap-check-cont {
	position: absolute;
	top: 5px;
	right: 20px;
	pointer-events: none;
}

.circle-img {
	&:not(.standalone) {
		float: left;
		margin-left: 5px;
	}
	min-width: $circle-img-dim;
	width: $circle-img-dim;
	height: $circle-img-dim;
	&.sm {
		min-width: 32px;
		width: 32px;
		height: 32px;
	}
	&.xs {
		min-width: 20px;
		width: 20px;
		height: 20px;
	}
	overflow: hidden;
	position: relative;
	border-radius: 50%;
	&.no-clip {
		border-radius: 0;
	}
	&.cntr {
		top: 50%;
		transform: translateY(-50%);
	}
	img, svg:not(.svg-inline--fa), svg.img-placeholder {
		position: absolute;
		left: 50%;
		top: 50%;
		height: 100%;
		-webkit-transform: translate(-50%,-50%);
			-ms-transform: translate(-50%,-50%);
		transform: translate(-50%,-50%);
	}
	.center-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		font-size: 18px;
		color: white;
	}

	&.usr-filter {
		position: absolute;
		top: -10px;
		right: -10px;
		&.ddown {
			right: unset;
			left: 0;
			top: 0;
		}
	}
}

.group-img-cont {
	min-width: 40px;
	width: 40px;
	height: 40px;
	position: relative;
	overflow: hidden;
	.circle-img {
		position: absolute;
	}
	&.n2, &.n3, &.n4 {
		.circle-img {
			min-width: $two-circle-dim;
			width: $two-circle-dim;
			height: $two-circle-dim;
		}
	}
	&.n2 {
		.circle-img:nth-of-type(1) {
			top: 0;
			left: 0;
		}
		.circle-img:nth-of-type(2) {
			top: $two-circle-dim;
			left: $two-circle-dim;
		}
	}

	&.n3 {
		.circle-img:nth-of-type(1) {
			top: 0;
			left: 0;
		}
		.circle-img:nth-of-type(2) {
			top: 0;
			left: $two-circle-dim;
		}
		.circle-img:nth-of-type(3) {
			top: $two-circle-dim;
			left: calc(#{$two-circle-dim}/2);
		}
	}
	&.n4 {
		.circle-img:nth-of-type(1) {
			top: 0;
			left: 0;
		}
		.circle-img:nth-of-type(2) {
			top: 0;
			left: $two-circle-dim;
		}
		.circle-img:nth-of-type(3) {
			top: $two-circle-dim;
			left: 0;
		}
		.circle-img:nth-of-type(4) {
			top: $two-circle-dim;
			left: $two-circle-dim;
		}
	}

	&.n5, &.n6, &.n7, &.n8, &.n9 {
		.circle-img {
			min-width: $three-circle-dim;
			width: $three-circle-dim;
			height: $three-circle-dim;
		}
	}
	&.n5, &.n6, &.n7 {
		.circle-img:nth-of-type(1) {
			top: calc(#{$three-circle-dim}/2);
			left: 0;
		}
		.circle-img:nth-of-type(2) {
			top: 0;
			left: $three-circle-dim;
		}
		.circle-img:nth-of-type(3) {
			top: calc(#{$three-circle-dim}/2);
			left: calc(#{$three-circle-dim}*2);
		}
	}
	&.n5 {
		.circle-img:nth-of-type(4) {
			top: calc(#{$three-circle-dim}*1.5);
			left: calc(#{$three-circle-dim}/2);
		}
		.circle-img:nth-of-type(5) {
			top: calc(#{$three-circle-dim}*1.5);
			left: calc(#{$three-circle-dim}*1.5);
		}
	}
	&.n6, &.n7 {
		.circle-img:nth-of-type(4) {
			top: calc(#{$three-circle-dim}*1.5);
			left: 0;
		}
		.circle-img:nth-of-type(5) {
			bottom: 0;
			left: $three-circle-dim;
		}
		.circle-img:nth-of-type(6) {
			top: calc(#{$three-circle-dim}*1.5);
			left: calc(#{$three-circle-dim}*2);
		}
	}
	&.n7 {
		.circle-img:nth-of-type(7) {
			top: $three-circle-dim;
			left: $three-circle-dim;
		}
	}
	&.n8,&.n9 {
		.circle-img:nth-of-type(1) {
			top: 0;
			left: 0;
		}
		.circle-img:nth-of-type(2) {
			top: 0;
			left: $three-circle-dim;
		}
		.circle-img:nth-of-type(3) {
			top: 0;
			left: calc(#{$three-circle-dim}*2);
		}
		.circle-img:nth-of-type(4) {
			top: $three-circle-dim;
			left: 0;
		}
		.circle-img:nth-of-type(5) {
			top: $three-circle-dim;
			left: $three-circle-dim;
		}
		.circle-img:nth-of-type(6) {
			top: $three-circle-dim;
			left: calc(#{$three-circle-dim}*2);
		}
		.circle-img:nth-of-type(7) {
			top: calc(#{$three-circle-dim}*2);
			left: 0;
		}
		.circle-img:nth-of-type(8) {
			top: calc(#{$three-circle-dim}*2);
			left: $three-circle-dim;
		}
		.circle-img:nth-of-type(9) {
			top: calc(#{$three-circle-dim}*2);
			left: calc(#{$three-circle-dim}*2);
		}
	}
	&.n8 {
		.circle-img:nth-of-type(7) {
			left: calc(#{$three-circle-dim}/2);
		}
		.circle-img:nth-of-type(8) {
			left: calc(#{$three-circle-dim}*1.5);
		}
	}

}

//Add-People Flow
.added-person-box-closed, .modal-listing-box {
	min-height: 40px;
	&:not(.non-modal) {
		max-width: 500px;
		@media #{$extra-small-and-down} {
			width: 90%;
		}
	}
	border-radius: 10px;
	border: 1px solid color("grey","lighten-2");
	color: $font-grey;
	line-height: 40px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	&.added {
		border: none;
		color: white;
		background-color: $primary-color;
	}
	&.expandable, &.selected {
		border: 1px solid $primary-color;
		color: $font-grey;
		background-color: #F6FBF7;
	}
	&.error {
		border: 1px solid color("red","base");
		color: color("red","base");
		background-color: color("red","lighten-4");
	}

	.child-cont {
		line-height: 1.5;
		color: $off-black;
	}
}

.modal-listing-box.align-left {
	text-align: left;
}

.modal-listing-title-block {
	font-size: 18px;
	font-weight: unset;
	margin-left: 15px;
	line-height: 1;
	display: inline-block;
	vertical-align: middle;
	max-width: 80%;
	.subtitle {
		font-weight: 300;
		font-size: 13px;
	}
}

//Portions-Popup
.portions-container {
	width: 350px;
}

.annual-savings-flag {
	position: absolute;
	top: 0;
	right: 15px;
	padding: 5px;
	border-radius: 5px;
	background-color: color("light-blue", "darken-3");
	color: white;
	transform: translateY(-60%);
}

.affiliate-disc-flag {
	position: absolute;
	top: 0;
	left: 50%;
	padding: 5px;
	border-radius: 5px;
	background-color: color("light-blue", "darken-3");
	color: white;
	transform: translateX(-50%) translateY(-60%);
	z-index: 99;
	@media(max-width: 750px) {
		top: -40px;
	}
}

.free-trial-flow {
	position: relative;
	.text-block {
		position: relative;
		padding-top: 10px;
		margin-left: 25px;
		line-height: 1.1;
		color: $font-grey;
		.header-text {
			font-weight: 700;
			color: $header-color;
			font-size: 15px;
		}
		&:not(:last-of-type) {
			margin-bottom: 15px;
		}
		.step-icon {
			position: absolute;
			left: -21px;
			&.trophy {
				left: -22.5px;
			}
			top: 15px;
			color: white;
		}
	}
	.sidebar {
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		background-color: $primary-color;
		border-color: $primary-color;
		border-radius: 10px;
	}
}

.free-trial-flow-wrap {
	background-color: #ffffffc8;
	border-radius: 5px;
}

.no-pointer-events {
	pointer-events: none;
}

.section-title.modal-listing {
	font-size: 20px;
	color: $header-color;
	font-weight: 300;
}

.no-shrink {
	flex-shrink: 0;
}

.signed-in-as {
	font-size: 9px;
	background-color: #D1D8DB;
	color: black;
	padding: 3px;
}

.bcard-logo-cont {
	position: absolute;
	top: 50%;
	transform: translateY(-50%) translateX(-50%);
	left: 75%;
	@media(max-width: 550px) {
		left: 50%;
	}
	text-align: center;
	z-index: 2;
}

.mcard-macro-sum {
	font-size: 11px;
	position: absolute;
	bottom: 2px;
	&.meal {
		bottom: 45px;
		margin-left: 5px;
	}
	white-space: nowrap;
}

.max80vw {
	max-width: 80vw !important;
}

.listing-right-btn {
	float: right;
	width: 40px;
	text-align: center;
	cursor: pointer;	
}

.mtn5 {
	margin-top: -5px !important;
}


.app-icon-card {

	.cont {
		height: 90px;
		display: flex;
		justify-content: center;
		align-items: center;

		.btn-cont {
			flex-grow: 1;
		}

		.icon-cont {
			flex-shrink: 1;
			padding: 10px;
		}
		.app-icon {
			width: 70px;
			height: auto;
		}

		.x-holder {
			width: 30px;
		}
	}
}

.scanner-vid {
	video {
		display: block;
		width: 100%;
		height: 100%;
		background-color: black;
	}
}

.assess-res-cont {
	display: flex;
	max-width: 480px;
	align-items: center;
}

.vid-width-cont {
	max-width: 480px;
}

.vid-width-btn {
	text-align: center;
	width: 480px;
}

.strtst-res-cont {
	max-width: 480px;
	border: 1px solid #ddd;
	border-radius: 5px;
}

.lh0 {
	line-height: 0;
}

.photos-container {
	display: flex;
	width: auto;
	margin: 0 auto;
	overflow-x: hidden; /* Prevent horizontal scrolling at the container level */
	border-radius: 10px;
	max-height: calc(min(80vh,600px));
	aspect-ratio: 9 / 8;
	@extend .z-depth-1;
}
  
.photo-view {
	flex: 1;
	position: relative;
	width: 50%; /* Ensure each photo view takes up half the width of the container */
	padding-top: 88.88%; /* 100 * (16 / 9) = 177.77% for 9:16 aspect ratio */
	overflow: auto;
}

.prog-photo-itm {
	width: 80px;
	min-width: 80px;
	height: 80px;
	border-radius: 10px;
	@extend .z-depth-1;
	display: inline-block;
	overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
	margin: 5px 10px;
	position: relative;
	cursor: pointer;

	.dt-label {
		position: absolute;
		bottom: 4px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 10px;
		white-space: nowrap;
		color: white;
		background-color: black;
		border-radius: 4px;
		padding: 2px;
	}
}

.photo-itms-wrapper {
	display: inline-flex;
	margin-right: 5px;
	flex-wrap: nowrap;
	overflow-x: auto;
	z-index: -1;
	-webkit-overflow-scrolling: touch;
	max-width: 100%;
	box-shadow: inset 0px 3px 1px -2px rgba(0, 0, 0, 0.2), inset 0px 2px 2px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	border-radius: 10px;
}

.sel-prog-photo {
	border: 3px solid $primary-color;
	.dropdown-content {
		pointer-events: all;
	}
}

.sel-prog-itm {
	border: 3px solid $primary-color;
}

@media #{$medium-and-down} {
	.photo-itms-wrapper::-webkit-scrollbar {
		display: none;
	}
}

@media #{$small-and-down} {
	.photo-itms-wrapper {
		box-shadow: none;
		border-radius: none;
	}
}

.prog-photo-menu {
	position: absolute;
	top: 15px;
	right: 15px;
	width: 40px;
	height: 40px;
	font-size: 20px;
	cursor: pointer;
	background-color: #999;
	opacity: 0.75;
	color: white;
	border-radius: 50%;
	pointer-events: all;
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}