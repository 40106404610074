.set-container {
    display: inline-block;
    max-width: 100%;
}

@media(min-width: $set-background-width) {
    .set-container {
        padding: 10px;
        background-color: color("grey","lighten-3");
        border-radius: 2px;
    }
}