.plus-minus-toggle {
    position: relative;
    font-size: 1.25em;
    text-align: center;
    .minus-toggle {
      position: absolute;
      cursor: pointer;
      left: 25%;
      top: 50%;
      color: $icon-low-opacity;
      -webkit-transform: translateY(-50%) translateX(-50%);
      -ms-transform: translateY(-50%) translateX(-50%);
      transform: translateY(-50%) translateX(-50%);
      text-align: center;
    }
    .plus-toggle {
      position: absolute;
      cursor: pointer;
      right: 25%;
      top: 50%;
      color: $icon-low-opacity;
      -webkit-transform: translateY(-50%) translateX(50%);
      -ms-transform: translateY(-50%) translateX(50%);
      transform: translateY(-50%) translateX(50%);
  
      text-align: center;
    }

    &.large-input-toggle {
        .plus-toggle, .minus-toggle{
            top: 35%;
        }        
    }
  
    &.tighter {
      .minus-toggle, .servings-minus {
        left: 15%;
      }
      .plus-toggle, .servings-plus {
        right: 15%;
      }
    }
  
    &.inline-toggle {
      display: inline-block;
      width: 150px;
    }
  
    .plus-toggle, .minus-toggle, .servings-plus, .servings-minus {
      width: 50px;
      height: 40px;
      padding: 10px 0;
    }
  
    .input-display {
      font-weight: bold;
    }
  }