$types: ('t': 'top', 'r':'right', 'b':'bottom', 'l':'left');
@for $i from 0 through 20 {
    $num: $i*5;

    .ma#{$num} {
        margin: #{$num}px !important;
    }

    .pa#{$num} {
        padding: #{$num}px !important;
    }

    @each $type,$attr in $types {
        .m#{$type}#{$num} {
            margin-#{$attr}: #{$num}px !important;
        }

        .p#{$type}#{$num} {
            padding-#{$attr}: #{$num}px !important;
        }
    }
}

.mb150 {
    margin-bottom: 150px !important;
}

@for $i from 1 through 4 {
    $num: $i;

    .ma#{$num} {
        margin: #{$num}px !important;
    }

    .pa#{$num} {
        padding: #{$num}px !important;
    }

    @each $type,$attr in $types {
        .m#{$type}#{$num} {
            margin-#{$attr}: #{$num}px !important;
        }

        .p#{$type}#{$num} {
            padding-#{$attr}: #{$num}px !important;
        }
    }
}

.mb1 {
    margin-bottom: 1px !important;
}

@mixin listing-title-large {
    font-size: 18px;
    font-weight: unset;
    margin-left: 5px;
}

@mixin title-container {
    flex-grow: 1;
    padding: 5px;
    overflow: hidden;
    &.large-title {
        .exercise-title, .title {
            @include listing-title-large;
        }
    }
}

@mixin listing-title {
    color: $font-grey;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
}

.shadowed-circle-icon {
    border-radius: 50%;
    @extend .elevation-4-dp;
}

.btn.btn-facebook:not(.btn-flat):not(.btn-outlined) {
    background-color: #4C69BA;
    &:hover {
      background-color: lighten(#4C69BA,5%);
    }
}

.btn.btn-google:not(.btn-flat):not(.btn-outlined) {
    background-color: #DD4B39;
    &:hover {
      background-color: lighten(#DD4B39,5%);
    }
}

.btn.btn-apple:not(.btn-flat):not(.btn-outlined) {
    background-color: #000;
    &:hover {
      background-color: lighten(#000,5%);
    }
}

.bottom-nav {
    position: fixed;
    background-color: color("shades","white");
    @extend .elevation-4-dp;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    text-align: center;
    line-height: unset;
    height: unset;
    font-size: 8px;
    width: 100%;
    z-index: 10;

    ul {
        display: flex;
        height: $bottom-nav-height;
        align-items: center;
        justify-content: center;
        margin: 0;
        li {
            flex-grow: 1;
            flex-basis: 0;
            color: $off-black;
            a {
                color: $off-black;
                display: block;
                &.active, &:hover, &:focus {
                    color: $primary-slightly-light;
                    font-weight: 700;
                }
            }
        }
    }
}

.circle-img-icon {
    position: absolute;
    right: 7px;
    top: -1px;
    font-size: 15px;
    color: $primary-color;
}

.notification-icon {
    position: absolute;
    top: -3px;
    right: -6.5px;
    &.for-btn {
        right: -3px;
    }
    &.left-side {
        right: unset;
        left: 6.5px;
    }
    font-size: 9px;
    font-weight: bold;
    color: white;
    background-color: red;
    &.primary {
        background-color: $primary-color;
    }
    &.blue {
        background-color: #4064C5;
    }
    border-radius: 50%;
    -moz-border-radius: 50%;
    width: 13px;
    height: 13px;
    line-height: 13px;
    vertical-align: middle;
    text-align: center;
    z-index: 2;
}
.inline-notificon {
    font-size: 12px;
    font-weight: bold;
    color: white;
    background-color: red;
    &.primary {
        background-color: $primary-color;
    }
    &.grey {
        background-color: $font-grey;
    }
    &.bigger {
        font-size: 16px;
        min-width: 24px;
        line-height: 18px;
    }
    min-width: 18px;
    line-height: 13px;
    border-radius: 50%;
    padding: 3px;
    display: inline-block;
    text-align: center;
}

.activity-tabs {
    position: fixed;
    background-color: color("shades","white");
    width: 100%;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    @extend .elevation-4-dp;
    top: 0;
    padding-top: var(--sat);
    height: $bottom-nav-offset-top;
    &.for-simple-nav {
        top: $simple-nav-height-safe;
        padding-top: 0;
        height: $bottom-nav-height;
    }
    display: flex;
    font-size: 13px;
    z-index: 10;
    &.responsive {
        display: none;
    }
    .activity-tab {
        text-align: center;
        height: 100%;
        flex-grow: 1;
        color: $low-emphasis;
        padding-top: $bottom-nav-height/3;
        cursor: pointer;
        &.active {
            color: $off-black;
            border-bottom: 3px solid $off-black;
            font-size: 1.25em;
            font-weight: 700;
            @each $plan-type in $trainer-plans {
                &.#{$plan-type} {
                    border-bottom: 3px solid color($plan-type,"border")
                }
            }
        }
    }
    .tab-header {
        text-align: center;
        height: 100%;
        flex-grow: 1;
        color: $off-black;
        padding-top: $bottom-nav-height/3;
        font-size: 1.25em;
        font-weight: 700;
    }
    &.not-top {
        position: static;
        box-shadow: none;
    }
}

.nav-container { 
    padding-top: 0;
    padding-bottom: $bottom-nav-cont-padding;
    &.messaging-cont {
        padding-bottom: $bottom-nav-min-padding;
    }
    &.activity-tabs-container {
        padding-top: $bottom-nav-offset-top;
    }
    &.activity-tabs-container-extra {
        padding-top: calc(var(--sat) + 65px);
    }
}

.mp-container {
    padding-top: 50px;
}

@mixin meal-plan-header-with-nav {
    background-color: white;
    color: $primary-color;
    label {
        color: $font-grey;
    }
    border-top: 1px solid #ddd;
    .mp-week-toggle {
        color: $primary-color;
    }
}

.meal-plan-header {
    position: fixed;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    @extend .elevation-4-dp;
    color: white;
    width: 100%;
    top: 0;
    padding-top: var(--sat);
    &.for-simple-nav {
        top: $simple-nav-height-safe;
        @include meal-plan-header-with-nav;
        .meal-plan-heading {
            color: $header-color;
            font-weight: normal;
        }
    }
    height: calc(var(--sat) + 64px);
    display: flex;
    font-size: 13px;
    z-index: 10;
    border-radius: 0 0 10px 10px;
    background-color: $primary-color;
    label {
        color: white;
    }
    .meal-plan-heading {
        color: white;
        font-size: 25px;
        margin: 0px 5px 0 20px;
        @media #{$extra-small-and-down} {
            font-size: 21px;
            margin: 0px 5px 0 8px;
        }
        @media #{$medium-only} {
            font-size: 37px;
            font-weight: 400;
            // margin-left: 50px;
        }
        @media #{$large-and-up} {
            color: $header-color;
            font-size: 37px;
            font-weight: 400;
        }
    }

}

.slight-rounded-border {
    border: 1px solid #ddd;
    border-radius: 5px;
}

@media #{$large-and-up} {
    .bottom-nav {
        top: 0;
        padding-top: var(--sat);
        bottom: unset;
        padding-bottom: 0;
        &.with-mp-header {
            border-radius: 0 0 10px 10px;
        }
    }

    .activity-tabs:not(.for-simple-nav) {
        border-top: 1px solid #ddd;
        box-shadow: none;
        position: static;
        &.no-border {
            border-top: none;
        }
        &.bottom-border {
            border-top: none;
            border-bottom: 1px solid #ddd;
        }
    }

    .meal-plan-header:not(.for-simple-nav) {
        box-shadow: none;
        position: relative;
        @include meal-plan-header-with-nav;
    }

    .nav-container { 
        padding-top: $bottom-nav-offset-top;
        &.messaging-cont {
            padding-bottom: 0;
        }
        &.with-signed-in-as {
            padding-top: calc(var(--sat) + #{$bottom-nav-height} + #{$signed-in-as-height});
        }
        &.activity-tabs-container {
            padding-top: calc(var(--sat) + #{$bottom-nav-height*2});
            &.with-signed-in-as {
                padding-top: calc(var(--sat) + #{$bottom-nav-height*2} + #{$signed-in-as-height});
            }
        }
        &.activity-tabs-container-extra {
            padding-top: calc(var(--sat) + 65px + #{$bottom-nav-height});
            &.with-signed-in-as {
                padding-top: calc(var(--sat) + 65px + #{$bottom-nav-height} + #{$signed-in-as-height});
            }
        }
    }
}

@media(max-width: 359px) {
    .bottom-nav {
        font-size: 7px;
        ul {
            height: 40px;
        }
    }
}

hr {
    border-style: solid;
    border-width: thin;
    border-color: #f0f0f0;
}

.top-splash-title {
    color: white;
	background-color: $primary-color;
	width: 100%;
	height: auto;
	font-weight: 500;
	font-size: 28px;
	line-height: 1;
	padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-top: calc(var(--sat) + #{$simple-nav-height-num+10}px);
	margin-bottom: 10px;
    text-transform: uppercase;
    position: relative;
    z-index: 98;
    text-align: center;
}

.flow-nav-under-shadow {
    position: fixed;
    height: $simple-nav-height-safe;
    @extend .elevation-4-dp;
    z-index: 97;
    width: 100%;
}

.flow-nav {
    position: fixed;
    &:not(.no-bcg) {
        background-color: white;
    }
    height: $simple-nav-height-safe;
    top: 0;
    padding-top: var(--sat);
    left: 0;
    width: 100%;
    z-index: 99;

    .back-arrow, .left-btn {
        color: $icon-low-opacity;
        cursor: pointer;
        padding: 11px 24px 8px 8px;
        position: absolute;
        top: var(--sat);
        left: 0;
        svg {
            font-size: 28px;
        }
    }

    .exit-btn, .right-btn {
        color: $icon-low-opacity;
        cursor: pointer;
        &:not(.with-flex) {
            padding: 11px 24px 8px 8px;
            position: absolute;
            top: var(--sat);
            right: 0;
        }
        &.with-flex {
            padding: 0 10px 0 15px;
        }
        svg {
            font-size: 28px;
        }
    }

    .progress {
        position: absolute;
        bottom: 0;
        margin: 0;
        left: 0;
        width: 100%;
    }

    &.primary {
        background-color: $primary-color;
        color: white;
        .exit-btn, .right-btn, .back-arrow, .left-btn, .topper-title {
            color: white;
        }
    }

    &.dark {
        background-color: $dark-mode-bcg;
        color: white;
        .exit-btn, .right-btn, .back-arrow, .left-btn, .topper-title, label {
            color: white;
        }
    }
  
    &.shadow {
      @extend .elevation-4-dp;
    }
}

.simple-nav-container {
    padding-top: calc(var(--sat) + #{$simple-nav-cont-padding});
    &.min-padding {
        padding-top: calc(var(--sat) + #{$simple-nav-height-num}px);
    }
    &.with-tabs {
        padding-top: calc(var(--sat) + #{$simple-nav-height-num*2 + 15}px);
    }
    &.for-iframe {
        margin: 0; 
        padding: 0; 
        height: 100%; 
        overflow: hidden;
    }
}

.fp-iframe-cont {
    position:absolute; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    top: 0; 
}

@media #{$medium-and-down} {
    .flow-nav.with-responsive-tabs {
        box-shadow: none;
    }

    .simple-nav-container.with-responsive-tabs {
        padding-top: calc(var(--sat) + #{$simple-nav-height-num*2 + 10}px);
    }

    .activity-tabs.responsive {
        display: flex;
    }
}

@media #{$simple-nav-btn-drop} {
    .simple-nav-container.with-drop-btn {
        padding-top: calc(var(--sat) + #{$simple-nav-height-num + 40}px);
    }
}

.white-icon-bcg {
    background: white;
    border-radius: 50%;
    display: inline-block;
    svg {
        vertical-align: middle;
        transform: scale(1.05);
    }
}

.StripeElement {
    background-color: white;
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}


.ex-vid-container {
    text-align: center;
    line-height: 0;
}

.ex-vid-parent, .vid-parent {
    position: relative;
    display: inline-block;
    line-height: 0;
    max-width: 100%;
    video:not(.no-placeholder), img:not(.overlay), .chartholder, iframe, .vid-upload-txt {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .vid-upload-txt {
        color: white;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        line-height: 1;
        text-align: center;
    }

    video, img:not(.overlay), .placeholder-svg, .chartholder {
        max-width: 100%;
        max-height: calc(min(500px,80vh));
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    &.limit-height {
        video, img:not(.overlay), .chartholder {
            max-height: 100%;
        }
    }
}

.chartholder {
    pointer-events: none;
    width: 480px;
    height: 100%;

    canvas {
        margin-top: 10px;
        margin-left: -5px;
    }
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.video-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 80px;
    color: white;
}

@media(min-width: 501px) {
    .wp-hide-lg {
        display: none !important;
    }
}

@media(max-width: 500px) {
    .wp-hide-sm {
        display: none !important;
    }
}

$bm-start-color:    #64FFDA;
$bm-end-color:      #9C27B0;
$bm-mid-color:     #03A9F4;

.score-legend-container {
    position: absolute;
    bottom: 50px;
    left: 15px;
    .top-title {
        position: absolute;
        top: -15px;
        left: -50%;
        font-size: 9px;
        color: #aaa;
        text-align: center;
    }

    .bottom-title {
        position: absolute;
        bottom: -15px;
        font-size: 9px;
        color: #aaa;
        text-align: center;
    }

    .title-container {
        position: absolute;
        top: 50%;
        left: -7px;
        width: 0;
        height: 30px;
        transform: translateY(50%);
        -webkit-transform: translateY(50%);
        -moz-transform: translateY(50%);
        -ms-transform: translateY(50%);
        -o-transform: translateY(50%);
        .legend-title {
            color: #aaa;
            font-size: 10px;
            white-space: nowrap;
            -webkit-transform: rotate(270deg);
            -moz-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
            -o-transform: rotate(270deg);
            transform: rotate(270deg);
            text-align: center;
        }
    }
}

.score-legend {
  background-image: linear-gradient(to top, $bm-start-color 0%, $bm-mid-color 51%, $bm-end-color 100%);
  width: 5px;
  height: 100px;
}

svg.overlay-svg {
    max-width: 100%;
    height: auto;
    path {
        fill: $bm-start-color;
        fill-opacity: 0;
        transition: all 1s ease;
        @for $i from 0 through 100 {
            &.score-percentile-#{$i} {
                fill-opacity: 0.65;
                @if $i < 50 {
                    fill: mix($bm-start-color,$bm-mid-color,100 - ($i/50.0)*100)
                } @else {
                    fill: mix($bm-mid-color,$bm-end-color,100 - (($i - 50)/50.0)*100)
                }
            }
        }
    }
}

.body-scan-line {
    fill: url(#scanGradient);
}

.circle-check {
    position: relative;
    display: inline-block;
    height: 15px;
    width: 15px;
    min-width: 15px;
    border-radius: 50%;
    background-color: #ffffff00;
    margin-left: 3px;
    margin-right: 3px;
    border: 2px solid #ffffff88;
    vertical-align: middle;
    svg {
        font-size: 11px;
        top: -5.5px;
        position: relative;
    }
    &.logged {
        background-color: #fff;
    }
}

@media #{$large-and-up} {
	.circle-check-cont:not(.no-upsizing) {
        display: block;
        .circle-check {
            height: 30px;
            width: 30px;
            min-width: 30px;
            svg {
                font-size: 20px;
                top: 3px;
                left: 3px;
                position: relative;
            }
        }
    }
}

.circle-check-cont {
    display: inline-block;
}

.q-icon {
    position: absolute;
    font-size: 11px;
    top: -2.5px;
    right: -11px;
}

.sidenav-logo-cont {
    position: relative;
    min-height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    label {
        display: block;
        flex-grow: 1;
        line-height: 0;
        text-align: center;
    }
    img {
        display: inline-block;
        max-width: 70%;
        max-height: 70px;
        height: auto;
        width: auto;
    }
}


.last-active-tag {
    display: inline-block;
    font-size: 10px;
    color: color("green","base");
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 8px;
    border: 1px solid color("green","base");
    opacity: 0.75;
    &.yt {
        color: color("yellow","darken-2");
        border: 1px solid color("yellow","darken-2");
    }
    &.ot {
        color: color("orange","base");
        border: 1px solid color("orange","base");
    }
    &.rt {
        color: color("red","base");
        border: 1px solid color("red","base");
    }
    &.pt {
        color: color("purple","base");
        border: 1px solid color("purple","base");
    }
    &.pt {
        color: color("purple","base");
        border: 1px solid color("purple","base");
    }
    &.bt {
        color: color("blue","base");
        border: 1px solid color("blue","base");
    }
    &.gt {
        color: #666;
        border: 1px solid #666;
    }
}

.cli-tag-cont {
    position: absolute;
    right: 5px;
    top: -13px;
    &.lc {
        top: 0;
    }
}

@mixin discount-tag {
    font-size: 10px;
    color: color("red","base");
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 8px;
    border: 1px solid color("red","base");
}

.discount-tag {
    position: absolute;
    right: 10px;
    bottom: 10px;
    @include discount-tag();
}

.discount-tag-unpos {
    @include discount-tag();
    display: inline-block;
}

.annual-card {
    .discount-tag {
        border-color: white;
        color: white;
    }
    del.price-slash {
        color: rgba(white,0.65);
    }
}

.discount-card {
    .discount-tag {
        right: unset;
        left: 10px;
    }
}

del.price-slash {
    color: rgba(black,0.65);
    text-decoration: none;
    position: relative;
    font-weight: 300;
    &:before {
      content: " ";
      display: block;
      width: 100%;
      border-top: 2px solid rgba(red, 0.8);
      height: 0.7em;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(-15deg);
    }
}

.color-select-btn {
    min-width: 40px;
    height: 30px;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    margin: 2px;
    padding: 3px;
    vertical-align: middle;
}

.show-overflow {
    overflow: visible !important;
}

.branded-app-icon {
    background-color: $primary-color;
    border-radius: 5px;
    &.circle {
        border-radius: 50%;
    }
    max-width: 100%;
    max-height: 180px;
}

video.mux-vid {
    border-radius: 7.5px;
}

.signature-cont {
    position: relative;
    svg {
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: #999;
    }
}