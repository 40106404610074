// shared styles
.btn,
.btn-flat {
  border: $button-border;
  border-radius: $button-radius;
  display: inline-block;
  &.block-btn {
    display: block;
  }

  &.flow-answer-btn {
    display: flex;
    justify-content: center;
    margin: 10px 3px;
    text-align: center;
    text-transform: none;
    box-shadow: none !important;
    height: auto;
    min-height: 50px;
    line-height: 1.3;
    padding-top: 8px;
    padding-bottom: 8px;
    .btn-content {
      white-space: wrap;
      font-size: 18px;
      text-align: center;
      height: auto;
    }
  }
  height: $button-height;
  line-height: $button-height;
  padding: $button-padding;
  text-transform: capitalize;
  &.no-upcase, &.no-capital {
    text-transform: none;
  }
  &.underline {
    text-decoration: underline;
  }
  &.card-radius {
    border-radius: $card-border-radius;
  }
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent; // Gets rid of tap active state
  &.insert-type-cards {
    text-transform: none;
    color: color("grey","lighten-1");
    margin-top: 118px;
    margin-left: 10px;
    margin-right: 15px;
    font-size: 15px;
    i, svg {
      font-size: 13px;
    }
  }
  &.me-heart-style {
    padding: 0 5px;
    i, svg {
      font-size: 33px;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  &.green-style {
    color: $primary-color;
  }
  &.valsub {
    svg {
      vertical-align: sub;
    }
  }
}

.btn-over-btn-right {
  position: absolute;
  width: 40px;
  height: 100%;
  cursor: pointer;
  right: 4px;
  top: 0;
  z-index: 999;
  svg {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

// Disabled shared style
.btn.disabled,
.btn-floating.disabled,
.btn-large.disabled,
.btn-small.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-flat:disabled,
.btn[disabled],
.btn-floating[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-flat[disabled] {
  pointer-events: none;
  background-color: $button-disabled-background !important;
  box-shadow: none;
  color: $button-disabled-color !important;
  cursor: default;
  &:hover {
    background-color: $button-disabled-background !important;
    color: $button-disabled-color !important;
  }
}

// Shared icon styles
.btn,
.btn-floating,
.btn-large,
.btn-small,
.btn-flat {
  font-size: $button-font-size;
  outline: 0;
  i, svg {
    font-size: $button-icon-font-size;
    line-height: inherit;
  }
}

// Shared focus button style
.btn,
.btn-floating {
  &:focus {
    background-color: $button-raised-focus-color;
  }
}

// Raised Button
.btn {
  text-decoration: none;
  color: $button-raised-color;
  background-color: $button-raised-background;
  text-align: center;
  letter-spacing: .5px;
  @extend .z-depth-1;
  transition: background-color .2s ease-out transform .2s ease-out;
  cursor: pointer;
  &:hover {
    background-color: $button-raised-background-hover;
    @extend .z-depth-1-half;
  }
  &:active {
    transform: scale(0.95);
  }
}

.btn.btn-round-icon, .btn.btn-icon-only {
    position: relative;
    width: $button-floating-size;
    padding: 0;
    svg {
        position: absolute;
        font-size: 18px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.btn.btn-round-icon {
  height: $button-floating-size;
  line-height: $button-floating-size;
  border-radius: 50%;
}

// Floating button
.btn-floating {
  &:hover {
    background-color: $button-floating-background-hover;
    @extend .z-depth-1-half;
  }
  &:before {
    border-radius: 0;
  }
  &.btn-large {
    &.halfway-fab {
      bottom: -$button-floating-large-size / 2;
    }
    width: $button-floating-large-size;
    height: $button-floating-large-size;
    padding: 0;
    i, svg {
      line-height: $button-floating-large-size;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      &.fa-spin {
        top: 28%;
        left: 28%;
        transform: none;
      }
    }
  }

  &.btn-small {
    &.halfway-fab {
      bottom: -$button-floating-small-size / 2;
    }
    width: $button-floating-small-size;
    height: $button-floating-small-size;
    i {
      line-height: $button-floating-small-size;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &.white-grey {
    background-color: white;
  
    i, svg {
      color: $font-grey;
    }
  }

  &.halfway-fab {
    &.left {
      right: auto;
      left: 24px;
    }
    position: absolute;
    right: 24px;
    bottom: -$button-floating-size / 2;
  }
  display: inline-block;
  color: $button-floating-color;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: $button-floating-size;
  height: $button-floating-size;
  line-height: $button-floating-size;
  padding: 0;
  background-color: $button-floating-background;
  border-radius: $button-floating-radius;
  @extend .z-depth-1;
  &.btn-outlined.btn-primary {
    i, svg {
      color: $primary-color;
    }
  }
  transition: background-color .3s;
  cursor: pointer;
  vertical-align: middle;
  i, svg {
    width: inherit;
    display: inline-block;
    text-align: center;
    color: $button-floating-color;
    font-size: $button-large-icon-font-size;
    line-height: $button-floating-size;
  }
  img {
    width: 23px;
    margin-top: 7px;
  }
  
  &.grey-floating {
  background-color: color("grey","lighten-4");
  height: 38px;
  width: 38px;
  box-shadow: none;
    i, svg {
      color: $font-grey;
      font-size: 14px;
    }
  }

  &.transparent-floating {
    background-color:rgba(242, 242, 242, .55);
    height: 38px;
    width: 38px;
    box-shadow: none;
      i, svg {
        color: $font-grey;
        font-size: 18px;
      }
  }
}

// button fix
button.btn-floating {
  border: $button-border;
}

// Fixed Action Button
.fixed-action-btn {
  &.active {
    ul {
      visibility: visible;
    }
  }

  // Directions
  &.direction-left,
  &.direction-right {
    padding: 0 0 0 15px;
    ul {
      text-align: right;
      right: 64px;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      left: auto;
      /*width 100% only goes to width of button container */
      width: 500px;
      li {
        display: inline-block;
        margin: 7.5px 15px 0 0;
      }
    }
  }
  &.direction-right {
    padding: 0 15px 0 0;
    ul {
      text-align: left;
      direction: rtl;
      left: 64px;
      right: auto;
      li {
        margin: 7.5px 0 0 15px;
      }
    }
  }
  &.direction-bottom {
    padding: 0 0 15px 0;
    ul {
      top: 64px;
      bottom: auto;
      display: flex;
      flex-direction: column-reverse;
      li {
        margin: 15px 0 0 0;
      }
    }
  }
  &.toolbar {
    &.active {
      &>a i {
        opacity: 0;
      }
    }
    padding: 0;
    height: $button-floating-large-size;
    ul {
      display: flex;
      top: 0;
      bottom: 0;
      z-index: 1;
      li {
        flex: 1;
        display: inline-block;
        margin: 0;
        height: 100%;
        transition: none;
        a {
          display: block;
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 100%;
          background-color: transparent;
          box-shadow: none;
          color: color("shades", "white");
          line-height: $button-floating-large-size;
          z-index: 1;
          i {
            line-height: inherit;
          }
        }
      }
    }
  }
  position: fixed;
  right: 23px;
  bottom: 53px;
  @supports (bottom: env(safe-area-inset-bottom)) {
    bottom: calc(env(safe-area-inset-bottom) + 53px);
  }
  padding-top: 15px;
  margin-bottom: 0;
  z-index: 997;
  ul {
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: 64px;
    margin: 0;
    visibility: hidden;
    li {
      padding-bottom: 25px;
    }
    a.btn-floating {
      opacity: 0;
    }
  }
  .fab-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: $button-floating-size;
    height: $button-floating-size;
    background-color: $button-floating-background;
    border-radius: $button-floating-radius;
    transform: scale(0);
  }
}

// Flat button
.btn-flat {
  box-shadow: none;
  background-color: transparent;
  color: $button-flat-color;
  cursor: pointer;
  transition: background-color .2s;
  &:focus,
  &:hover {
    box-shadow: none;
  }
  &:focus {
    background-color: rgba(0, 0, 0, .1);
  }
  &.disabled,
  &.btn-flat[disabled]  {
    background-color: transparent !important;
    color: $button-flat-disabled-color !important;
    cursor: default;
  }
}

.btn.btn-no-shadow {
    box-shadow: none !important;
    &:focus,
    &:hover {
        box-shadow: none !important;
    }
}

.default-modal-footer, .modal-footer {
  .btn {
    box-shadow: none;
    &:focus,
    &:hover {
        box-shadow: none;
    }
  }
}

// Large button
.btn-large {
  @extend .btn;
  height: $button-large-height;
  line-height: $button-large-height;
  font-size: $button-large-font-size;
  padding: 0 28px;

  i,svg {
    font-size: $button-large-icon-font-size;
  }
}

// Small button
.btn-small {
  @extend .btn;
  height: $button-small-height;
  line-height: $button-small-height;
  font-size: $button-small-font-size;
  i {
    font-size: $button-small-icon-font-size;
  }
}

.btn.btn-outlined {

    background-color: color("shades", "white");
    &:not(.shadow) {
      box-shadow: none !important;
    }
    @each $color_name, $color in $colors {
        @each $color_type, $color_value in $color {
          @if $color_type == "base" {
            &.btn-#{$color_name} {
              color: $color_value;
              &.no-border {
                border: none;
              }
              border: 1px solid $color_value;
              &:hover,&:active {
                @if $color_name == "primary" {
                  background-color: $primary-very-light;
                } @else {
                  background-color: lighten($color_value,40%);
                }
              }
            }
          }
          @else if $color_name != "shades" {
            &.btn.btn-#{$color_name}.#{$color_type} {
                color: $color_value;
                border: 1px solid $color_value;
                &:hover {
                    background-color: lighten($color_value,80%);
                }
            }
          }
        }
      }
    
}

.btn.btn-rounded {
    border-radius: 6px;
}

.btn.btn-green {
	background-color: $primary-color;
}

.btn.btn-select {
  background-color: color("shades", "white");
  border: 1px solid color("grey", "base");
  font-weight: 300;
  color: $font-grey;
  text-transform: none;
  margin: 10px 10px 10px 10px;
}

.btn.btn-select.active {
  background-color: $primary-color;
  color: color("shades", "white");
  font-weight: 500;
  border: none;
  
  &:hover {
    background: $primary-slightly-light;
  }
}

.btn.btn-wide {
    width: $button-width-wide;
    text-align: center;
}

.btn.btn-extra-wide {
    width: 200px;
}

.btn.btn-full-width {
  width: 100%;
  max-width: calc(min(#{$button-width-full},100vw - 16px));
  text-align: center;
}

.bottom-center-action-container {
  .btn {
    height: 50px;
    line-height: 50px;
  }
}

.btn.btn-auto {
  width: 100px;
  min-width: 80px;
}

.bottom-center-action-container {
  display: flex;
}

.btn.btn-bottom-center, .bottom-center-action-container {
    bottom: 20px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;
    @media #{$medium-and-down} {
      &.has-bot-nav {
        bottom: $bottom-nav-height + 25px;
      }
    }
  }

.btn-bottom-right {
    bottom: 25px;
    position: fixed;
    right: 25px;
    @media #{$extra-small-and-down} {
      right: 20px;
    }
}

.btn-bottom-left {
  bottom: 25px;
  position: fixed;
  left: 25px;
  @media #{$extra-small-and-down} {
    left: 20px;
  }
}

// Block button
.btn.btn-block, .btn-flat.btn-block {
  width: 100%;
}

.btn {
	.btn-content {
		display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;

		span {
			flex-grow: 1;
		}
		svg, i {
			margin-right: 10px;
		}
  }
  .btn-content-2 {
    line-height: 34px;
    align-items: center;
    i {
      font-size: 13px;
    }
  }
}

.btn.google-sign-up {
		border: 1px solid $font-grey;
		background: $button-google-color;
		width: 300px;
		border-radius: 20px;
		color: $font-grey;
		display: block;
		margin-bottom: $button-sign-up-margin;
		margin-top: 20px;
		&:hover {
			background-color: $button-google-color-hover;
			@extend .z-depth-1-half;
		  }
	}

#google-logo, #facebook-logo, #email-logo {
	height: $button-height - 10;
}

.btn.facebook-sign-up {
		background:  $button-facebook-color;
		width: 300px;
		border-radius: 20px;
		color: color("shades", "white");
		display: block;
		margin-bottom: $button-sign-up-margin;
		&:hover {
			background-color: $button-facebook-color-hover;
			@extend .z-depth-1-half;
		  }
	}

.btn.email-sign-up {
		background: $button-email-color;
		width: 300px;
		border-radius: 20px;
		color: color("shades", "white");
		display: block;
		margin-bottom: $button-sign-up-margin;
		&:hover {
   	      background-color: $button-email-color-hover;
   		  @extend .z-depth-1-half;
		  }
	}


@each $color_name, $color in $colors {
    @each $color_type, $color_value in $color {
      @if $color_type == "base" {
        .btn.btn-#{$color_name}:not(.btn-flat):not(.btn-outlined), .btn-floating.btn-#{$color_name}:not(.btn-flat):not(.btn-outlined) {
          background-color: $color_value;
          &:hover {
            @if $color_name == "primary" {
              background-color: $primary-slightly-light;
            } @else {
              background-color: lighten($color_value,5%);
            }
          }
        }
      }
      @else if $color_name != "shades" {
        .btn.btn-#{$color_name}.#{$color_type}:not(.btn-flat), .btn-floating.btn-#{$color_name}.#{$color_type}:not(.btn-flat) {
          background-color: $color_value;
          &:hover {
            background-color: lighten($color_value,5%);
          }
        }
      }
    }
  }

.btn.btn-toggle {
	font-size: $button-toggle-font-size;
	background: $button-toggle-background;
	border: $button-toggle-border;
	height: $button-toggle-height;
	border-radius: $button-toggle-radius;
	
	.btn-toggle-content {
		div {
            padding-left: 5px;
            padding-right: 5px;
            &:not(:last-child) {
                border-right: 1px solid $font-grey;
            } 
            flex-grow: 1;
            &.active {
                font-weight: bold;
                color: $primary-color;
            }
		}
	  color: $font-grey;
	  font-size: 12px;
	  font-family: $font-stack;
      font-weight: 300;
      line-height: 2.3em;
	  display: flex;
	}
}

.button-line {
  position: relative;
  display: inline;
  border-left: 1px solid $font-grey;
  padding: 5px 5px 7px 0px;
  margin: 4px;
}

//Meal Plan View Toggle 

.btn-toggle-2-container {
  display: inline-flex;
  border-radius: 6px;
  overflow: hidden;
  @extend .z-depth-1;
  transition: background-color .2s ease-out transform .2s ease-out;
  cursor: pointer;
  border-color: $primary-color-light;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  &:hover {
    background-color: $button-raised-background-hover;
    @extend .z-depth-1-half;
  }
  &:active {
    transform: scale(0.95);
  }
  &.nav {
    border-color: $primary-color;
  }
  @media #{$simple-nav-btn-drop} {
    &.simple-nav-resp {
      position: fixed;
      top: calc(var(--sat) + #{$simple-nav-height-num+2}px);
      right: 2px;
    }
  }

  &.dark-mode {
    border-color: rgba(0, 0, 0, .2);
    &:hover {
      background-color: darken($font-grey, 5%);
      @extend .z-depth-1-half;
    }
    .btn-toggle-2 {
      background: rgba(255,255,255,.80);
      color: rgba(0,0,0,.4);
      &.active {
        background: rgba(255,255,255,.95);
        color: $font-grey;
      }
      &.divider-right {
        border-right:rgba(0, 0, 0, .2);;
      }
    }
  }
}

.btn-toggle-2 {
  background: #E7EDE8;
  color: $primary-color-light;
  font-size: 18px;
  text-transform: capitalize;
  padding-left: 8px;
  padding-right: 8px;
  line-height: $button-height;
  &.active {
    color: $primary-color;
    background-color: rgba(255, 255, 255, 0.99);
  }
  &.nav {
    background: rgba(255, 255, 255, 0.6);
    color: #71B672;
  }
  &.divider-right {
    border-right: 1px solid rgba(105,174,110,.4);
  }
}

// Allergy & Setting buttons

.btn {
  &.btn-allergy {
    border-radius: $btn-border-radius;
    background: color("shades", "white");
    color: $font-grey;
    font-weight: 300;
    border: 1px solid $font-grey; 
    margin: 5px 6px 10px 5px;
    &.compact {
      margin: 2px;
      text-transform: none;
    }
    
    &.bottom-spacing {
      text-transform: none;
      margin-bottom: 10px;
    }

    &.no-capital {
      text-transform: none;
    }
  
    &:hover {
      background:darken(color("shades", "white"), 5%);
    }
  }

  &.btn-allergy.active {
    background: $primary-color;
    color: color("shades", "white");
    font-weight: 500;
  
    &:hover {
      background: $primary-slightly-light;
    }
  }

  &.btn-allergy.active2 {
    background: $primary-very-light;
    font-weight: 500;
  }

  &.btn-square-icon {
    padding: 0;
    .btn-content {
      justify-content: center;
      svg {
        margin: 0;
      }
    }
  }

  .btn-subtitle {
    font-size: 10px;
    text-transform: lowercase;
  }

  &.btn-setting {
    border-radius: $btn-border-radius;
    background: color("shades", "white");
    color: $font-grey;
    font-weight: 300;
    border: 1px solid $font-grey; 
    margin: 5px 6px 10px 5px;
    text-transform: capitalize;
  
    &:hover {
      background:darken(color("shades", "white"), 5%);
    }
  }

  &.btn-setting.active {
    background: $primary-color;
    color: color("shades", "white");
    font-weight: 500;
  
    &:hover {
      background: $primary-slightly-light;
    }
  }

  &.btn-setting2 {
    height: 25px;
    width: 50px;
    line-height: 22px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 18px;
    font-size: 13px;
    background: color("shades", "white");
    color: $font-grey;
    font-weight: 300;
    border: 1px solid $font-grey; 
    text-transform: capitalize;
    margin: 8px auto;

    @media #{$extra-small-and-down} {
      width: 40px;
    }

    @media #{$medium-only} {
      width: 60px;
    }

    @media #{$large-and-up} {
      width: 40px;
      flex-grow: .1;
    }
  
    &:hover {
      background:darken(color("shades", "white"), 5%);
    }
  }

  &.secondary-btn {
    margin: -5px 10px 5px auto;
    border-radius: 10px;
    color: $primary-color;
    background-color: white;
    border: 1px solid $primary-color;
    font-weight: 400;
    text-transform: capitalize;
    display: block;
    width: fit-content;
  }

  &.btn-setting2.active {
    background: $primary-color;
    color: color("shades", "white");
    font-weight: 500;
    border: none;
    padding-top: 1px;
  
    &:hover {
      background: $primary-slightly-light;
    }
  }
  
}

//Today Page Card Buttons
.today-page-setup {
  position: absolute;
  &.waves-effect {
      position: absolute;
  }
  left: 7%;
  bottom: 12%;
}

.image-card-note {
  position: absolute;
  left: 3%;
  bottom: 12%;
  width: 40%;
}

.btn.btn-meal-plan-setup {
	background-color: color("yellow", "darken-3");
}

.btn.btn-workout-setup{
	background-color: color("light-blue", "darken-3");
}

.btn.white, .btn.btn-white {
    background-color: white;
    color: $font-grey;
    text-transform: capitalize;
    font-weight: 300;
    &:hover {
        background-color: #eee; 
    }
}

.wo-wt-yes-no-btns {
  background-color: white;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 15px;
  border-radius: 5px;
  
  &.yes {
    border: 1px solid $primary-color;
    color: $primary-color;
  }

  &.no {
    border: 1px solid $font-grey;
    color: $font-grey;
  }
}

//Meal Plan Page
.btn.semitransparent {
  background-color: rgba(255, 255, 255, .2);
  border: 1px solid white;
  color: white;
  border-radius: 6px;
  text-transform: none;
  padding: 0 10px;
  line-height: 32px;
  margin-bottom: 11px;
  margin-left:5px;
  padding: 0px 5px;
  font-size: 12px;
  i {
    font-size: 14px;
  }
  &.dark {
    color: $header-color;
    border: 1px solid color("grey","lighten-1");
    background-color: rgba(255, 255, 255, .5);
  }
}

//Meal Editor
.btn.btn-outlined-2 {
  background-color: white;
  border-radius: 5px;
  border: 1px solid $primary-color;
  color: $primary-color;
  padding: 0px 5px;
  text-transform: capitalize;
  line-height: 30px;

  &.add-food {
    width: 35%;
    i {
      font-size: 16px;
      margin-top: 2px;
    }
  }

  &.randomize-sides {
    width: 65%;
    margin-right: 7px;
    i {
      font-size: 16px;
      margin-top: 2px;
    }
  }
  
}

.date-gradient-btn {
  border-radius: 50px;
  background: rgb(85,184,106);
  background: linear-gradient(90deg, rgba(85,184,106,1) 0%, rgba(133,208,128,1) 100%);  
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: inline-block;
}


.btn.semitransparent-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(242, 242, 242, .55);
  color: $font-grey;
  padding: 0px;
  line-height: 40px;
  &.arrows-hide {
    position: absolute;
    top: 150px;
    left: -75px;
    background-color: color("grey","lighten-3");
    color: $font-grey;
    @media #{$rc-small-and-down} {
      display: none !important;
    }
    @media #{$rc-medium-and-up} {
      left: 10px;
      z-index: 10;
    }
    @media #{$arrows-wide} {
      left: -75px;
    }
    &.right {
      right: -75px;
      left: auto;
      @media #{$rc-medium-and-up} {
        right: 10px;
        z-index: 10;
      }
      @media #{$arrows-wide} {
        right: -75px;
      }
    
    }
}

.btn.block-small.with-margins {
  margin-left: 5px;
}

@media #{$small-and-down} {
  .btn.block-small {
    display: block;
  }
  .btn.block-small.with-margins {
    margin-left: 0;
    margin-top: 5px;
  }
}

}


//Swap Btns
.btn.swap-btn {
  height: 50px;
  width: 100%;
  border-radius: 30px;
  background-color: color("grey","lighten-3");
  color: $font-grey;
  position: relative;
  margin: 4px auto 4px auto;
  box-shadow: none;

  &.active {
		border: 1px solid $primary-color;
		background-color: #F6FBF7;  }
}

@mixin transparent-btn-text {
  color: white;
  font-size: 18px;
  line-height: 32px;
  font-family: $font-alt;
}

.transparent-btn-text {
  @include transparent-btn-text;
}

//transparent- Today Page
.btn.transparent-btn {
  background-color: transparent;
  border: 1px solid rgba(255,255,255,.33);
  min-width: 180px;
  @include transparent-btn-text;
  @media #{$medium-and-up} {
    min-width: 260px;
  }

  i, svg {
    font-size: 14px;
    margin-right: 10px;
  }
}

.btn.btn-group-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none !important;
}

.btn.btn-group-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn.btn-group-mid {
  border-radius: 0;
  border-right: none !important;
}

