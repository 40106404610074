.modal {
  &:focus {
    outline: none;
  }

  @extend .z-depth-5;

  display: none;
  position: absolute;
  &.fixed-modal {
    position: fixed;
  }
  left: 0;
  right: 0;
  background-color: #fafafa;
  padding: 0;
  width: 55%;
  margin: auto;
  overflow-y: auto;
  &.no-overflow {
    overflow-y: hidden;
  }

  border-radius: $card-border-radius;
  will-change: opacity,transform;

  @media #{$medium-and-down} {
   width: 80%;
  }

  &.full-width {
    @media #{$small-and-down} {
        width: 100%;
    }
  }

  &.limit-width {
      max-width: 500px;
  }

  &.scrollable-content.has-collapse {
    overflow-y: hidden;
  }

  &.scrollable-content, &.full-height {
      max-height: none;

      .scroll-modal-footer, .scroll-modal-header {
          background-color: white;
          text-align: center;
          position: relative;
          z-index: 1;
          &:not(.no-shadow) {
            @extend .elevation-3-dp;
          }
      }

      .scroll-modal-header {
        border-top: 1px solid #fff;
      }

      .scroll-modal-footer {
        border-bottom: 1px solid #fff;
      }
    &.height-min {
      min-height: 350px;
    }
  }


  h1,h2,h3,h4 {
    margin-top: 0;
  }

  .modal-content {
    padding: 24px;
  }
  .modal-close {
    cursor: pointer;
  }

  .default-modal-header {
      text-align: center;
      padding: 20px 20px 10px 20px;
      font-weight: 500;
      font-size: 18px;
      color: $font-grey;
      border-bottom: 1px solid $icon-low-opacity;
      .subtitle {
          font-size: 12px;
          color: $icon-low-opacity;
      }
  }

  .default-modal-header-font {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: $font-grey;
    .subtitle {
        font-size: 12px;
        color: $icon-low-opacity;
    }
  }

  .default-modal-footer {
    text-align: center;
    padding: 10px;
    border-top: 1px solid $icon-low-opacity;
  }

  .modal-footer {
    border-radius: 0 0 2px 2px;
    background-color: #fafafa;
    padding: 4px 6px;
    height: 56px;
    width: 100%;
    text-align: right;

    .btn, .btn-flat {
      margin: 6px 0;
    }
  }
}
.modal-overlay {
  position: fixed;
  z-index: 999;
  top: -25%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;

  will-change: opacity;
}

// Modal with fixed action footer
.modal.modal-fixed-footer {
  padding: 0;
  height: 70%;

  .modal-content {
    position: absolute;
    height: calc(100% - 56px);
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .modal-footer {
    border-top: 1px solid rgba(0,0,0,.1);
    position: absolute;
    bottom: 0;
  }
}

// Modal Bottom Sheet Style
.modal.bottom-sheet {
  top: auto;
  bottom: -100%;
  margin: 0;
  width: 100%;
  max-height: 45%;
  border-radius: 0;
  will-change: bottom, opacity;
}

.modal-close-btn, .upper-right-btn {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 22px;
    padding-left: 20px;
    cursor: pointer;
}

body.modal-open {
  .app-scroll-container {
    overflow: hidden;
  }
}