//Default styles

html {
 box-sizing: border-box;
}
*, *:before, *:after {
 box-sizing: inherit;
}

@mixin thin-subtitle {
    font-family: $font-stack;
    font-weight: 300;
    color: $card-background-light;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-stack;
}

ul {
  &:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;

    & > li {
      list-style-type: none;
    }
  }
}

a {
	color: $link-color;
	text-decoration: none;

  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;
}


// Positioning
.valign-wrapper {
  display: flex;
  align-items: center;

  @media(max-width: 600px) {
    &.resp {
      display: block;
    }
  }

  &.cntr {
    justify-content: center;
  }

  &.left {
    justify-content: flex-end;
  }

  &.spbt {
    justify-content: space-between;
  }

  &.flex-end {
    align-items: flex-end;
  }
  &.flex-start {
    align-items: flex-start;
  }
}

.or-div {
  width: 100px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}


// classic clearfix
.clearfix {
  clear: both;
}

@mixin abbr-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.abbr-text {
  @include abbr-text;
}

// Z-levels
.z-depth-0 {
  box-shadow: none !important;
}

/* 2dp elevation modified*/
.z-depth-1 {
  @extend .elevation-2-dp;
}

.z-depth-1-half {
    @extend .elevation-4-dp;
}

/* 6dp elevation modified*/
.z-depth-2 {
    @extend .elevation-6-dp;
}

/* 12dp elevation modified*/
.z-depth-3 {
    @extend .elevation-12-dp;
}

/* 16dp elevation */
.z-depth-4 {
    @extend .elevation-16-dp;
}

/* 24dp elevation */
.z-depth-5 {
    @extend .elevation-24-dp;
}

.hoverable {
  transition: box-shadow .25s;

  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

// Dividers

.divider {
  height: 1px;
  overflow: hidden;
  background-color: color("grey", "lighten-3");
}


//  Blockquote

blockquote {
  margin: 20px 0;
  padding-left: 1.5rem;
  border-left: 5px solid $primary-color;
}

// Icon Styles

i {
  line-height: inherit;

  &.left {
    float: left;
    margin-right: 15px;
  }
  &.right {
    float: right;
    margin-left: 15px;
  }
  &.tiny {
    font-size: 1rem;
  }
  &.small {
    font-size: 2rem;
  }
  &.medium {
    font-size: 4rem;
  }
  &.large {
    font-size: 6rem;
  }
}

// Images
img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: auto;
}

img.meal-image-today {
  width: 130px;
  border-radius: 5px;
}


// Pagination

.pagination {

  li {
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
    height: 30px;

    a {
      color: #444;
      display: inline-block;
      font-size: 1.2rem;
      padding: 0 10px;
      line-height: 30px;
    }

    &.active a { color: #fff; }

    &.active { background-color: $primary-color; }

    &.disabled a {
      cursor: default;
      color: #999;
    }

    i {
      font-size: 2rem;
    }
  }


  li.pages ul li {
    display: inline-block;
    float: none;
  }
}
@media #{$medium-and-down} {
  .pagination {
    width: 100%;

    li.prev,
    li.next {
      width: 10%;
    }

    li.pages {
      width: 80%;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

// Breadcrumbs
.breadcrumb {
  font-size: 18px;
  color: rgba(255,255,255, .7);

  i,
  [class^="mdi-"], [class*="mdi-"],
  i.material-icons {
    display: inline-block;
    font-size: 24px;
  }

  &:before {
    content: '\E5CC';
    color: rgba(255,255,255, .7);
    vertical-align: top;
    display: inline-block;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 25px;
    margin: 0 10px 0 8px;
    -webkit-font-smoothing: antialiased;
  }

  &:first-child:before {
    display: none;
  }

  &:last-child {
    color: #fff;
  }
}

// Parallax
.parallax-container {
  position: relative;
  overflow: hidden;
  height: 500px;

  .parallax {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    img {
      opacity: 0;
      position: absolute;
      left: 50%;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      transform: translate3d(0,0,0);
      transform: translateX(-50%);
    }
  }
}

// Pushpin
.pin-top, .pin-bottom {
  position: relative;
}
.pinned {
  position: fixed !important;
}

/*********************
  Transition Classes
**********************/

ul.staggered-list li {
  opacity: 0;
}

.fade-in {
  opacity: 0;
	transition: opacity 300ms ease-in;
}

.fade-in.in {
  opacity: 1;
}


/*********************
  Media Query Classes
**********************/
.hide-on-short {
  @media #{$short-screen-only} {
    display: none !important;
  }
}
.hide-on-small-only, .hide-on-small-and-down {
  @media #{$small-and-down} {
    display: none !important;
  }
}
.hide-on-med-and-down {
  @media #{$medium-and-down} {
    display: none !important;
  }
}
.hide-on-med-and-up {
  @media #{$medium-and-up} {
    display: none !important;
  }
}
.hide-on-med-only {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: none !important;
  }
}
.hide-on-large-only {
  @media #{$large-and-up} {
    display: none !important;
  }
}
.hide-on-extra-large-only {
  @media #{$extra-large-and-up} {
    display: none !important;
  }
}
.show-on-extra-large {
  @media #{$extra-large-and-up} {
    display: block !important;
  }
}
.show-on-large {
  @media #{$large-and-up} {
    display: block !important;
  }
}
.show-on-medium {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: block !important;
  }
}
.show-on-small {
  @media #{$small-and-down} {
    display: block !important;
  }
}
.show-on-medium-and-up {
  @media #{$medium-and-up} {
    display: block !important;
  }
}
.show-on-medium-and-down {
  @media #{$medium-and-down} {
    display: block !important;
  }
}

.hide-on-med-sm-and-down {
  @media #{$med-small-and-down} {
    display: none !important;
  }
}

.hide-on-med-sm-up {
  @media #{$med-small-up} {
    display: none !important;
  }
}


// Center text on mobile
.center-on-small-only {
  @media #{$small-and-down} {
    text-align: center;
  }
}

// Footer
.page-footer {
  padding-top: 20px;
  color: $footer-font-color;
  background-color: $footer-bg-color;

  .footer-copyright {
    overflow: hidden;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    color: $footer-copyright-font-color;
    background-color: $footer-copyright-bg-color;
  }
}

@mixin table-title {
    font-weight: 300;
    color: color("grey", "mid-point");
    font-size: 15px;
}

@mixin meal-plan-icon {
  color: color("grey","base");
}

@mixin two-line-ellipsis {
  line-height: 1.2;
  max-height: 2.4em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

// Tables
table, th, td {
   border: none;

   .title {
       @include table-title;
   }

   .table-padding-small {
     padding: 5px;
   }
}

table {
  width:100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;

  &.striped {
    tr {
      border-bottom: none;
    }

    > tbody {
      > tr:nth-child(odd) {
        background-color: $table-striped-color;
      }
      > tr:nth-child(even) {
        background-color: $table-striped-even-color;
      }

      > tr > td {
        border-radius: 0;
      }
    }
  }

  &.feature-comparison {
      th, td {
          @include table-title;
          padding: 5px;
      }
  }

  &.highlight > tbody > tr {
    transition: background-color .25s ease;
    &:hover {
      background-color: $table-striped-color;
    }
  }

  &.centered {
    thead tr th, tbody tr td {
      text-align: center;
    }
  }

  &.food-log-table {
      tbody {
          tr {
              td {
                    @include table-title;
                    padding: 3px;
                    &.x-padding {
                        padding-top: 6px;
                        padding-bottom: 7px;
                        padding-left: 10px;
                        padding-right: 11px;
                        text-align: center;
                    }
                    &.calorie-text {
                        color: $protein-color;
                    }
                    
                    &.protein-text {
                        color: $protein-color;
                    }
                    
                    &.fat-text {
                        color: $fat-color;
                    }
                    
                    &.carbs-text {
                        color: $carb-color;
                    }
                    
                    &.logged-title-text {
                        color: $header-color;
                        white-space: nowrap;
                        max-width: 80px;
                        overflow: hidden;
                    }
              }

              &.totals {
                border-bottom: none;
                &:not(.normal-height) {
                  height: 80px;
                }
                td {
                  font-weight: 600;
                  vertical-align: bottom;
                }
              }
          }
      }
  }
  &.inactive {
		background-color: color("grey", "lighten-3" );
	  }
}

tr {
  border-bottom: 1px solid $table-border-color;

  &.inactive {
		background-color: color("grey", "lighten-3" );
	  }
}

td, th{
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
}

// Responsive Table
@media #{$medium-and-down} {

  table.responsive-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    position: relative;

    td:empty:before {
      content: '\00a0';
    }

    th,
    td {
      margin: 0;
      vertical-align: top;
    }

    th { text-align: left; }
    thead {
      display: block;
      float: left;

      tr {
        display: block;
        padding: 0 10px 0 0;

        th::before {
          content: "\00a0";
        }
      }
    }
    tbody {
      display: block;
      width: auto;
      position: relative;
      overflow-x: auto;
      white-space: nowrap;

      tr {
        display: inline-block;
        vertical-align: top;
      }
    }
    th {
      display: block;
      text-align: right;
    }
    td {
      display: block;
      min-height: 1.25em;
      text-align: left;
    }
    tr {
      border-bottom: none;
      padding: 0 10px;
    }

    /* sort out borders */
    thead {
      border: 0;
      border-right: 1px solid $table-border-color;
    }
  }

}


// Collections
.collection {
  margin: $element-top-margin 0 $element-bottom-margin 0;
  border: 1px solid $collection-border-color;
  border-radius: 2px;
  overflow: hidden;
  position: relative;

  .collection-item {
    background-color: $collection-bg-color;
    line-height: $collection-line-height;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid $collection-border-color;

    // Avatar Collection
    &.avatar {
      min-height: 84px;
      padding-left: 72px;
      position: relative;

      // Don't style circles inside preloader classes.
      &:not(.circle-clipper) > .circle,
      :not(.circle-clipper) > .circle {
        position: absolute;
        width: 42px;
        height: 42px;
        overflow: hidden;
        left: 15px;
        display: inline-block;
        vertical-align: middle;
      }
      i.circle {
        font-size: 18px;
        line-height: 42px;
        color: #fff;
        background-color: #999;
        text-align: center;
      }


      .title {
        font-size: 16px;
      }

      p {
        margin: 0;
      }

      .secondary-content {
        position: absolute;
        top: 16px;
        right: 16px;
      }

    }


    &:last-child {
      border-bottom: none;
    }

    &.active {
      background-color: $collection-active-bg-color;
      color: $collection-active-color;

      .secondary-content {
        color: #fff;
      }
    }
  }
  a.collection-item{
    display: block;
    transition: .25s;
    color: $collection-link-color;
    &:not(.active) {
      &:hover {
        background-color: $collection-hover-bg-color;
      }
    }
  }

  &.with-header {
    .collection-header {
      background-color: $collection-bg-color;
      border-bottom: 1px solid $collection-border-color;
      padding: 10px 20px;
    }
    .collection-item {
      padding-left: 30px;
    }
    .collection-item.avatar {
      padding-left: 72px;
    }
  }

}
// Made less specific to allow easier overriding
.secondary-content {
  float: right;
  color: $primary-color;
}
.collapsible .collection {
  margin: 0;
  border: none;
}



// Responsive Videos
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}

.tiny-text {
    font-size: 10px;
    line-height: 11px;
}

.exercise-instructions {
    p {
      margin-bottom: 0.8em;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  

// Back arrow & white bar
.white-bar {
  z-index: 99;
  background-color: white;
  height: $bottom-nav-height-safe;
  width: 100%;
  position: fixed;
  top: 0;
  padding-top: var(--sat);

  &.dark-top {
    background-color: color("grey","darken-3");
    color: white;
  }

  &.green-top {
    background-color: color("green", "base");
    color: white;

    .topper-title {
      color: white;
      font-weight: 500;
    }

    .back-arrow-top-left {
      color: white;
    }
  }
}

.white-bcg {
    background-color: white;
}

.nav-bar-spacer {
  width: 100%;
  height: $navbar-height-mobile;
}

.back-arrow-top-left {
	color: $icon-low-opacity;
	cursor: pointer;
  padding: 8px 10px 10px 16px;
	font-size: 2em;
	position: fixed;
	top: var(--sat);
	left: 0;
  z-index: 100;

  &.color-white {
    color: white;
  }
}

.icon-low-opacity {
    color: $icon-low-opacity;
}

// Progress Bar

.progress-bar-rule {
  position: absolute;
  height: 120%;
  top: -10%;
  width: 2px;
  background-color: #ddd;
  border-radius: 2px;
  z-index: 9;
  &:first-of-type, &:last-of-type {
    width: 0;
  }
  .rule-label {
    position: absolute;
    bottom: 0;
    transform: translateX(-50%) translateY(100%);
    font-size: 8px;
    text-align: center;
    line-height: 1;
  }
  &:first-of-type {
    .rule-label {
      text-align: left;
      transform: translateX(0) translateY(100%);
    }
  }
  &:last-of-type {
    .rule-label {
      text-align: right;
      transform: translateX(-100%) translateY(100%);
    }
  }
}

.metric-center-title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  color: $font-grey;
  font-size: 20px;
  &.large {
    font-size: 35px
  }
}


.progress {
    position: relative;
    height: 4px;
    &.fat {
      height: 20px;
    }
    display: block;
    //width: 100%;
    background-color: $progress-bar-super-light;
    border-radius: 5px;
    margin: $element-top-margin 0 $element-bottom-margin 0;
    overflow: hidden;
    z-index: 100;
  .determinate {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $progress-bar-color;
    transition: width .3s linear;
  }
  &.gradient {
    background-color: #eeeeee;
    z-index: 9;
    .determinate {
      background-image: linear-gradient(to right, #64FFDA 0%, #03A9F4 51%, #9C27B0 100%);
    }
  }

  &.fixed-progress {
    position: fixed;
  }
  &.big {
    height: 6px;
  }
  .indeterminate {
    background-color: $progress-bar-color;
    &:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left:0;
      bottom: 0;
      will-change: left, right;
      // Custom bezier
      animation: indeterminate 2.1s cubic-bezier(0.650, 0.815, 0.735, 0.395) infinite;

    }
    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left:0;
      bottom: 0;
      will-change: left, right;
      // Custom bezier
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite;
      animation-delay: 1.15s;
    }
  }
}
@keyframes indeterminate {
    0% {
      left: -35%;
      right:100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
}

@keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
}


/*******************
  Utility Classes
*******************/

.hide {
  display: none !important;
}

.invisible {
  opacity: 0;
}

// Text Align
.left-align {
  text-align: left;
}
.right-align {
  text-align: right
}
.start-align {
  text-align: start;
}
.center, .center-align, .text-center {
  text-align: center;
}

.left {
  float: left !important;
}
.right {
  float: right !important;
}

// Items Allign
.align-items-center {
  align-items: center;
}

.top-left {
  position: absolute;
  left: 5px;
  top: 5px;
}

.bottom-right {
  position: absolute;
  right: 0px;
  margin-bottom: 5px;
  &.me-heart {
    top: 160px;
    &.shorter {
      top: 110px;
    }
  }
}

.me-side {
  .bottom-right.me-heart {
    top:60px;

  }
}

.warning-icon {
  padding: 15px;
  position: absolute;
  top: -5px;
  &.left {
    left: 0;
  }
  &:not(.left):not(.bottom-left) {
    right: 0;
  }
  &.bottom-left {
    left: 0;
    bottom: 20px;
    top: unset;
  }
  cursor: pointer;
}

//Colors
.color-signup {
  color: $primary-color;
}

.color-primary {
  color: $primary-color;
}

.secondary-bkgd {
  background-color: color("grey","lighten-3");
  width: 100%;
  color: $header-color;
}

.tirtiary-bkgd {
  background-color: $primary-color;
  color: white;
  width: 100%;
}


// No Text Select
.no-select {
  user-select: none;
}

.circle {
  border-radius: 50%;
}

.no-wrap {
    white-space: nowrap;
}

.normal-wrap {
    white-space: normal;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-padding {
  padding: 0 !important;
}

.faint-color {
    color: #aaa;
}

a.faint-color {
  color: #aaa;
}

.info-color {
    color: $info-color;
}

.info-color-bg {
    background-color: $info-color;
    color: white;
}

.warning-color {
    color: $warning-color;
}

.warning-color-i {
  color: $warning-color !important;

}

.warning-color-bg {
    background-color: $warning-color;
    color: white;
}

.success-color {
    color: $success-color;
}

.success-text {
  color: $success-color !important;
}

.error-color {
  color: $error-color;
}

.gold-color {
  color: color("yellow","golden");
}

.success-color-bg {
    background-color: $success-color;
    color: white;
}
.minimal-padding {
  padding-left: 7px !important;
  padding-right: 7px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.auto-margins {
  margin-right: auto;
  margin-left: auto;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.minimal-margin {
  &.top {
    margin-top: 5px;
  }
  &.bottom {
    margin-bottom: 5px;
  }
}

.custom-macro-container {
  width: auto;
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

.extra-margin-bottom {
  margin-bottom: 10px !important;
}

.extra-margin-top {
  margin-top: 40px !important;

  &.half {
    margin-top: 20px !important;
  }
}

.extra-margin-left {
  margin-left: 40px;
}

.extra-padding-bottom {
  padding-bottom: 100px;
}

.thin-text {
  font-weight: 300;
}

.underline {
  text-decoration: underline;
  svg.svg-inline--fa {
    border-bottom: 1px solid currentColor;
  }
}

a.underline:not(.font-grey) {
  color: unset;
}

.side-buffer-margin {
  margin-left: 5px;
  margin-right: 5px;
}

.left-align-block {
    display: inline-block;
    text-align: left;
}

.display-inline-block {
  display: inline-block;
}

.display-inline {
  display: inline !important;
}

.display-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.display-inline-flex {
  display: inline-flex;
}

.flex-grow {
  flex-grow: 1;
}

.position-relative {
  position: relative;

  &.adjust-up {
    top: -56px;
    height: 100%;
  }

  &.spinach-width {
    width: 100px;
    height: 200px;
    right: -20px;
    top: -25px;
  }
}

.clickable-icon {
  padding: 13px;
}

.offside-tip {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

.flex-icon-tip {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.float-right {
  float: right;
}

.vert-align-mid {
    vertical-align: middle;
}

.vert-align-top {
  vertical-align: top;
}

.icon-padding {
  font-size: 18px;
  padding: 9px;
  cursor: pointer;
}

.icon-padding-2 {
  font-size: 21px;
  padding: 10px 14px;
  cursor: pointer;
}

.caret-cont {
  display: inline;
  vertical-align: middle;
  font-size: 21px;
  &.right-caret {
    padding: 0 20px 0 8px;
  }
  &.left-caret {
    padding: 0 8px 0 0;
  }
  cursor: pointer;
}

.z-index-neg-1 {
  z-index: -1;
}

.lh1 {
    line-height: 1.1;
}

.clickable-panel {
    cursor: pointer;
    &:hover {
          background-color: $card-buttonize-background-hover;
    } 
    &:active {
        transform: scale(0.95);
    }
}

.tiny-icon {
  font-size: 14px !important;
}

.fw500 {
  font-weight: 500;
}

.centered-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.font-grey {
    color: $font-grey;
}

.med-quarter {
    font-size: 1.25em;
    font-weight: bold;
}

.slight-bottom-border {
    border-bottom: 1px solid #ddd;
}

.capitalizer {
    text-transform: capitalize;
}

/*********************
Macro Color Classes
**********************/
.protein-color {
  background-color: $protein-color !important;
}

.carb-color, .carbs-color {
  background-color: $carb-color !important;
}

.fat-color {
  background-color: $fat-color !important;
}

.fiber-color {
  background-color: $fiber-color !important;
}

.alcohol-color {
  background-color: $alcohol-color !important;
}

.protein-text, .calories-text {
  color: $protein-color !important;
}

.carb-text, .carbs-text {
  color: $carb-color !important;
}

.fat-text {
  color: $fat-color !important;
}

.fiber-text {
  color: $fiber-color !important;
}

.alcohol-text {
  color: $alcohol-color !important;
}

.protein-circle {
	stroke: $protein-color;
}

.carbs-circle {
	stroke: $carb-color;
}

.fiber-circle {
  stroke: $fiber-color;
}

.fat-circle {
  stroke: $fat-color;
}

.alcohol-circle {
  stroke: $alcohol-color;
}

.off-white { 
  background-color: $background-off-white !important;
}

.page-container.dark {
  background-color: $dark-mode-bcg;
  .nutrition-background {
    background-color: unset;
  }
  .conversion-heading, .sideways-text, .meal-plan-heading-2  {
    color: white;
  }
  .main-head-sub {
    color: $low-em-dark-bcg;
  }
}

.off-white-2 {
  background-color: $background-off-white-2 !important;
}

.off-white-rc-med-and-up {
  @media #{$rc-medium-and-up} {
    background-color: $background-off-white !important;
  }
}

@mixin blur-overlay-cont {
  position: relative;
  overflow: hidden;
}

@mixin abs-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.blur-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.35);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 9;
  .overlay-msg {
    @include abs-centered;
    width: 80%;
  }
}

.mt-inset-60 {
  margin-top: calc(var(--sat) + 60px) !important;
}

.mt-inset-50 {
  margin-top: calc(var(--sat) + 50px) !important;
}

.mt-inset-30 {
  margin-top: calc(var(--sat) + 30px) !important;
}

.shift15-up {
  margin-top: -15px;
  margin-bottom: 15px;
}

.shift20-up {
  margin-top: -20px;
  margin-bottom: 20px;
}

.outline-text {
  color: white;
  text-shadow:
          -1px -1px 0 #000,
          1px -1px 0 #000,
          -1px 1px 0 #000,
          1px 1px 0 #000;
}