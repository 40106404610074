@function dp-shadow($dp) {
    $umbra: map-get($mdc-elevation-umbra-map,$dp);
    $penumbra: map-get($mdc-elevation-penumbra-map,$dp);
    $ambient: map-get($mdc-elevation-ambient-map,$dp);

    @return #{$umbra} rgba(0,0,0,$mdc-elevation-umbra-opacity),
    #{$penumbra} rgba(0,0,0,$mdc-elevation-penumbra-opacity),
    #{$ambient} rgba(0,0,0,$mdc-elevation-ambient-opacity)
}

@each $dp, $umbra in $mdc-elevation-umbra-map {
    $penumbra: map-get($mdc-elevation-penumbra-map,$dp);
    $ambient: map-get($mdc-elevation-ambient-map,$dp);

    .elevation-#{$dp}-dp {
        box-shadow: dp-shadow($dp);
    }
}


@mixin elevation-dp($dp) {
    box-shadow: dp-shadow($dp);
}