.record-panel {
    &.border-top {
        border-top: 1px solid #ddd;
    }

    &.border-bottom {
        border-bottom: 1px solid #ddd;
    }

    .action-box {
      width: 50px;
      height: 50px;

      &:not(.no-border) {
        &:not(.left) {
          border-left: 1px solid #ddd;
        }
        &.left {
          border-right: 1px solid #ddd;
        }
    }

      &.wider {
          min-width: 100px;
      }

      float: right;
        &.left {
          float: left;
        }
        text-align: center;
        .action-icon, .action-input {
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 9px;
          line-height: 1;
          svg, i {
              font-size: 2em;
          }
        }
    
        &:not(.inactive) {
          cursor: pointer;
          &:hover {
            background-color: #CCCCCC;
            color: white;
            transition: 0.3s;
          }
    
          &:active {
            background-color: #999;
            color: white;
          }
    
          label {
            cursor: pointer;
          }
        }

    }
  
    .content-box {
        overflow: hidden;
      height: 50px;
      padding-left: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
       > * {
           flex-grow: 1;
       }
      .vertical-div {
        height: 50%;
        text-align: center;
        .half-div {
          width: 48%;
          height: 100%;
          display: inline-block;
        }
      }
    }
  
    &.wide {
      .content-box {
        height: 75px;
      }
      .action-box {
        width: 75px;
        height: 75px;
      }
      .drag-handle, .lock-icon {
        width: 50px;
        height: 75px;
        font-size: 1.5em;
      }
    }

    &.narrow {
        .content-box {
          height: 35px;
          padding-left: 5px;
        }
    
        .action-box {
          width: 35px;
          height: 35px;
        }
    
        .dropdown {
          .dropdown-menu {
            top: 23px;
            border-radius: 0;
          }
          &.open {
            .dropdown-toggle {
              color: #000;
              background-color: #aaa;
            }
          }
        }
    
        .drag-handle, .lock-icon {
          width: 35px;
          height: 35px;
          font-size: 1em;
        }
      }

  }
